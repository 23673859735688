.HeroListingTitleWithOverline {
  &-Container {
    width: 100%;
    padding: 56px 0 48px 0;
    @include tablet {
      padding-bottom: 32px;
    }
    @media (min-width: #{$small-desktop}) {
      width: 66.66%;
      padding-top: 120px;
      padding-bottom: 64px;
    }
  }
  &-Overline {
    display: block;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 3.86px;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
    cursor: default;

    @include max-small-desktop {
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 19px;
    }
  }

  &-Title {
    display: block;
    color: $black;
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 2px;
    line-height: 66px;
    cursor: default;
    margin: 0;

    @include tablet {
      font-size: 36px;
      line-height: 42px;
    }

    @include mobile {
      font-size: 24px;
      line-height: 33px;
    }
  }
}
