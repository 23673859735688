.MyNewsletters {
  @media (min-width:#{$tablet}) {
    background-color: $black-3;
  }

  &-Wrapper {
    @media (min-width:#{$tablet}) {
      @include component-container;
      background-color: $black-3;
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  &-Subscriptions {
    background-color: $white;
    padding: 56px 24px;
    color: $black-80;
    font-size: 16px;
    border-bottom: 1px solid $black-10;

    @media (min-width:#{$tablet}) {
      padding: 32px 40px;
      border-bottom: none;
    }

    &-Title {
      margin-bottom: 24px;

      @media (max-width:#{$tablet}) {
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }
    }

    &-Text {
      line-height: 24px;
      margin-bottom: 24px;
    }

    &-Success {
      line-height: 24px;
      margin-top: 12px;
    }

    &-Row {
      display: flex;
      margin-top: 1px;
      padding: 0 8px;
      padding-top: 24px;
      padding-bottom: 24px;
      box-shadow: 0 -1px 0 0 $black-10, 0 1px 0 0 $black-10;

      &-Selection {
        @include checkbox-input-style;
        display: inline-block;
        position: relative;
        padding-left: 24px;
        line-height: 17px;
        cursor: pointer;
        &:hover {
          .checkmark {
            border-color: $primary;
          }
        }
      }
    }

    &-Save {
      margin-top: 32px;
      width: 100%;
      @include button-link;
      @media (min-width:#{$tablet}) {
        width: auto;
      }
    }
  }
}
