.OrderListMobile {
  padding-bottom: 40px;
}

.OrderListMobile__Item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  border-bottom: 1px solid $black-10;
  padding: 16px 20px;

  &:first-of-type {
    border-top: 1px solid $black-10;
  }
}

.OrderListMobile__Item__Title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-align: left;
  color: $black;
}

.OrderListMobile__Item__Properties {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.OrderListMobile__Item__Property {
  color: $black-60;
  @include text-sm;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px 16px;

  dt {
    color: $black;
    display: inline;
  }

  dd {
    margin-left: 4px;
    display: inline;
  }
}

.OrderListMobile__Item__Button {
  margin-top: 8px;
  padding: 0;
  align-self: flex-end;
  font-size: 14px;
}
