.SearchResultHeading {
  max-width: 100% !important;
  padding-top: 88px;
  padding-bottom: 40px;
}

.SearchResultHeading__Label {
  color: $black-80;
  font-weight: bold;
  font-size: 48px;
  letter-spacing: 2px;
  line-height: 66px;
  margin: 0;
}

.SearchResultHeading__Query {
  color: $black-80;
  font-weight: 400;
  font-size: 48px;
  letter-spacing: 2px;
  line-height: 66px;
  text-transform: uppercase;
}
