@import 'ImageSlide/ImageSlide.scss';
@import 'VideoSlide/VideoSlide.scss';
@import 'TextBox/TextBox.scss';
@import 'ProgressBar/ProgressBar.scss';

// Whole component spacings on page
$bottom-spacing-mobile: 24px;
$bottom-spacing: 56px;
$side-spacing: 32px;

// offset of the bottom elements - so text box and progress bar
$bottom-offset-mobile: 40px;
$image-bottom-offset-tablet: 40px;
$image-bottom-offset-desktop: 32px;

$slider-bottom-offset-tablet: 64px;
$slider-bottom-offset-desktop: 80px;

// side offset of slides and boxes within main container
$side-offset-mobile: 20px;
$side-offset-tablet: 22px;
$side-offset-desktop: 80px;

// height of image/video container
$image-height-mobile: 370px;
$image-height-tablet: 420px;
$image-height-desktop: 729px;

// width of text box container
$text-box-width-mobile: 100%;
$text-box-width-tablet: 520px;
$text-box-width-desktop: 678px;

.ImageVideoHero {
  margin-bottom: $bottom-spacing-mobile;
  display: flex;
  flex-direction: column;
  position: relative;

  @include min-tablet {
    height: $image-height-tablet;
    margin-bottom: calc($bottom-spacing-mobile + $image-bottom-offset-tablet);
  }

  @include desktop {
    height: $image-height-desktop;
    margin-bottom: calc($bottom-spacing + $image-bottom-offset-desktop);
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
}

.ImageVideoHero__slide-preload {
  display: none;
}

.ImageVideoHero__container {
  display: flex;
  flex-direction: column;

  position: relative;
  text-decoration: none;
  width: 100%;

  // Only the incoming slide is absolute, so we can keep know the size of the container
  &.ImageVideoHero__item-enter {
    position: absolute;
  }

  @include min-tablet {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include desktop {
    padding-left: $side-spacing;
    padding-right: $side-spacing;
  }
}

.ImageVideoHero--slider {
  @include min-tablet {
    margin-bottom: calc($bottom-spacing + $slider-bottom-offset-tablet);
  }

  @include desktop {
    margin-bottom: calc($bottom-spacing + $slider-bottom-offset-desktop);
  }

  .ImageVideoHero__bottom {
    @include min-tablet {
      bottom: -$slider-bottom-offset-tablet;
    }

    @include desktop {
      bottom: -$slider-bottom-offset-desktop;
    }
  }
}

.ImageVideoHero__item {
  position: relative;
}

.ImageVideoHero__item-appear-done {
  .ImageVideoHero__slide {
    opacity: 1;
  }

  .ImageVideoHero__text-box {
    opacity: 1;
  }
}

.ImageVideoHero__item-enter {
  .ImageVideoHero__slide {
    opacity: 0;
  }

  .ImageVideoHero__text-box {
    opacity: 0;
  }
}

.ImageVideoHero__item-enter-active {
  .ImageVideoHero__slide {
    opacity: 1;
    transition: opacity 500ms;
  }

  .ImageVideoHero__text-box {
    opacity: 1;
    transition: opacity 500ms;
  }
}

.ImageVideoHero__item-exit {
  .ImageVideoHero__slide {
    opacity: 1;
  }

  .ImageVideoHero__text-box {
    opacity: 1;
  }
}

.ImageVideoHero__item-exit-active {
  .ImageVideoHero__slide {
    opacity: 0;
    transition: opacity 500ms;
  }

  .ImageVideoHero__text-box {
    opacity: 0;
    transition: opacity 500ms;
  }
}

.ImageVideoHero__bottom {
  margin-top: -$bottom-offset-mobile;
  margin-left: $side-offset-mobile;

  @include min-tablet {
    position: absolute;
    bottom: -$image-bottom-offset-tablet;
    margin-top: 0;
    margin-left: 0;
    right: 0;
  }

  @include desktop {
    bottom: -$image-bottom-offset-desktop;
    margin-right: $side-spacing;
  }
}

.ImageVideoHero__slide {
  overflow: hidden;

  margin-right: $side-offset-mobile;
  height: $image-height-mobile;

  @include min-tablet {
    margin-right: $side-offset-tablet;
    height: $image-height-tablet;
  }

  @include desktop {
    height: $image-height-desktop;
    margin-right: $side-offset-desktop;
  }
}

.ImageVideoHero__text-box {
  @include min-tablet {
    width: $text-box-width-tablet;
  }

  @include desktop {
    width: $text-box-width-desktop;
  }
}

.ImageVideoHero__progress-bar {
  width: 180px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;

  @include min-tablet {
    margin-bottom: 30px;
    margin-left: $side-offset-tablet;
    position: absolute;
    left: 0;
    bottom: -$slider-bottom-offset-tablet;
  }

  @include desktop {
    margin-left: 98px;
    bottom: -$slider-bottom-offset-desktop;
  }
}

.ImageVideoHero__button {
  display: none;
  position: absolute;
  background-color: transparent;
  border: none;
  padding: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  z-index: 1;

  svg {
    width: 24px;
    height: 24px;
    color: #fff;
  }

  &:hover {
    svg {
      color: $primary;
    }
  }

  @include desktop {
    display: flex;
  }
}

.ImageVideoHero__button--prev {
  left: calc($side-spacing + 32px);

  &:hover {
    transform: translateX(-16px) translateY(-50%);
    padding-right: 32px;
  }
}

.ImageVideoHero__button--next {
  right: calc($side-offset-desktop + $side-spacing + 32px);

  &:hover {
    transform: translateX(16px) translateY(-50%);
    padding-left: 32px;
  }
}
