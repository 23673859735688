.AllDownloadItem {
  margin: 0 !important;
  text-decoration: none;
  color: $black-60;
  display: flex;
  align-items: center;
  background-color: $white;

  &:nth-child(odd) {
    background-color: $black-4;
  }

  &:active .AllDownloadItem-Title,
  &:active .AllDownloadItem-Size {
    color: $primary;
  }
}

.AllDownloadItem-Icon {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 16px var(--download-item-padding-inline);
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: solid 1px $primary !important;
  }
}

.AllDownloadItem-Title {
  padding-right: 0;
  padding-block: 16px;
  flex-grow: 1;
  text-decoration: none;
  font-weight: bold;
  @include text-section-title-small;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  transform: translateY(1px);

  @include min-tablet {
    flex-direction: row;
    align-items: center;
    padding-block: 18px;
  }
}

.AllDownloadItem-Title-Inline-Language {
  color: $black-60;
  @include text-section-title-small;
  font-weight: 600;

  @include min-tablet {
    margin-left: auto;
  }
}

.WhiteColorStyle .AllDownloadItem {
  color: $white;
}

.AllDownloadItem-Title-Inline-Subtitle {
  font-weight: 400;
}

.AllDownloadItem-Label {
  padding: 0;
  padding-left: var(--download-item-padding-inline);
  flex-grow: 1;
}
