.SalesAndServicePointsList {
  box-shadow: 0 -1px 1px -1px $black-60;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  @include tablet {
    height: auto;
  }
  @include mobile {
    height: auto;
  }
}
