.Expertise {
  &-Container {
    margin: 0 auto;
    padding-top: 116px;
    width: 83.33%;

    @include tablet {
      width: 100%;
      padding-top: 40px;
    }

    @include mobile {
      width: 100%;
      position: static;
      padding-top: 48px;
    }
  }

  &-Title {
    color: $black-80;
    width: 80%;
    margin: 24px auto 40px auto;
    cursor: default;

    @include tablet {
      width: 100%;
      padding: 0 64px;
      margin: 32px auto;
    }

    @include mobile {
      width: 100%;
      padding: 0 24px;
      margin: 16px auto 32px auto;
    }
  }

  &-Button {
    width: 80%;
    margin: 0 auto;

    a {
      text-transform: uppercase;
      color: $primary;
      font-weight: bold;
      letter-spacing: 3px;
      display: block;
      color: $primary;
      font-size: 14px;
      text-decoration: none;
    }

    svg {
      margin-bottom: -3px;
      margin-right: 8px;
    }

    @include tablet {
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      padding: 0 24px;
    }

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }
  }

  &-Image {
    width: 100%;
    height: auto;
    display: block;
  }

  &-SocialLinkWrapper {
    width: 32px;
    float: right;
    margin-bottom: 24px;
    margin-top: 40px;

    @include tablet {
      width: auto;
      float: none;
      text-align: end;
      margin-bottom: 0;
      padding-left: 24px;
      padding-right: 24px;
    }

    @include mobile {
      float: none;
      width: auto;
      margin: 32px auto 0 auto;
      text-align: center;
    }

    &-Hide {
      display: none;
    }
  }

  &-EmailShare {
    height: 32px;
    width: 32px;
    display: inline-block;
    margin-top: 4px;

    svg {
      width: 32px;
      height: 32px;
    }

    @include tablet {
      margin: 0 4px;
    }

    @include mobile {
      margin: 0 8px;
    }
  }

  &-Description {
    width: 80%;
    margin: 0 auto;
    padding-top: 54px;
    padding-bottom: 32px;
    color: $black-80;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    cursor: default;

    @include rich-text-a-style;
    @include rich-text-list-style;

    @include mobile {
      width: 100%;
      padding: 32px 24px;
      font-size: 22px;
      line-height: 30px;
    }

    @include tablet {
      width: 100%;
      padding: 32px 64px;
    }
  }
}

//used for content editor
.scEnabledChrome {
  .Expertise {
    &-SocialLinkWrapper {
      &-Hide {
        display: block;
      }
    }
  }

  a.Expertise-Button {
    color: $primary;
  }
}

.GeneralLink.Expertise-Button a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}
