.ContactTwoColumn {
  padding: 42px 0 96px 0;
  position: relative;

  .Dropdown {
    width: 100%;
    margin: 0 0 24px 0;
    height: 56px;

    @media (min-width: #{$tablet}) {
      width: calc(100% - 10px);
      margin: 0 0 40px 0;
    }

    &-Wrapper {
      padding-top: 8px;
    }

    &-Pointer {
      top: 24px;
      right: 16px;
    }

    &-Options {
      width: 100%;
      height: auto;
      padding-bottom: 8px;
    }
  }

  .LinkElement.componentContainer,
  .RichTextTeaser.componentContainer,
  .componentContainerMobile {
    padding: 0;
  }

  .LinkElement-Link,
  .RichTextTeaser-Text,
  .DownloadTeaser-Container {
    width: 100%;
  }

  .HeadlineRichtextTeaser .title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 32px;
  }

  .DownloadTeaser-Container .DownloadTeaser .HeadlineRichtextTeaser {
    padding-bottom: 0;

    .title {
      @include max-small-desktop {
        margin-bottom: 24px;
      }
    }
  }

  .LinkElement {
    margin-top: 32px;
  }

  &-GetAQuoteMoreButton {
    color: $primary;
    font-weight: bold;
    text-transform: uppercase;
    @include text-section-title-small;
    display: inline-block;
    padding: 0 0 24px 16px;
    cursor: pointer;

    svg {
      margin-right: 8px;
      margin-bottom: -3px;
    }

    @media (min-width:#{$small-desktop}) {
      &:hover {
        color: $primary-hover;

        svg.sprite-icon {
          color: $primary-hover;
        }
      }
    }
  }

  &-ProductsContainer {
    padding-bottom: 24px;
  }
}
