.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;

  padding: 12px 16px;
  background-color: #d40028;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 2.57px;
  line-height: 16px;
  height: 40px;
  margin: 0;
  text-transform: uppercase;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border: none;
  text-decoration: none;

  svg {
    flex-shrink: 0;
  }

  & ~ .button {
    margin-left: 24px;
  }

  &:hover {
    background-color: $primary-hover;
    color: $white;

    .button__tooltip {
      visibility: visible;
    }
  }

  @include mobile {
    display: flex;
    width: 100%;
    height: 56px;
  }

  &:disabled,
  &.button--disabled {
    cursor: default;
    color: $white;
    font-weight: bold;
    @include text-title-xs;
    background-color: $primary-30;
  }

  &.button--large {
    padding: 18px 24px;
    line-height: 1.4286;
    height: auto;
  }

  &.button--square {
    padding: 16px;
    height: auto;
    flex-grow: 0;
    width: auto;
  }

  &.button--no-size {
    padding: 0;
  }

  &.button--no-size-small {
    padding: 0;
    height: 16px;
    gap: 8px;
  }

  &.button--secondary {
    background-color: $white;
    color: $primary;

    &:hover {
      background-color: $primary-3;
    }

    &:disabled,
    &.button--disabled {
      background-color: $black-3;
      color: $primary-60;

      svg {
        color: $primary-60;
      }
    }

    .CustomSpinner div {
      background-color: $primary;
    }
  }

  &.button--text {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.33;
    text-transform: uppercase;
    display: flex;
    color: $primary;
    gap: 8px;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    box-shadow: none;

    > span {
      margin-top: 2px;
    }
  }

  &.button--ghost {
    background-color: transparent;
    color: $primary;
    box-shadow: none;

    &:hover {
      background-color: transparent;
    }

    &:disabled,
    &.button--disabled {
      background-color: $black-3;
      color: $primary-60;
    }
  }

  .button__loading-spinner {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 30px;
  }

  // Customized loading spinner
  &.button--is-loading .CustomSpinner {
    transform-origin: left; // Keep the distance between the spinner and the text
    transform: translate(0, 0) scale(0.4);
    top: unset;
    left: unset;
  }
}

.button__tooltip {
  @include tooltip-product;
}
