.VariantSelector {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  margin-bottom: 32px; // Let it overflow with other margins
  border-top: 1px solid $black-10;

  @include min-small-desktop {
    flex-direction: row;
    justify-content: space-between;
  }

  + .AddToCartTeaser {
    margin-top: 16px;
  }
}

.VariantSelector__text {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: $black-80;
  @include min-small-desktop {
    font-size: 18px;
    line-height: 24px;
  }
}
