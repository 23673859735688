.TwoSectionsLayout {
  &-Section {
    &-Title {
      font-size: 18px;
      letter-spacing: 1.5px;
      font-weight: bold;
    }

    &-Row {
      padding-top: 24px;
      padding-bottom: 16px;
      margin: 0 !important;
      & > div {
        padding: 0 24px 0 0 !important;
      }
    }
  }
  &-Item {
    margin-bottom: 8px;
    min-height: 56px !important;
    &-NoImage {
      min-height: 40px !important;
    }
    a {
      display: flex;
      align-items: center;

      .TwoSectionsLayout-Item {
        &:hover {
          cursor: pointer;
          .TwoSectionsLayout-Item-Title {
            color: $primary;
          }
        }

        &-Image {
          width: 56px;
          height: 56px;
          background-color: $black-4;
          margin-right: 16px;
        }

        &-Sitecore {
          &-Image {
            width: 56px;
            height: 56px;
            margin-right: 16px;
          }
        }

        &-Title {
          color: $black-80;
          font-size: 14px;
          letter-spacing: 1.5px;
          line-height: 19px;
        }
      }
    }
  }
}
.OneSectionsLayout-Section-Row.TwoSectionsLayout-Section-Row {
  padding-top: 0;
}
