.FooterColumn {
  display: inline-block;
  width: 25%;
  margin-right: 20px;
  vertical-align: top;
  @include tablet {
    width: 50%;
    margin-right: 0;
    margin-bottom: 68px;
  }
  @include mobile {
    display: block;
    width: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
  &-title {
    line-height: 25px;
    color: $black-80;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 16px;
    display: inline-block;
  }
  .GeneralLink {
    color: $black-80;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 18px;
  }
}
