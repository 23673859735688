.LinkElement {
  margin: 0 auto 24px auto;
  min-height: 16px;

  &-Link {
    display: block;
    width: 66.66%;
    margin: 0 auto;
    color: $primary;

    a {
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
    }

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }

    svg {
      margin-bottom: -2px;
      margin-left: 10px;
      color: $primary;
    }

    @include tablet {
      width: 100%;
      padding: 0 40px;
    }

    @include mobile {
      width: 100%;
    }
  }
}
