.PageBackLink {
  width: 80%;
  margin: 0 auto;

  a {
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
    letter-spacing: 3px;
    display: block;
    color: $primary;
    font-size: 14px;
  }

  svg {
    margin-bottom: -3px;
    margin-right: 8px;
  }

  @include tablet {
    width: 100%;
    padding: 0 64px;
  }

  @include mobile {
    width: 100%;
    padding: 0 24px;
  }

  &:hover {
    a,
    svg {
      color: $primary-hover;
    }
  }
}

.GeneralLink.PageBackLink a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}
