.ProductApplications {
  &-Wrapper {
    padding: 56px 0;

    @media (min-width:#{$small-desktop}) {
      padding: 112px 0;
    }
  }

  padding-top: 32px;

  @media (min-width:#{$small-desktop}) {
    padding-top: 56px;
  }

  &-Item {
    display: flex;
    align-items: center;
    padding-right: 24px !important;
    margin-bottom: 32px;

    @media (min-width:#{$small-desktop}) {
      margin-bottom: 40px;
    }

    &-Image {
      margin-right: 24px;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
      }

      @media (min-width:#{$small-desktop}) {
        width: 80px;
        height: 80px;
      }

      &-Placeholder {
        background-color: $black-3;
      }
    }

    &-Name {
      color: $black-80;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 19px;
      width: 100%;

      @media (min-width:#{$small-desktop}) {
        width: auto;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  &-More {
    color: $primary;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $primary-hover;

      svg {
        &.sprite-icon {
          color: $primary-hover;
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      margin: 0 8px 2px 0;
    }

    &-Hide {
      display: none;
    }
  }
}
