@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';
@import '../../../assets/typography-mixins.scss';

.PageOmnibox__background-wrapper {
  background-color: #fff;
}

.PageOmnibox {
  min-width: 300px;
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  font-size: 15px;
  color: #313a45;

  padding-left: 24px;
  padding-right: 24px;

  @include min-small-desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktop {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.PageOmnibox__wrapper {
  padding-top: 24px;
  padding-bottom: 8px;
  margin-right: 0;
  display: flex;

  @include min-tablet {
    max-width: calc(50% + 56px);
    height: auto;
    box-shadow: none;
    margin-left: 0 !important;
    padding: 16px 0 40px;
  }
}

.PageOmnibox__searchbox {
  flex-grow: 1;
  text-align: left;
  color: #212121;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: relative;

  @include min-tablet {
    margin-right: 16px;
  }
}

.PageOmnibox__input-wrapper {
  background: #fff;
  height: 56px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
}

.PageOmnibox__input {
  color: #212121;
  text-align: left;
  text-indent: 0;
  white-space: pre;
  overflow: auto;
  box-sizing: border-box;
  display: block;
  background: none;
  border: none;
  resize: none;
  color: #333;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding: 16px 49px 16px 12px;
  height: 56px;
  width: 100%;

  @include min-tablet {
    font-size: 12px;
  }
}

.PageOmnibox__search-button {
  display: none;
  height: 56px;
  background-color: #d40028;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border: 0;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  width: 60px;
  cursor: pointer;
  line-height: 0;

  @include min-tablet {
    display: block;
  }

  &:hover svg {
    opacity: 0.8;
  }
}

.PageOmnibox__search-icon {
  vertical-align: middle;
  animation: none;
  display: inline-block;
  right: 18px;
  position: absolute;
  top: 17px;
  width: 22px;
  height: 22px;
}

.PageOmnibox__clear {
  position: relative;
  float: right;
  height: 38px;
  line-height: 38px;
  width: 48px;
  text-align: center;
  font-size: 24px;
  transition: visibility 0.3s, width 0.3s;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  top: -46px;
  border: none;

  svg {
    width: 16px;
    height: 16px;

    &.sprite-icon {
      color: #676767;
    }
  }

  @include min-tablet {
    height: 48px;
    line-height: 48px;
    top: -52px;
  }
}

.PageOmnibox__suggestions-wrapper {
  border: none;
  background: #fff;
  left: 0;
  right: 0;
  clear: both;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  top: calc(100% + 4px);
  z-index: 3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  padding-left: 24px;
  padding-right: 24px;

  @include min-small-desktop {
    height: auto;
    max-height: 70vh;
  }
}

.PageOmnibox__suggestions {
  padding: 0;
  padding-bottom: 24px;
  max-width: 1660px;
  margin: 0 auto;
  list-style: none;
}

.PageOmnibox__suggestion {
  padding: 24px 0 0;
  margin: 0;
}

.PageOmnibox__suggestion-link {
  color: #004990;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.PageOmnibox__suggestion-image {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-right: 24px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.PageOmnibox__suggestion-text {
  display: block;

  &:hover {
    .PageOmnibox__suggestion-category,
    .PageOmnibox__suggestion-title {
      color: #d40028;
    }
  }
}

.PageOmnibox__suggestion-category {
  display: block;
  color: #333;
  font-size: 10px;
  letter-spacing: 2.14px;
  line-height: 14px;
}

.PageOmnibox__suggestion-title {
  display: block;
  color: #333;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 25px;
  font-weight: 700;
}

.PageOmnibox__submit-search-button {
  margin-left: 16px;
}

.PageOmnibox.PageOmnibox--downloads {
  .PageOmnibox__wrapper {
    padding-bottom: 20px;

    @include min-tablet {
      padding: 60px 0;
    }
  }

  .PageOmnibox__search-icon {
    @include mobile {
      display: none;
    }
  }

  .PageOmnibox__input {
    @include mobile {
      padding-right: 16px;
    }
  }
}
