.CompareFloatingIcon {
  border-radius: 4px 0 0 4px;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 56px;
  height: 56px;
  top: 50vh;
  right: 0;
  transform: translateY(-50%);
  z-index: 100;

  &:hover {
    svg.sprite-icon {
      color: $primary-hover;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-Number {
    color: $white;
    background-color: $primary;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -4px;
    left: -10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }
}
