.SelectLanguage {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  height: 100%;
  color: $black-60;

  &-Mobile {
    align-items: center;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: $black-60;

    &-Wrapper {
      outline: none;

      svg {
        margin-bottom: -3px;
      }
    }

    &-ChevronDown {
      height: 8px;
      width: 8px;
      margin-bottom: 0 !important;
      margin-left: 8px;

      &.sprite-icon {
        color: $black-60;
      }
    }

    .LanguageFlyout {
      width: 100%;
      height: 100%;
      top: 0;
      margin-left: -24px;
      margin-top: 0;
    }

    &-Label {
      text-transform: uppercase;
      padding-left: 8px;
    }
  }

  &-Icon {
    display: inline-block;
    margin: auto 0;

    svg {
      margin-bottom: -3px;
    }

    &-ShowFlyout {
      color: $primary;
    }
  }

  &-Wrapper {
    height: 100%;
    cursor: default;

    &-Inner {
      display: flex;
      height: 100%;
      align-items: center;
      cursor: pointer;
      outline: none;

      &:hover {
        .SelectLanguage-Icon svg {
          color: $primary;
        }
      }
    }
  }

  &-Selected {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    display: flex;
    align-items: center;

    &-Language {
      @include desktop {
        display: none;
      }
    }

    &:after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-left: 8px;
      background-image: url(./../../../assets/css-svg/chevron-down.svg);
      background-repeat: no-repeat;
    }
  }

  &-currentLanguage {
    @include desktop {
      display: none;
    }
  }

  &-Separator {
    height: 24px;
    margin: 0 16px;
    width: 1px;
    box-shadow: inset -1px 0 0 0 $black-10;

    @include small-desktop {
      display: none;
    }
  }

  &-Direct {
    display: inline-block;
    color: $black-60;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    min-width: 107px;

    @include small-desktop {
      display: none;
    }

    div {
      display: inline-block;
    }

    .GeneralLink {
      color: inherit;
      font-size: inherit;

      a:hover {
        color: $primary;
      }
    }

    &-Lang {
      display: inline-block;
      text-align: center;
      box-shadow: inset -1px 0 0 0 $black-10;
      margin-top: 32px;

      a {
        line-height: 16px;
        padding: 12px 24px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        display: inline-block;
      }

      &-Plus {
        box-shadow: none;

        padding: 12px 24px;
      }

      &-Default {
        &.GeneralLink a {
          color: $primary;
          cursor: pointer;
        }

        &-Separator {
          color: $black-60;
          padding-left: 4px;
          padding-right: 4px;
        }

        &-Plus {
          &:hover svg {
            &.sprite-icon {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
