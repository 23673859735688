.CardLayout {
  .HiddenOnCard {
    display: none;
  }

  .ProductResultItem {
    background-color: $white;
    width: 100%;
    padding: 24px 24px 32px 24px;
    display: flex;
    flex-direction: column;

    img {
      width: 200px;
      height: 200px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
  }

  .ProductResultItem__Link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $primary;
      text-decoration: none;
    }
  }

  .ProductResultItem__Tags {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  .ProductResultItem__Wrapper-Title {
    color: $black-80;
    font-family: var(--font-family-base);
    font-weight: bold;
    padding: 8px 0;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 1.5px;

    @media (min-width: #{$tablet}) {
      line-height: 25px;
    }
  }

  .ProductResultItem__Wrapper-Description {
    color: $black-60;
    font-family: var(--font-family-base);
    font-weight: 400;
    padding: 0 0 16px 0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .ProductResultItem__Wrapper-SubDescription {
    color: $black-60;
    font-family: var(--font-family-base);
    font-weight: bold;
    padding: 8px 0 0 0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .ProductResultItem__Wrapper-Compare-Container {
    position: relative;

    .CompareBox {
      display: none;
      visibility: visible;
      left: 0;
      top: 0;

      @media (max-width: 800px) {
        position: fixed;
        width: 100%;
        background-color: $white;
        padding: 24px;
        z-index: 105;
        top: 0;
        right: 0;
        height: 100vh;
        max-height: 100vh;
        overflow: auto;
        &::after {
          display: none;
        }
      }

      @media (min-width: 801px) {
        left: -22px;
        bottom: calc(100% + 12px);
        transform: translateX(0);
        top: auto;
      }

      &::after {
        left: 30px;
      }
    }
  }

  .ProductResultItem__Wrapper-Compare {
    display: flex;

    label {
      display: inline-block;
      position: relative;
      padding: 0 0 16px 24px;
      font-family: var(--font-family-base);
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 16px;
      cursor: pointer;
      color: $black-80;
    }

    @include checkbox-input-style;
  }

  .ProductResultItem__Wrapper-Downloads {
    padding: 0;
    font-size: 12px;
    line-height: 1.33333;

    svg {
      margin-left: 8px;
    }
  }

  .ProductResultItem__Variant-Tags {
    margin-top: auto;
  }

  .ProductResultItem__Buy-And-Compare {
    display: flex;
    align-items: center;
    padding: 32px 0 0 0;
    gap: 16px;

    .AddToCart__Button {
      padding: 0;
    }
  }

  .ProductResultItem__From-Price {
    color: $black;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
  }

  .ProductResultItem__Wrapper-Compare-Container {
    margin-left: auto;
  }

  .ProductResultItem__Compare-CheckIcon {
    position: absolute;
    top: -8px;
    right: -10px;
  }

  .ProductResultItem__Info-Message {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $black;
  }
}

// We don't have Layout switcher at the moment, but for example downloads lister uses this styles
// DO NOT DELETE
.ListLayout {
  .HiddenOnCard {
    border-top: 1px solid $black-10;
    margin-bottom: 24px;
  }

  .ProductResultItem {
    background-color: $white;
    width: 100%;
    padding: 16px 0px 24px;

    @media (min-width: 800px) {
      padding: 24px 24px 32px 24px;
    }

    img {
      max-width: 124px;
      max-height: 124px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0 auto 8px auto;
      display: block;

      @media (min-width: 800px) {
        max-width: 200px;
        max-height: 200px;
        margin: 0 64px 0 0;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }

  .ProductResultItem__Link {
    text-decoration: none;

    @media (min-width: 800px) {
      display: flex;
    }
    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $primary;
      text-decoration: none;
    }
  }

  .ProductResultItem__Wrapper {
    @media (min-width: 800px) {
      flex-grow: 1;
      max-width: 66.66%;
    }
  }

  .ProductResultItem__Wrapper-Title {
    color: $black-80;
    font-family: var(--font-family-base);
    font-weight: bold;
    padding: 8px 24px;

    @media (min-width: 800px) {
      padding: 8px 0px;
    }
  }

  .ProductResultItem__Wrapper-Description {
    color: $black-60;
    font-family: var(--font-family-base);
    font-weight: 400;
    padding: 0 24px 16px 24px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;

    @media (min-width: 800px) {
      padding: 0 0 16px 0;
    }
  }

  .ProductResultItem__Wrapper-SubDescription {
    display: none;
  }

  .ProductResultItem__Wrapper-DetailsRow {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    @media (min-width: 800px) {
      border-bottom: 1px solid $black-10;
    }
  }

  .ProductResultItem__Wrapper-DetailsRow-Cell,
  .ProductResultItem__Wrapper-DetailsRow-BoldedCell {
    color: $black-60;
    font-family: var(--font-family-base);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
    width: 50%;
    padding: 8px 0 8px 8px;
    border-bottom: 1px solid $black-10;

    @media (min-width: 800px) {
      width: 25%;
      font-size: 14px;
      border-bottom: none;
    }
  }

  .ProductResultItem__Wrapper-DetailsRow-Cell {
    @media (max-width: 800px) {
      padding: 8px 8px 8px 24px;
    }
  }

  .ProductResultItem__Wrapper-DetailsRow-BoldedCell {
    font-family: var(--font-family-base);
    font-weight: bold;

    @media (max-width: 800px) {
      text-align: right;
      padding: 8px 24px 8px 8px;
    }
  }

  .ProductResultItem__Wrapper-Compare-Container {
    position: relative;

    .CompareBox {
      display: none;
      visibility: visible;

      @media (max-width: 800px) {
        position: fixed;
        width: 100%;
        background-color: $white;
        padding: 24px;
        z-index: 105;
        top: 0;
        right: 0;
        height: 100vh;
        max-height: 100vh;
        overflow: auto;

        &::after {
          display: none;
        }
      }

      @media (min-width: 801px) {
        transform: translateX(-50%);
        bottom: 28px;
        left: 272px;

        &::after {
          left: 50%;
        }
      }
    }
  }

  .ProductResultItem__Wrapper-Compare {
    padding-left: 24px;

    @media (min-width: 800px) {
      padding-left: 0;
      margin-left: 264px;
    }

    label {
      display: inline-block;
      position: relative;
      padding: 0 24px;
      font-family: var(--font-family-base);
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 16px;
      cursor: pointer;
      color: $black-80;
    }

    @include checkbox-input-style;
  }
}
