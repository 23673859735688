@import '../../../assets/typography-mixins.scss';

.LoadMore {
  text-align: center;
}

.LoadMore__Button {
  color: #fff;
  margin-top: 56px;
  margin-bottom: 80px;
  font-family: var(--font-family-base);
  font-weight: 700;
  @include text-section-title-small;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: #d40028;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  border: none;

  &:hover {
    background-color: #b40028;
  }
}
