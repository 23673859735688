.compareZIndexWithRelativePosition {
  z-index: 99;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  .CompareStickyMenu {
    z-index: 99;
    top: 70px !important;
    box-shadow: 0 5px 10px 0 $black-10;
    display: none;

    @media (min-width:#{$small-desktop}) {
      &.Active {
        display: block;
        animation: slide-down 0.3s ease-out;
      }
    }

    &-Container {
      display: flex;
      align-items: center;
      background-color: $white;
    }

    &-Top {
      width: 20%;
      padding-left: 32px;

      &-Container {
        color: $primary;
        font-weight: bold;
        @include text-section-title-small;
        text-transform: uppercase;
        display: inline-block;
        cursor: pointer;

        svg {
          margin-right: 8px;
          margin-bottom: -2px;
        }

        &:hover {
          color: $primary-hover;

          svg.sprite-icon {
            color: $primary-hover;
          }
        }
      }
    }

    &-Item {
      width: 20%;

      &:first-child {
        padding-left: 20px;
      }

      padding-left: 10px;
    }

    &-Title {
      padding-top: 12px;

      a {
        display: inline-block;
        color: $black-80;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
        text-transform: uppercase;

        &:hover {
          color: $primary;
        }
      }
    }

    &-Link {
      padding-top: 8px;
      padding-bottom: 12px;

      a {
        color: $primary;
        display: inline-block;
        font-weight: bold;
        @include text-title-xs;
        text-transform: uppercase;

        &:hover {
          color: $primary-hover;
        }
      }
    }
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
