.MyAccountTabsComponent {
  &-TabsWrapper {
    border-bottom: 1px solid $black-10;
    margin-top: 16px;
    @media (min-width:#{$small-desktop}) {
      margin-top: 64px;
    }
    &-Container {
      display: flex;
      @include component-container;
      &-Tab {
        color: $black-60;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.57px;
        line-height: 16px;
        padding: 24px;
        text-align: center;
        position: relative;
        text-transform: uppercase;
        white-space: nowrap;
        @media (min-width:#{$small-desktop}) {
          font-size: 14px;
          letter-spacing: 3px;
          line-height: 19px;
        }
        &.Active {
          a {
            color: $primary;
          }
          &:after {
            content: '';
            display: block;
            height: 4px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(90deg, #ec0051 0%, #d40028 100%);
          }
        }
        &.Disabled {
          color: $black-30;
          cursor: default;
        }
      }
    }
  }
  &-Content {
    .Components-StickyMenu-Padding {
      @media (min-width:#{$small-desktop}) {
        margin-top: -80px;
      }
    }
  }
}
