@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';
@import '../../../assets/typography-mixins.scss';

.SuggestionResultItem {
  color: #004990;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  align-items: center;

  &:hover {
    .SuggestionResultItem__category,
    .SuggestionResultItem__title {
      color: #d40028;
    }
  }
}

.SuggestionResultItem__icon {
  display: none;
  margin-left: auto;
  flex-shrink: 0;
}

.SuggestionResultItem__selected {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    right: -24px;
    bottom: 0;
    background-color: #f9f9f9;
    z-index: -1;
  }

  .SuggestionResultItem__category,
  .SuggestionResultItem__title {
    color: #d40028;
  }

  .SuggestionResultItem__icon {
    display: block;
  }
}

.SuggestionResultItem__image {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-right: 24px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.SuggestionResultItem__text {
  display: block;
  cursor: pointer;
}

.SuggestionResultItem__category {
  display: block;
  color: #333;
  font-size: 10px;
  letter-spacing: 2.14px;
  line-height: 14px;
}

.SuggestionResultItem__title {
  display: block;
  color: #333;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 25px;
  font-weight: 700;
}
