.ResultLink,
.ResultLink a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.ResultLink--larger {
  color: $black-80;
  font-family: var(--font-family-base);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 25px;

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.ResultLink--important {
  font-family: var(--font-family-base);
  font-weight: bold;
  @include text-section-title-medium;
  padding-top: 24px;
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  color: $primary;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: $primary-hover;
  }

  &:visited {
    color: $primary;
    text-decoration: none;
  }

  svg {
    margin-left: 16px;
    margin-bottom: 2px;
    flex-shrink: 0;
  }
}
