.AddressCard {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  background-color: $white;

  @include min-small-desktop {
    min-height: 272px;
    height: 100%;
  }

  @include max-small-desktop {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0 16px;
    grid-template-areas:
      'header header'
      'info actions';
  }
}

.AddressCard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  @include max-small-desktop {
    grid-area: header;
  }
}

.AddressCard__type {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.333333333;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: $black-80;
}

.AddressCard__company-name {
  font-weight: bold;
}

.AddressCard__info {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428571429;
  color: $black-80;

  @include max-small-desktop {
    grid-area: info;
  }

  @include min-small-desktop {
    margin-bottom: 16px;
  }
}

.AddressCard__country {
  text-transform: uppercase;
}

.AddressCard__actions {
  margin-top: auto;
  display: flex;

  @include max-small-desktop {
    grid-area: actions;
  }
}

.AddressCard__personal-title {
  font-weight: bold;
}

.AddressCard__delete-button {
  margin-left: 24px;

  @include min-small-desktop {
    margin-left: 16px !important;
    padding-left: 16px !important;
    border-left: 1px solid $black-10;
  }

  span {
    transform: translateY(1px);
  }
}

.AddressCard__edit-button {
  span {
    transform: translateY(1px);
  }
}

.AddressCard__edit-button--desktop,
.AddressCard__delete-button--desktop {
  display: none;

  @include min-small-desktop {
    display: inline-flex;
  }
}

.AddressCard__edit-button--mobile,
.AddressCard__delete-button--mobile {
  display: block;

  @include min-small-desktop {
    display: none;
  }
}
