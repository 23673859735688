.Breadcrumb {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 40px;
  padding: 0 24px;
  background-color: $white;

  @include desktop {
    padding: 0 32px;
  }

  &-Arrow {
    margin: 0 16px;

    &-Back {
      margin: 0 16px 0 0;
    }

    &-Black {
      color: $black-80 !important;
    }

    &-Fade {
      color: $black-30 !important;
    }
  }

  &-Item {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: $black-80;
    display: inline-block;

    a {
      padding: 12px 0;
      text-decoration: none;
      display: inline-block;
      color: $black-80;
    }

    &-Flyout {
      padding: 12px 0;
      cursor: pointer;
      display: inline-block;
      outline: none;
    }

    &-Active {
      color: $black-30;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: 16px;

      display: inline-block;
    }
  }
}
