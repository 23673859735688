.MenuFlyout {
  width: 100%;
  position: absolute;
  top: calc(100% + 1px);
  border-bottom: 1px solid $border;
  background-color: $white;
  &-Close {
    height: 18px;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    @include desktop {
      right: 32px;
    }
  }
  .FlyoutFactory-Container {
    padding: 0 !important;
    margin: 0 auto !important;
  }

  &-Wrapper {
    position: relative;
    padding-top: 48px;
    padding-bottom: 24px;
  }
}
