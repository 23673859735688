.CustomSpinner {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;

  // Make it a little smaller if used within the form submit button
  .Form-Button & {
    position: relative;
    transform: translateY(-24px) scale(0.5);
    width: 50px;
    left: unset;
    top: unset;
    height: unset;
  }
}
.CustomSpinner div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #e5e5e5;
  border-radius: 50%;
  animation: CustomSpinner 1.2s linear infinite;
}
.CustomSpinner div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.CustomSpinner div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.CustomSpinner div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.CustomSpinner div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.CustomSpinner div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.CustomSpinner div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.CustomSpinner div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.CustomSpinner div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.CustomSpinner div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.CustomSpinner div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.CustomSpinner div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.CustomSpinner div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes CustomSpinner {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
