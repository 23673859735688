@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';
@import '../../../assets/typography-mixins.scss';

@keyframes coveo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Searchbox {
  min-width: 300px;
  position: relative;
  margin: 0 auto;
  font-size: 15px;
  color: #313a45;
  height: 88px;
  background-color: #000;
  padding-left: 24px;
  padding-right: 24px;

  @include min-small-desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktop {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.Searchbox__wrapper {
  max-width: 1660px;
  padding-top: 24px;
  margin-right: 0;
  display: flex;

  @include min-tablet {
    margin: 0 auto;
    padding-top: 16px;
  }
}

.Searchbox__searchbox {
  flex-grow: 1;
  text-align: left;
  color: #212121;
  border-radius: 2px;

  @include min-tablet {
    margin-right: 16px;
  }
}

.Searchbox__input-wrapper {
  background: #fff;
  height: 40px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;

  @include min-tablet {
    height: 56px;
  }
}

.Searchbox__input {
  color: #212121;
  text-align: left;
  text-indent: 0;
  white-space: pre;
  overflow: auto;
  box-sizing: border-box;
  display: block;
  background: none;
  border: none;
  resize: none;
  color: #333;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding: 16px 49px 16px 12px;
  height: 40px;
  width: 100%;

  @include min-tablet {
    height: 56px;
    font-size: 12px;
  }
}

.Searchbox__search-button {
  display: none;
  height: 56px;
  background-color: #d40028;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border: 0;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  width: 60px;
  cursor: pointer;
  line-height: 0;

  @include min-tablet {
    display: block;
  }

  &:hover svg {
    opacity: 0.8;
  }
}

.Searchbox__search-button-magnifier {
  vertical-align: middle;
  animation: none;
  display: inline-block;
  color: #fff !important;
}

.Searchbox__search-button-loading {
  vertical-align: middle;
  display: inline-block;
  color: #1d4f76;
  width: 18px;
  height: 18px;
  animation-name: coveo-spin;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  color: #fff;
}

.Searchbox__clear {
  position: relative;
  float: right;
  height: 38px;
  line-height: 38px;
  width: 48px;
  text-align: center;
  font-size: 24px;
  transition: visibility 0.3s, width 0.3s;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  top: -35px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @include min-tablet {
    height: 48px;
    line-height: 48px;
    top: -52px;
  }
}

.Searchbox__suggestions-wrapper {
  background: #fff;
  left: 0;
  right: 0;
  clear: both;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  top: 100%;
  z-index: 3;
  border-bottom: 1px solid #e5e5e5;
  overflow-x: hidden;

  padding-left: 24px;
  padding-right: 24px;

  height: calc(100vh - 88px - 70px); // 70px is the height of the header, 88px is the height of the searchbox

  @include min-small-desktop {
    padding-left: 32px;
    padding-right: 32px;
    height: auto;
    max-height: 70vh;
  }

  @include desktop {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.Searchbox__suggestions {
  padding: 0;
  padding-bottom: 24px;
  max-width: 1660px;
  margin: 0 auto;
  list-style: none;
}

.Searchbox__suggestion {
  padding: 24px 0 0;
  margin: 0;
}

.Searchbox__suggestion-link {
  color: #004990;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.Searchbox__suggestion-image {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-right: 24px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.Searchbox__suggestion-text {
  display: block;

  &:hover {
    .Searchbox__suggestion-category,
    .Searchbox__suggestion-title {
      color: #d40028;
    }
  }
}

.Searchbox__suggestion-category {
  display: block;
  color: #333;
  font-size: 10px;
  letter-spacing: 2.14px;
  line-height: 14px;
}

.Searchbox__suggestion-title {
  display: block;
  color: #333;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 25px;
  font-weight: 700;
}
