.FooterCopyright {
  display: inline-block;
  color: $black-60;
  font-size: 14px;
  line-height: 21px;
  @include tablet {
    max-width: 210px;
  }
  @include mobile {
    max-width: 210px;
    margin: 0 0 30px 0;
    padding-top: 32px;
  }
}
