.NotFound {
  margin-top: 176px;
  margin-bottom: 160px;
  padding: 0 0 0 130px;
  @include desktop {
    width: 780px;
  }

  @include small-desktop {
    width: 780px;
  }

  @include tablet {
    padding: 0 24px 0 24px;
    width: 780px;
  }

  @include mobile {
    margin-top: 40px;
    margin-bottom: 56px;
    padding: 0 24px 0 24px;
  }

  &-ErrorCode {
    color: $black-80;
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 2px;
    line-height: 66px;
  }

  &-Title {
    color: $black-80;
    font-weight: 400;
    font-size: 48px;
    letter-spacing: 2px;
    line-height: 66px;
  }

  &-Description {
    color: $black-80;
    font-weight: 400;
    @include text-body;
    margin-top: 16px;
  }

  &-Buttons {
    margin-top: 40px;
    &-Home-Link {
      margin-right: 24px;
      display: inline-block;
      a {
        font-weight: bold;
        color: $white;
        @include text-section-title-small;
        text-decoration: none;
        border-radius: 4px;
        background-color: $primary;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        padding-left: 23px;
        padding-right: 21px;
        padding-top: 19px;
        padding-bottom: 18px;
        display: inline-block;
        @include mobile {
          width: 272px;
          margin-bottom: 24px;
        }
      }

      a:hover {
        background-color: $primary-hover;
      }
    }

    &-Contact-Link {
      display: inline-block;
      a {
        font-weight: bold;
        color: $primary;
        @include text-section-title-small;
        text-decoration: none;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        padding-left: 23px;
        padding-right: 21px;
        padding-top: 19px;
        padding-bottom: 18px;
        display: inline-block;

        @include mobile {
          width: 272px;
        }
      }

      a:hover {
        color: $primary-hover;
      }
    }
  }
}
