.MyAccountDesktopMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 24px;
  background-color: $white;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  @include desktop {
    padding: 12px 32px;
  }

  &-Welcome {
    color: $black-60;
  }

  &-Link.GeneralLink {
    font-size: 12px;
    a {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      color: $primary;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: $primary-hover;
      }
    }
  }

  &-Logout {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: $primary;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: $primary-hover;
    }
  }

  &-Separator {
    padding: 0 8px;
  }
}
