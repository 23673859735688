.CompareTechnicalSpecs {
  &-Row {
    @media (min-width:#{$small-desktop}) {
      display: flex;
      width: 100%;
      color: $black-60;
      box-shadow: inset 0 -1px 0 0 $black-10;
      background-color: $white;
      font-size: 14px;
      &:hover {
        background-color: $black-3;
      }
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
  &-Item {
    @media (min-width:#{$small-desktop}) {
      width: 20%;
      &.CompareTechnicalSpecs-Name {
        padding-left: 32px;
        font-weight: bold;
        letter-spacing: 3px;
        padding-top: 19px;
        padding-bottom: 20px;
        text-transform: uppercase;
      }
      &.CompareTechnicalSpecs-Value {
        padding-left: 20px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 56px;
      }
    }
  }
}
