:root {
  --header-height: 72px;
  --toggle-height: 24px;
  --bottom-dialog-spacing: 24px;
}

.ProductVariantSelector__Content {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.ProductVariantSelector__Content--Mobile {
  overflow-y: auto;
  padding: 0;
}

.ProductVariantSelectorTitle {
  display: flex;
  gap: 8px;
}

.ProductVariantSelectorTitle__Title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.ProductVariantSelectorTitle__Count {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.ProductVariantSelector__Table {
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - var(--header-height) - var(--toggle-height) - var(--bottom-dialog-spacing));
}

.Dialog__title--mobile.ProductVariantSelector__title {
  padding: 16px 20px;
  border-bottom: 1px solid $black-10;
}

.SelectVariantMobile + .Dialog__title--mobile {
  padding-top: 48px;
}
