.StoriesPromotionBox {
  margin: 0 auto;
  padding: 48px 0 56px;
  overflow: hidden;

  @include small-desktop {
    padding: 80px 0;
  }

  @include desktop {
    padding: 112px 0 120px;
  }

  &-Headline {
    margin: 0 auto;
    padding: 0 12px 32px 12px;

    @media (min-width: #{$small-desktop}) {
      padding: 0 12px 56px 12px;
    }
  }

  &-Card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 12px;

    @media (min-width: #{$small-desktop}) {
      box-shadow: none;
    }

    &-Image {
      overflow: hidden;
      text-decoration: none;
      display: flex;
      align-items: flex-start;

      img {
        width: 100%;
        height: auto;
        transition: transform 1000ms ease-in;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &-Content {
      padding: 24px;
      background-color: $white;

      @media (min-width: #{$small-desktop}) {
        padding: 40px;
      }

      &-Category {
        display: inline-block;
        text-transform: uppercase;
        color: $black-60;
        @include text-title-xs;
        font-weight: bold;
        margin-right: 8px;
      }

      &-Date {
        @media (min-width: #{$small-desktop}) {
          display: inline-block;

          &:before {
            content: '|';
            margin-right: 8px;
          }
        }

        color: $black-60;
        font-size: 12px;
        line-height: 21px;
        white-space: nowrap;
      }

      &-Title {
        margin-top: 8px;
        color: $black-80;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 19px;

        @media (min-width: #{$small-desktop}) {
          font-size: 18px;
          line-height: 25px;
        }
      }

      &-Link {
        display: none;
        margin-top: 24px;
        text-transform: uppercase;
        text-decoration: none;
        color: $primary;
        font-weight: bold;
        @include text-section-title-small;
        position: relative;

        @media (min-width: #{$small-desktop}) {
          display: block;
        }

        svg {
          margin-left: 16px;
          position: absolute;
          top: 1px;
        }

        &:hover {
          color: $primary-hover;

          svg {
            &.sprite-icon {
              color: $primary-hover;
            }
          }
        }
      }
    }
  }

  &-DesktopContainer {
    display: flex;

    .StoriesPromotionBox-Card {
      width: 33.33%;
    }
  }

  &-TabletContainer {
    display: flex;
    padding-right: 12px;

    .StoriesPromotionBox-Card {
      width: 50%;
    }
  }

  &-MobileContainer {
    padding-right: 12px;

    .StoriesPromotionBox-Card {
      padding: 0 12px;
    }
  }
}

.StoriesPromotionBox.WhiteColorStyle {
  .StoriesPromotionBox-Headline-Title,
  .StoriesPromotionBox-Headline-Subtitle,
  .StoriesPromotionBox-Card-Content-Category,
  .StoriesPromotionBox-Card-Content-Title {
    color: $white;
  }

  .objectSlider-arrowRight,
  .objectSlider-arrowLeft {
    svg {
      &.sprite-icon {
        color: $white;
      }
    }
  }

  .alice-carousel__dots-item {
    border: 2px solid $white;
  }

  .alice-carousel__dots-item.__active {
    border: 2px solid $primary;
  }
}
