.BundleItem {
  padding: 0;
  gap: 16px;
  width: 100%;
  text-decoration: none;
  color: $black-80;
  padding: 16px 16px 0 16px;
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-areas:
    'image variantDetails'
    'icon icon';
  align-items: flex-start;

  @include min-tablet {
    gap: 8px;
    padding: 16px 0 16px 16px;
    grid-template-areas: 'image variantDetails icon';
  }

  &.BundleItem--additional {
    grid-template-areas: 'image variantDetails';
    background-color: $white;
    padding: 16px;
  }
}

.BundleItem__image {
  grid-area: image;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  img {
    max-height: 100%;
    height: auto;
  }
}

.BundleItem__variant {
  grid-area: variantDetails;
  display: flex;
  flex: 1 1 auto;
}

.BundleItem__product-name {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  margin-right: 16px;
}

.BundleItem__product-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.015em;
  text-align: left;
  color: $black-80;
}

.BundleItem__product-sku {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: $black-80;
}

.BundleItem__price {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: $black-80;
}

.BundleItem__main-plus-icon {
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  margin: 0;
  align-self: center;

  @include min-tablet {
    flex: 0 0 40px;
    height: 40px;
  }
}
