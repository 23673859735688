.StartPageHero-HeightPlaceholder {
  width: 100%;
  z-index: -1;
  display: flex;
}
.StartPageHero {
  z-index: -1;
  position: fixed;
  width: 100%;
  overflow: hidden;
  &-Image {
    width: 100%;
    @media (min-width: #{$small-desktop}) {
      height: 100%;
      object-fit: cover;
    }
  }
  &-Headline {
    position: relative;
    width: 100%;
    font-weight: bold;

    @media (min-width: #{$small-desktop}) {
      position: absolute;
      bottom: 0;
      width: 710px;
      height: 405px;
    }

    img {
      vertical-align: bottom;
      height: 100%;
      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }
    }

    &-Overline {
      color: $black-60;
      @include text-title-xs;
      text-transform: uppercase;

      @media (min-width: #{$small-desktop}) {
        padding-bottom: 16px;
        font-size: 18px;
        letter-spacing: 3.86px;
        line-height: 25px;
      }
    }
    &-Title {
      margin: 16px 0 0 0;
      color: $black-80;
      letter-spacing: 2px;
      font-size: 36px;
      line-height: 42px;
      @media (min-width: #{$small-desktop}) {
        margin: 0;
        font-size: 60px;
        line-height: 82px;
      }
    }

    &-Wrapper {
      padding: 32px 24px;

      @media (min-width: #{$small-desktop}) {
        padding: 136px 130px 64px 96px;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
