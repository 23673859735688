.ProductComparisonAccordion {
  max-width: 1920px;
  margin: 0 auto;

  &-Heading {
    padding: 18px 24px;
    background-color: $black-80;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: #{$small-desktop}) {
      padding: 18px 32px;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    &-Container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &.Active svg {
      transform: none;
      transition: 0.4s ease;
    }

    &-Title {
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
      display: inline-block;
    }

    svg {
      transform: rotate(180deg);
      transition: 0.4s ease;
      margin-bottom: -3px;
      margin-left: 32px;

      &.sprite-icon {
        color: $white;
      }
    }

    &-Systems {
      display: inline-block;
      background-color: $white;
      padding: 4px;
      text-transform: uppercase;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      margin-top: 16px;
      line-height: 16px;

      @media (min-width: #{$small-desktop}) {
        margin-top: 0;
        display: inline-block;
        margin-left: 32px;
      }

      &-Imperial,
      &-Metric {
        display: inline-block;
        padding: 3px 16px;
        font-weight: bold;
        font-size: 9px;
        letter-spacing: 2.14px;
        color: $black-60;
        border-radius: 13px;
        outline: none;
        cursor: pointer;

        &-Active {
          background-color: $black-80;
          color: $white;
        }
      }
    }
  }

  &-Content {
    display: none;

    &.Active {
      display: block;
    }
  }
}
