.USP {
  padding-top: 16px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-weight: 400;
      @include text-body;
      color: $black-80;
      margin-bottom: 16px;

      &::before {
        content: '';
        background-color: $primary;
        width: 7px;
        height: 7px;
        display: inline-block;
        margin-right: 16px;
        margin-bottom: 2px;
      }
    }
  }
}
