.Toggle {
  display: inline-flex;
  position: relative;
  border-radius: 9999px;
  border-width: 2px;
  border-color: transparent;
  margin: 0;
  padding: 0;
  width: 2.75rem;
  height: 1.5rem;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  cursor: pointer;
  background-color: $black-10;

  &.Toggle--checked {
    background-color: $primary;
  }
}

.Toggle__marker {
  position: absolute;
  left: 2px;
  display: inline-block;
  border-radius: 9999px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
  width: 1.25rem;
  height: 1.25rem;
  background-color: $white;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  pointer-events: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transform: translateX(0);
}

.Toggle__marker--checked {
  transform: translateX(1.25rem);
}

.Toggle_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.Toggle_label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.15em;
  cursor: pointer;
}

.toggle__input {
  @include visually-hidden;
}
