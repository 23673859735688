.CompareCategories {
  background-color: $black-3;
  height: 100%;
  padding-bottom: 24px;
  padding-top: 24px;

  @media (min-width: #{$small-desktop}) {
    padding-bottom: 0;
    padding-top: 28px;
    box-shadow: inset -1px 0 0 0 $black-10;
  }

  .Dropdown {
    margin: 0 24px;
    width: auto;

    &-Options {
      width: 100%;
    }

    &-Wrapper {
      padding-top: 0;
    }
  }

  &-BackButton {
    padding: 0 24px 24px 24px;

    a {
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;

      svg {
        margin-bottom: -3px;
        margin-right: 8px;
      }

      &:hover {
        color: $primary-hover;

        svg.sprite-icon {
          color: $primary-hover;
        }
      }
    }

    @media (min-width: #{$small-desktop}) {
      padding: 0 32px 28px 32px;
    }
  }

  &-CategoryItem {
    padding: 18px 24px 18px 32px;
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: calc(100% - 56px);
      left: 32px;
      border-bottom: 1px solid $black-10;
    }

    &:first-child {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 56px);
        left: 32px;
        border-bottom: 1px solid $black-10;
      }
    }

    &:hover {
      color: $primary;
    }

    &-InfoButton {
      visibility: hidden;
      position: relative;

      svg {
        width: 16px;
        height: 16px;
      }

      &.Active {
        visibility: visible;

        &:hover {
          .CompareCategories-CategoryItem-InfoButton-Message {
            visibility: visible;
          }
        }
      }

      &-Message {
        @include tooltip-product;
        width: 224px;
        white-space: normal;
        text-align: center;
        bottom: 136%;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
        font-weight: 400;
        text-transform: none;
        padding: 8px;
      }
    }

    &.Active {
      background-color: $white;
      box-shadow: 0 -1px 0 0 $black-10, 0 1px 0 0 $black-10;
      color: $primary;

      &:before,
      &:after {
        display: none;
      }
    }

    &-Title {
      display: flex;
      width: 100%;

      &-Name {
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
      }
    }
  }
}
