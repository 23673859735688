.FilterBanner {
  min-height: 480px;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background-image: url(./../../../assets/css-svg/bg_weldy_triangle-mesh.svg);
  background-size: cover;

  &-Leister {
    background-image: url(./../../../assets/css-svg/bg_leister_triangle-mesh.svg);

    .FilterBanner-Button {
      a {
        background-color: $primary;
        color: $white;

        &:hover {
          background-color: $primary-hover;
          color: $white;
        }
      }
    }
  }

  @include mobile {
    min-height: 320px;
  }

  &-BackgroundImage {
    width: 100%;
    min-height: 480px;
    object-fit: cover;
    display: flex;

    @include mobile {
      min-height: 320px;
    }
  }

  &-Details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 128px;

    @include tablet {
      padding-left: 24px;
    }

    @include mobile {
      padding-left: 24px;
    }
  }

  &-Title {
    color: $white;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 49px;
    text-transform: uppercase;
    padding-top: 32px;
    cursor: default;

    @include mobile {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      padding-top: 64px;
    }
  }

  &-Subtitle {
    color: $white;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0.7px;
    line-height: 49px;
    padding-bottom: 24px;
    cursor: default;

    @include mobile {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      text-transform: uppercase;
      padding-bottom: 16px;
    }
  }

  &-LogoImage {
    max-width: 220px;
    max-height: 40px;

    @include mobile {
      max-width: 136px;
      max-height: 24px;
    }
  }

  &-Button {
    display: inline-block;

    a {
      padding: 12px 16px;
      display: inline-block;
      background-color: $white;
      @include text-title-xs;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      color: $weldy-main;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }
}
