.LinkerCarousel {
  margin: 0 auto;
  padding: 48px 0;
  overflow: hidden;

  @media (min-width: #{$small-desktop}) {
    padding: 80px 0;
  }

  &-Headline {
    margin: 0 auto;
    padding: 0 12px 32px 12px;

    @media (min-width: #{$small-desktop}) {
      padding: 0 12px 56px 12px;
    }
  }

  &-Description {
    padding: 0 24px 24px 12px;
    color: $black-80;
    @include text-body;

    @include tablet {
      padding-left: 52px;
      padding-right: 64px;
    }

    @media (min-width: #{$small-desktop}) {
      width: 66.66%;
      margin: 0 auto;
      padding: 0 0 24px 0;
    }

    @include rich-text-list-style;
    @include rich-text-a-style;

    p {
      padding-top: 0;
      padding-bottom: 16px;
      margin: 0;
    }

    h3,
    h4 {
      padding-bottom: 16px;
    }

    h2 {
      padding-bottom: 24px;
    }

    .RichTextWithCheckmarks-Item {
      p:first-child {
        margin: 0;
      }

      position: relative;

      .RichTextWithCheckmarks-Text {
        display: inline-block;
        margin-left: 52px;
        margin-top: 8px;
      }

      svg {
        position: absolute;
        left: 16px;
        top: 12px;
      }
    }
  }

  &-Card {
    padding: 12px;

    &-Image {
      overflow: hidden;
      font-size: 0;

      img {
        width: 100%;
        height: auto;
        transition: transform 1000ms ease-in;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &-Link {
      text-decoration: none;
      display: inline-block;
    }

    &-Content {
      padding: 24px;
      background-color: $white;

      &-Category {
        text-transform: uppercase;
        color: $black-60;
        font-weight: bold;
        @include text-section-title-small;
      }

      &-Title {
        margin-top: 8px;
        color: $black-80;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 33px;
      }

      &-Description {
        margin-top: 16px;
        color: $black-80;
        @include text-body;
        @include rich-text-a-style;
        @include rich-text-list-style;
      }

      &-Link-Text {
        margin-top: 24px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        color: $primary;
        font-weight: bold;
        @include text-section-title-small;
        text-decoration: none;

        &::after {
          content: url(./../../../assets/css-svg/arrow-right.svg);
          height: 18px;
          margin-left: 16px;
          margin-bottom: 3px;
        }

        &:hover {
          color: $primary-hover;

          &::after {
            content: url(./../../../assets/css-svg/arrow-right-hover.svg);
          }
        }
      }
    }
  }

  &-ThreeVisibleContainer {
    display: flex;

    .LinkerCarousel-Card {
      width: 33.33%;
    }
  }

  &-TwoVisibleContainer {
    padding-right: 12px;
    display: flex;

    .LinkerCarousel-Card {
      width: 50%;
    }
  }

  &-OneVisibleContainer {
    padding-right: 12px;

    .LinkerCarousel-Card {
      padding: 0 12px;
    }
  }
}

.LinkerCarousel.WhiteColorStyle {
  .LinkerCarousel-Headline-Title,
  .LinkerCarousel-Headline-Subtitle,
  .LinkerCarousel-Card-Content-Category,
  .LinkerCarousel-Card-Content-Title,
  .LinkerCarousel-Card-Content-Description {
    color: $white;
  }

  .objectSlider-arrowRight,
  .objectSlider-arrowLeft {
    svg {
      color: $white;
    }
  }

  .alice-carousel__dots-item {
    border: 2px solid $white;
  }

  .alice-carousel__dots-item.__active {
    border: 2px solid $primary;
  }
}

.LinkerCarousel-Card-Image:hover ~ .LinkerCarousel-Card-Content {
  .LinkerCarousel-Card-Content-Link-Text {
    color: $primary-hover;
  }

  .LinkerCarousel-Card-Content-Link svg {
    color: $primary-hover;
  }
}
