.IconsMenu {
  &-user,
  &-heart,
  &-search {
    margin-left: 24px;
    cursor: pointer;
    outline: none;
    width: 24px;
    height: 24px;
    max-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      svg.sprite-icon {
        color: $primary;
      }
    }
  }

  &-heart {
    > .GeneralLink,
    > .GeneralLink a {
      display: inline-block;
      position: relative;
      max-height: 24px;
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $primary;
      font-weight: bold;
      font-size: 10px; // On 4k screen, 9px doesn't look nice
      letter-spacing: 0;
      line-height: 18px;
      color: $white;
      height: 18px;
      width: 18px;
      border: 2px solid $white;
      position: absolute;
      bottom: -4px;
      right: -8px;
      padding-top: 1px;
    }
  }

  &-search {
    a {
      display: inline-block;
    }
  }

  &-userSignedIn {
    position: relative;
    cursor: pointer;
    margin-bottom: -8px;

    a {
      display: inline-block;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 12px;
      left: 16px;
      width: 24px;
      height: 24px;
      background-image: url(./../../../assets/css-svg/green-dot-checkmark.svg);
      background-repeat: no-repeat;
    }

    &.MobileMyAccountOpen {
      &:after {
        display: none;
      }
    }
  }
}
