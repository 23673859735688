.LanguageFlyout {
  width: 380px;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  text-transform: none;
  top: 40px;
  right: 20px;
  padding-bottom: 32px;
  @include small-desktop {
    margin-left: -300px;
  }
  @include max-small-desktop {
    width: 100%;
    box-shadow: none;
    right: 0;
    position: fixed;
  }

  &:before {
    content: '';
    bottom: 100%;
    left: 54%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: $white;
    border-width: 6px;
    margin-left: -8px;
    @include small-desktop {
      left: 89%;
    }
    @include max-small-desktop {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    transform: rotate(225deg);
    height: 12px;
    width: 12px;
    z-index: -1;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    left: 52%;
    top: -5px;
    @include small-desktop {
      left: 87%;
    }

    @include max-small-desktop {
      display: none;
    }
  }
  &-Headline {
    color: $black-80;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 25px;
    display: inline-block;
    margin: 32px 0 0 32px;

    @include tablet {
      margin: 32px auto 0 auto;
      width: 320px;
      display: block;
    }

    @include mobile {
      margin: 32px auto 0 auto;
      width: 272px;
      display: block;
    }
  }
  &-Description {
    color: $black-60;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 8px 0 22px 32px;
    white-space: normal;
    padding-right: 16px;

    @include rich-text-a-style;
    @include rich-text-list-style;
    @include tablet {
      margin: 8px auto 22px auto;
      width: 320px;
      display: block;
      white-space: normal;
    }
    @include mobile {
      margin: 8px auto 22px auto;
      width: 272px;
      display: block;
      white-space: normal;
    }
  }

  &-Close {
    position: absolute;
    top: 35px;
    right: 32px;
    outline: none;
    padding: 2px;
    svg {
      width: 12px;
      height: 12px;
    }
    @media (min-width: #{$small-desktop}) {
      display: none;
    }
  }

  &-Hide {
    display: none;
  }
  &-ApplyButton {
    width: 272px;
    margin: 24px auto 0 auto;
    background-color: $primary;
    border-radius: 4px;
    height: 40px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    @media (min-width: #{$tablet}) {
      width: 320px;
      cursor: pointer;
    }
    &:hover {
      background-color: $primary-hover;
    }
    &-Text {
      padding: 12px 0;
      color: $white;
      text-transform: uppercase;
      @include text-title-xs;
      font-weight: bold;
      text-align: center;
    }
    &-Disable {
      background-color: $primary-30;
      width: 272px;
      margin: 24px auto 0 auto;
      border-radius: 4px;
      height: 40px;
      @media (min-width: #{$tablet}) {
        width: 320px;
        cursor: default;
      }
    }
  }
}
