.ProductCategoryItems {
  &-Title {
    margin-top: 24px;
    color: $black-80;
    margin-bottom: 24px;

    @include mobile {
      font-size: 18px;
    }

    @include min-tablet {
      margin-top: 64px;
    }

    span {
      display: inline-block;
      font-weight: 400;
    }
  }

  &-ShowMore {
    margin-top: 32px;
    font-weight: bold;
    @include text-section-title-small;
    color: $primary;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include mobile {
      padding: 0 24px;
    }

    svg {
      margin: 0 16px 2px 0;
    }

    &:hover {
      color: $primary-hover;

      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }
}

.ProductCategoryItems-Label {
  display: flex;
  padding-left: var(--download-item-padding-inline);
}

.ProductCategoryItems-Label--Select-All {
  padding-left: var(--bulk-download-item-padding-left);
  flex-grow: 1;

  @include checkbox-input-style-extended;

  .checkmark {
    left: var(--download-item-padding-inline);
    top: 28px;

    @include min-tablet {
      top: 72px;
    }
  }
}
