.TextBox {
  color: #fff;
  min-height: 138px;
  padding: 40px;
  font-weight: 700;
  overflow-wrap: break-word;
  background: rgba(206, 0, 36, 0.88);
  backdrop-filter: blur(8px);

  display: flex;
  align-items: flex-end;
  gap: 8px;

  @include desktop {
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;

    @include min-tablet {
      gap: 16px;
    }

    @include desktop {
      gap: 24px;
    }
  }

  .overline {
    font-size: 12px;
    letter-spacing: 1.2px;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;

    @include desktop {
      font-size: 14px;
      letter-spacing: 1.4px;
    }
  }

  // Rendering of text without link
  .title {
    font-style: normal;
    line-height: normal;
    // Make sure new lines work
    white-space: pre-wrap;

    font-size: 24px;
    letter-spacing: 0.24px;

    @include desktop {
      font-size: 48px;
      letter-spacing: 0.48px;
    }
  }

  // Rendering of text with link
  &.has-link {
    .title {
      font-size: 18px;
      letter-spacing: 0.18px;

      @include min-tablet {
        font-size: 24px;
        letter-spacing: 0.24px;
      }

      @include desktop {
        font-size: 32px;
        letter-spacing: 0.32px;
      }
    }
  }

  .link {
    display: flex;
    align-items: center;
    gap: 8px;

    .link-text {
      display: none;

      @include desktop {
        display: inline;
        font-size: 12px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
    }

    .text-box-link-icon {
      @include desktop {
        margin-bottom: 2px;
        transition: transform 0.2s ease-in-out;

        a:hover & {
          transform: translateX(6px);
        }
      }
    }
  }
}
