.LeftImageWithRichText {
  margin: 2px auto 0 auto;
  padding: 0 0 16px 0;
  color: $black-80;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &:before {
    content: '';
    display: table;
    clear: both;
  }
  &-Text {
    width: 100%;
    margin: 0 auto;
    @include text-body;
    padding: 0 24px;
    @include tablet {
      padding: 0 40px;
    }
    @include rich-text-a-style;
    @include rich-text-list-style;
    ol,
    ul {
      display: table;
    }

    p {
      padding-top: 0;
      padding-bottom: 16px;
      margin: 0;
    }

    h2 {
      padding-top: 0;
      padding-bottom: 24px;
      @media (min-width: #{$small-desktop}) {
        line-height: 40px;
      }
    }
    .RichTextWithCheckmarks-Item {
      p:first-child {
        margin: 0;
      }
      display: table;
      position: relative;
      .RichTextWithCheckmarks-Text {
        display: inline-block;
        margin-left: 52px;
        margin-top: 8px;
      }
      svg {
        position: absolute;
        left: 16px;
        top: 12px;
      }
    }
  }
  &-ImageContainer {
    margin-bottom: 32px;
    width: 100%;
    img {
      margin-top: 6px;
      height: auto;
      width: 100%;
    }
  }
  &-ImageContainer:after {
    content: '';
    display: table;
    clear: both;
  }
  &-ImageContainer:before {
    content: '';
    display: table;
    clear: both;
  }
  @include tablet {
    &-ImageContainer {
      margin: 0 24px 8px 0;
      float: left;
      width: 50%;
    }
  }

  @media (min-width: #{$small-desktop}) {
    &-Text {
      width: 66.66%;
      padding: 0;
    }
    &-ImageContainer {
      margin: 0 24px 24px 8.33%;
      float: left;
      width: 41.66%;
    }
  }
}

/*
====================================================================================================
IMAGE ON THE RIGHT
====================================================================================================
*/

.RightImageWithRichText {
  margin: 0 auto;
  padding: 4px 0 16px 0;
  color: $black-80;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &:before {
    content: '';
    display: table;
    clear: both;
  }

  &-Text {
    width: 100%;
    margin: 0 auto;
    @include text-body;
    padding: 0 24px;
    @include tablet {
      padding: 0 40px;
    }
    @include rich-text-a-style;
    @include rich-text-list-style;
    p {
      padding-top: 0;
      padding-bottom: 16px;
      margin: 0;
    }

    h2 {
      padding-top: 0;
      padding-bottom: 24px;
      @media (min-width: #{$small-desktop}) {
        line-height: 40px;
      }
    }
    .RichTextWithCheckmarks-Item {
      p:first-child {
        margin: 0;
      }
      position: relative;
      display: table;
      .RichTextWithCheckmarks-Text {
        display: inline-block;
        margin-left: 52px;
        margin-top: 8px;
      }
      svg {
        position: absolute;
        left: 16px;
        top: 12px;
      }
    }
  }

  &-ImageContainer {
    margin-bottom: 32px;
    width: 100%;
    img {
      margin-top: 4px;
      height: auto;
      width: 100%;
    }
  }

  @include tablet {
    &-ImageContainer {
      margin: 0 0 8px 24px;
      float: right;
      width: 50%;
    }
  }
  @media (min-width: #{$small-desktop}) {
    &-Text {
      width: 66.66%;
      padding: 0;
    }
    &-ImageContainer {
      margin: 0 8.33% 24px 24px;
      float: right;
      width: 41.66%;
    }
  }
}
.WhiteColorStyle.RightImageWithRichText,
.WhiteColorStyle.LeftImageWithRichText {
  color: $white;
}
