.MediaCarousel {
  padding-bottom: 40px;
  padding-top: 24px;

  @media (min-width: #{$small-desktop}) {
    padding-bottom: 64px;
    padding-top: 32px;
  }

  &-Container {
    width: 100%;
    margin: 0 auto;

    @media (min-width: #{$small-desktop}) {
      width: 83.33%;
    }
  }

  &-Image {
    height: 100%;

    img {
      height: 100%;
    }
  }

  .carousel {
    .control-dots {
      text-align: left;
      padding-left: 24px;
      margin-bottom: 24px;

      @include mobile {
        text-align: center;
        padding-left: 0;
      }

      .dot {
        width: 18px;
        height: 18px;
        margin: 0 4px;
        background: none;
        border: 2px solid $white;
        opacity: 0.3;
        outline: none;

        &.selected {
          position: relative;
          opacity: 1;
        }

        &.selected:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: $white;
          border-radius: 50%;
          position: absolute;
          z-index: 10;
          top: 4px;
          left: 4px;
        }
      }
    }

    .slide .legend {
      display: none;
    }

    &.carousel-slider .control-arrow {
      top: 45%;
      bottom: 45%;
      font-size: 35px;

      @include mobile {
        display: none;
      }
    }

    &.carousel-slider .control-arrow:hover {
      background: none;
      opacity: 1;
    }

    .control-prev.control-arrow {
      &::before {
        content: '\2190';
        border: none;
      }
    }

    .control-next.control-arrow {
      &::before {
        content: '\2192';
        border: none;
      }
    }
  }

  &-Video {
    .video-react-poster {
      background-size: cover;
    }

    .video-react .video-react-big-play-button {
      top: 50%;
      left: 50%;
      border: none;
      width: 80px;
      height: 80px;
      background: none;
      margin-left: -40px;
      margin-top: -40px;
      background-image: url(./../../../assets/css-svg/play-full.svg);

      &:hover svg.sprite-icon {
        color: $primary-hover;
      }

      .video-react-control-text {
        display: none;
      }
    }

    .video-react .video-react-big-play-button:before {
      content: '\25B7';
      top: 2px;
      left: 3px;
      font-size: 24px;
      display: none;
    }
  }

  .MediaCarouselCaption {
    width: 83.33%;
    color: $black-60;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.35px;
    line-height: 22px;
    min-height: 22px;
    cursor: default;
    margin: 13px auto 0 auto;
    display: block;

    @include tablet {
      width: 100%;
      margin-top: 3px;
    }

    @include mobile {
      width: 100%;
      padding: 10px 24px;
      font-size: 12px;
      letter-spacing: 0.3px;
      margin: 0 auto;
    }
  }
}
