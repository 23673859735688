.SlideOver {
  position: relative;
  z-index: 110;
}

.SlideOver--enter {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.SlideOver--enter-from {
  transform: translateY(100%);

  @include min-small-desktop {
    transform: translateX(100%);
  }
}

.SlideOver--enter-to {
  transform: translateY(0);

  @include min-small-desktop {
    transform: translateX(0);
  }
}

.SlideOver--leave {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.SlideOver--leave-from {
  transform: translateY(0);

  @include min-small-desktop {
    transform: translateX(0);
  }
}

.SlideOver--leave-to {
  transform: translateY(100%);

  @include min-small-desktop {
    transform: translateX(100%);
  }
}

.SlideOver__backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.64);
  inset: 0px;
}

.SlideOver__backdrop--enter {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.SlideOver__backdrop--enter-from {
  opacity: 0;
}

.SlideOver__backdrop--enter-to {
  opacity: 1;
}

.SlideOver__backdrop--leave {
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.SlideOver__backdrop--leave-from {
  opacity: 1;
}

.SlideOver__backdrop--leave-to {
  opacity: 0;
}

.SlideOver__wrapper {
  background: $white;
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  height: 80vh;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @include min-tablet {
    height: 70vh;
  }

  @include min-small-desktop {
    width: 510px;
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.SlideOver--wide {
  .SlideOver__wrapper {
    @include min-small-desktop {
      width: 680px;
    }
  }
}

.SlideOver__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px 16px 20px;

  @include min-small-desktop {
    padding-left: 24px;
    padding-right: 24px;
    gap: 16px;
  }
}

.SlideOver__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  color: $black-80;
  padding-right: 16px;
}

.SlideOver__close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}
