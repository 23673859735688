.VideoCampaign {
  position: relative;
  cursor: pointer;
  //use font size 0 to remove image padding
  font-size: 0;
  overflow: hidden;
  max-width: 1440px;
  outline: none;
  background-color: $black;
  min-height: 128px;
  margin: 0 auto;

  @media (min-width: #{$small-desktop}) {
    margin: 0 auto 48px auto;
  }

  &.click_content_block_promotion_video {
    padding: 0;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }

    svg {
      &.sprite-icon {
        color: $primary-hover;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    min-height: 128px;
    opacity: 0.72;
    transform-origin: 0 0;
    transition: transform 1s, visibility 1s ease-in;
  }

  &-Play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    @include tablet {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &-Content {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 64px;

    @include mobile {
      bottom: 16px;
    }

    @include tablet {
      bottom: 40px;
    }

    @include small-desktop {
      max-width: 896px;
    }

    &-Rectangle {
      height: 15px;
      width: 40px;
      box-shadow: inset 0 4px 0 0 $primary;
    }

    &-Category {
      word-break: break-word;
      color: $white;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 3.86px;
      line-height: 25px;
      padding-top: 8px;

      @include mobile {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2.57px;
        padding-top: 0;
      }
    }

    &-Title {
      word-break: break-word;
      color: $white;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 1.5px;

      @media (min-width: #{$small-desktop}) {
        font-size: 36px;
        letter-spacing: 2px;
        line-height: 49px;
      }
    }
  }

  &-Overlay {
    position: fixed;
    z-index: 101;
    top: 0px;
    left: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);

    &-Details {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 88px;

      @include tablet {
        margin-bottom: 24px;
      }

      @include mobile {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      &-Name {
        word-break: break-word;
        width: 80%;
        color: $white;

        font-weight: bold;
        font-size: 36px;
        letter-spacing: 2px;
        line-height: 49px;
        display: inline-block;
        text-transform: uppercase;

        @include tablet {
          font-size: 24px;
          line-height: 33px;
        }

        @include mobile {
          font-size: 19px;
          line-height: 22px;
        }
      }

      &-Close {
        width: 20%;
        text-align: right;
        cursor: pointer;
        float: right;
        line-height: 49px;

        @include max-small-desktop {
          line-height: 24px;
        }

        &-Button {
          display: inline-block;
          padding-right: 24px;
          cursor: pointer;
          outline: none;

          svg.sprite-icon {
            color: $white;
          }

          @include mobile {
            padding-right: 0;

            svg {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    &-Video {
      video {
        outline: none;
        max-width: 100%;
        margin: 0 auto;
        display: block;
        max-height: 80vh;

        @media (min-width: #{$small-desktop}) {
          max-height: 600px;
        }
      }
    }
  }

  &-MoreVideos {
    &-Title {
      padding: 24px 0;
      color: $black-80;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;

      @media (min-width:#{$small-desktop}) {
        padding: 32px 0 32px;
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 33px;
      }
    }

    &-Content {
      background-color: rgba(216, 216, 216, 0);

      &-Video {
        padding: 32px 24px;
        display: flex;
        align-items: center;
        word-break: break-word;
        box-shadow: 0 -1px 0 0 $black-10;
        color: $black-60;
        font-weight: bold;
        @include text-title-xs;
        text-transform: uppercase;
        cursor: pointer;

        @media (min-width:#{$small-desktop}) {
          font-size: 14px;
          letter-spacing: 3px;
          line-height: 19px;
        }

        &:last-child {
          box-shadow: 0 -1px 0 0 $black-10, inset 0 -1px 0 0 $black-10;
        }

        svg {
          width: 24px;
          height: 24px;
          margin: 0 24px 0 0;
          flex-shrink: 0;
          flex-grow: 0;

          @media (min-width:#{$small-desktop}) {
            margin: 0 32px 0 0;
          }
        }

        &:hover {
          color: $primary;

          svg {
            &.sprite-icon {
              color: $primary-hover;
            }
          }
        }
      }
    }

    &-LoadMore {
      padding-top: 32px;
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $primary-hover;

        svg {
          &.sprite-icon {
            color: $primary-hover;
          }
        }
      }

      svg {
        width: 16px;
        height: 16px;
        margin: 0 8px 3px 0;

        &.sprite-icon {
          color: $primary;
        }
      }

      &-Hide {
        display: none;
      }
    }
  }
}
