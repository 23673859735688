.FacetManager {
  font-size: 16px;
  max-width: 1440px;
  padding: 32px 0 26px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  display: none;

  .Facet {
    display: none;
  }

  @include mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 999;
    padding: 0 0 100px;
  }

  @include min-tablet {
    padding: 0;
    display: block;
  }

  @include desktop {
    background-color: #f9f9f9;
  }
}

@mixin facet-manager-padding {
  @include min-tablet {
    padding: 16px 8px 0 32px;
  }

  @include desktop {
    padding: 16px 106px 0px 130px;
  }
}

.FacetManager--opened {
  @include facet-manager-padding;
  display: block;
}

.FacetManager--loaded {
  &.FacetManager--opened {
    .Facet--visible {
      display: inline-block;
    }
  }
}

.FacetManager--static-on-desktop {
  @include facet-manager-padding;

  @include min-tablet {
    .Facet--visible {
      display: inline-block;
    }
  }
}

.FacetManager__Top {
  position: relative;
  padding: 16px 56px 16px 24px;

  @include min-tablet {
    display: none;
  }
}

.FacetManager__Top-Title {
  color: #333;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 19px;
  text-transform: uppercase;
}

.FacetManager__Top-Reset {
  color: #d40028;
  font-weight: 700;
  @include text-title-xs;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.FacetManager__Top-Close {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 27px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;

  svg {
    width: 18px;
    height: 18px;
  }
}

.FacetManager__Bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  display: flex;
  justify-content: center;
  background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0.3));

  @include min-tablet {
    display: none;
  }
}

.FacetManager__Bottom-Apply {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.57px;
  text-decoration: none;
  padding: 16px 24px;
  display: inline-block;
  min-height: 40px;
  min-width: 133px;
  border-radius: 4px;
  background-color: #d40028;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}

.FacetManager__Bottom-Discard {
  color: #d40028;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.57px;
  text-decoration: none;
  padding: 16px 24px;
  display: inline-block;
  min-height: 40px;
  min-width: 133px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}
