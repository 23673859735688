.ProductDownloads {
  margin: 0 auto;
  padding: 56px 0;

  @include min-small-desktop {
    padding: 112px 0;
  }

  &-Headline {
    margin-bottom: 32px;

    @include min-small-desktop {
      margin-bottom: 40px;
    }
  }

  &-Content {
    background-color: $black-3;
    padding-top: 32px;

    // Match up the padding-bottom to the margin of the wrapper
    padding-bottom: 56px;

    @include min-small-desktop {
      padding-bottom: 112px;
    }
  }
}
