.ImageRightTeaser {
  @include content-padding;
  margin-bottom: 20px;
  &-img {
    max-width: 100%;
    height: 332px;
  }

  &-left {
    height: 332px;
    background-color: $black-3;
    &-wrapper {
      padding-left: 50px;
      padding-right: 59px;
      padding-top: 64px;
    }

    &-headline {
      color: $black-60;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2.57px;
      text-transform: uppercase;
    }

    &-description {
      color: $black-80;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      padding-top: 24px;
      padding-bottom: 24px;
      @include rich-text-a-style;
      @include rich-text-list-style;
    }

    &-button {
      &-link {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.57px;
        text-decoration: none;
        padding: 14px 25px;
        height: 40px;
        width: 133px;
        border-radius: 4px;
        background-color: $primary;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
