.MyLeisterRegistrationPromotion {
  align-items: center !important;
  margin: 0 auto !important;
  @include tablet {
    padding: 24px 0;
  }
  @media (min-width: #{$small-desktop}) {
    padding: 80px 0;
  }
  &-LeftWrapper {
    width: 81.66%;
    padding: 10px 0 10px 24px;
    @include desktop {
      padding-left: 0;
    }
    @include tablet {
      padding: 32px 24px 32px 0;
    }
    @include mobile {
      padding: 56px 24px;
      width: 100%;
    }
  }

  &-Title {
    color: $black-80;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 42px;
    padding-bottom: 8px;
    cursor: default;

    @include tablet {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      padding-bottom: 0;
    }
    @include mobile {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      padding-bottom: 0;
    }
  }
  &-Text {
    color: $black-80;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 41px;
    padding-bottom: 24px;
    cursor: default;
    @include tablet {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      padding-bottom: 16px;
    }
    @include mobile {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      padding-bottom: 16px;
    }
  }
  &-Image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: flex;
  }

  .GeneralLink {
    display: inline-block;
    border-radius: 4px;

    a {
      display: inline-block;
      padding: 12px 16px;
      background-color: $primary;
      color: $white;
      font-weight: bold;
      border-radius: 4px;
      @include text-title-xs;
      text-transform: uppercase;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    }
    &:hover a {
      background-color: $primary-hover;
      color: $white;
    }
  }
}
