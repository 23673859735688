.ProductsLightBox {
  position: fixed;
  z-index: 101;
  top: 0px;
  left: 0px;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: $white;

  model-viewer {
    width: 100%;
    height: 100%;
  }

  &-BlackBackground {
    background-color: $black;

    .ProductsLightBox-ContentWrapper-Headline {
      color: $white;

      svg.sprite-icon {
        color: $white;
      }
    }
  }

  &-ContentWrapper {
    margin: 0 auto;
    height: 100%;
    width: 100%;

    &-Headline {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: $black-80;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 2px;
      padding: 16px 56px 8px 24px;
      cursor: default;

      @media (min-width: #{$small-desktop}) {
        font-size: 36px;
        line-height: 49px;
        padding: 32px 80px 8px 40px;
      }

      &-Close {
        float: right;
        line-height: 0;
        position: absolute;
        top: 16px;
        right: 24px;
        cursor: pointer;
        padding: 8px;
        outline: none;

        svg {
          height: 16px;
          width: 16px;
        }

        &:hover {
          svg.sprite-icon {
            color: $primary;
          }
        }

        @media (min-width: #{$small-desktop}) {
          top: 40px;
          right: 40px;

          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    &-MediaWrapper {
      position: relative;
      width: 100%;
      height: 282px;
      padding-top: 24px;
      padding-bottom: 24px;

      @media (max-width: #{$tablet}) and (orientation: portrait) {
        margin-top: 100px;
      }

      @media (min-width: #{$tablet}) {
        height: calc(100vh - 168px);
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @media (min-width: #{$small-desktop}) {
        height: calc(100vh - 200px);
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @media screen and (max-height: 450px) and (max-width: 900px) {
        height: calc(100vh - 122px);
      }

      &-Zoom {
        text-align: center;
        padding: 24px;
        font-size: 0;

        @media (max-height: 450px) and (max-width: 900px) {
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 3;
          padding: 20px 24px;
        }

        &-In,
        &-Out {
          margin: 0 4px;
          display: inline-block;
          cursor: pointer;

          &:hover {
            svg {
              &.sprite-icon {
                color: $primary;
              }
            }
          }
        }

        .zoom-opacity {
          opacity: 0.3;
          cursor: not-allowed;

          &:hover {
            svg {
              &.sprite-icon {
                color: $black-60;
              }
            }
          }
        }
      }

      &-ArrowLeft,
      &-ArrowRight {
        height: 24px;
        width: 24px;
        cursor: pointer;
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        outline: none;

        svg {
          height: 16px;
          width: 16px;

          @media (min-width: #{$small-desktop}) {
            height: 24px;
            width: 24px;
          }

          &.sprite-icon {
            color: $black-60;
          }
        }

        &:hover {
          svg.sprite-icon {
            color: $primary;
          }
        }
      }

      &-ArrowLeft {
        left: 24px;

        @media (min-width:#{$small-desktop}) {
          left: 40px;
        }
      }

      &-ArrowRight {
        right: 24px;

        @media (min-width:#{$small-desktop}) {
          right: 40px;
        }
      }

      &-Image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center;

        img {
          cursor: move;
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-Video {
        width: 100%;
        height: 100%;
        text-align: center;

        video {
          height: 100%;
          width: auto;
          outline: none;
          max-width: 100%;
        }
      }
    }

    &-Navigation {
      display: flex;
      align-items: center;
      padding: 16px;
      background-color: $white;
      cursor: pointer;
      justify-content: center;
      flex-wrap: wrap;

      @include mobile {
        position: fixed;
        bottom: 0;
        width: 100%;
      }

      @media (min-width:#{$tablet}) {
        justify-content: left;
        flex-wrap: nowrap;
      }

      &-ImageWrapper {
        width: 80px;
        height: 80px;
        margin-right: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.5s ease-in-out;
        opacity: 0.3;

        &.noOpacity {
          opacity: 1;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 32px;
          height: 32px;
        }

        img {
          object-fit: contain;
          width: 100%;
        }
      }

      &-DotWrapper {
        padding: 8px;

        &-Dot {
          box-sizing: border-box;
          height: 18px;
          width: 18px;
          border: 2px solid #676767;
          opacity: 0.97;
          cursor: pointer;
          border-radius: 50%;

          &.active {
            box-sizing: border-box;
            height: 18px;
            width: 18px;
            border: 2px solid $primary;
            opacity: 0.97;
            position: relative;

            &:after {
              content: '';
              width: 6px;
              height: 6px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: $primary;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
