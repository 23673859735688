.FacetsModal {
  display: flex;
  border-bottom: 0;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 1440px;
  padding: 8px 0 8px 24px;
  margin: 0 auto;

  @include desktop {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.FacetsModal__Container {
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;
  position: relative;
  box-shadow: 0 -6px 4px -4px rgba(0, 0, 0, 0.1);
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.FacetsModal__Container--hide-on-desktop {
  @include min-tablet {
    display: none;
  }
}

.FacetsModal__Container--foldable {
  .FacetsModal__Action-Chevron {
    display: block;
  }

  .FacetsModal__Action {
    border-right: 1px solid #e5e5e5;
    padding-right: 16px;
  }
}

.FacetsModal__Action {
  display: flex;
  height: 40px;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-right: 20px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  flex-shrink: 0;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -4px;
    flex-shrink: 0;
  }
}

.FacetsModal__Action-Title {
  color: #d40028;
  font-weight: 700;
  font-size: var(--font-size-section-title-small);
  letter-spacing: var(--letter-spacing-section-title-small);
  line-height: var(--line-height-section-title-small);
  text-transform: uppercase;
  margin: 0 8px;
  flex-shrink: 0;
}

.FacetsModal__Action-Chevron {
  display: none;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  flex-shrink: 0;

  svg {
    width: 8px;
    height: 8px;

    &.sprite-icon {
      color: #d40028;
    }
  }
}
