.SalesAndServiceCentersHolderTwoColumns {
  width: 100%;
  min-height: 180px;
  border-top: 1px solid $black-10;

  &-Left {
    @media (min-width:#{$small-desktop}) {
      display: flex;
      flex-flow: column;
      height: calc(100vh - 150px);
    }
  }

  &-Right {
    @include tablet {
      height: 300px;
    }

    @media (min-width:#{$small-desktop}) {
      height: calc(100vh - 150px);
    }
  }

  &-BackLink {
    display: block;

    a {
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
      text-decoration: none;
      color: $primary;
    }

    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    text-decoration: none;
    color: $primary;
    margin-top: 36px;
    padding-left: 32px;

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }

    @include tablet {
      margin-bottom: 36px;
      padding-left: 24px;
    }

    @include mobile {
      margin-bottom: 36px;
      padding-left: 24px;
    }

    svg {
      margin-right: 16px;
      margin-bottom: -3px;

      @include mobile {
        margin-right: 8px;
      }

      @include tablet {
        margin-right: 8px;
      }
    }
  }

  &-LocationInput {
    position: inherit;
    padding: 32px 40px 32px 32px;

    @include mobile {
      padding: 32px 24px 32px 24px;
    }

    input {
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      border: 1px solid transparent;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 12px;
      outline: none;
      text-overflow: ellipses;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1.5px;

      &::placeholder {
        color: $black-80;
        opacity: 1;
      }

      @include mobile {
        height: 48px;
      }
    }
  }
}
