.SalesAndServicesBorderLine {
  border-top: 1px solid $black-10;
  margin: 0 auto;
  @include tablet {
    margin: 0 64px;
  }
  @media (min-width: #{$small-desktop}) {
    width: 66.66%;
  }
}
