// Carousel css
.ProductsCarousel {
  margin: 0 auto;
  position: relative;

  @media (min-width: #{$small-desktop}) {
    padding: 0 32px;
  }

  &-ArrowLeft,
  &-ArrowRight {
    z-index: 2;
    position: absolute;
    top: 40%;
    outline: none;
    cursor: pointer;

    &.ArrowDisabled {
      opacity: 0.28;
      display: none;

      @media (min-width: #{$small-desktop}) {
        display: block;
      }
    }

    svg {
      width: 16px;
      height: 16px;

      &.sprite-icon {
        color: $black-60;
      }
    }
  }

  &-ArrowLeft {
    left: 0px;
  }

  &-ArrowRight {
    right: 0;
  }

  &-ArrowLeft,
  &-ArrowRight {
    @include mobile {
      display: none;
    }

    @include tablet {
      display: none;
    }
  }

  .alice-carousel__play-btn-wrapper {
    display: none;
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none;
  }

  .alice-carousel__stage-item {
    padding: 10px;
    vertical-align: middle;
  }

  .alice-carousel__dots {
    display: block;

    @media (min-width: #{$small-desktop}) {
      display: none;
    }

    &-item {
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 2px solid #676767;
      opacity: 0.97;
    }

    &-item,
    &-item.__active,
    &-item:hover {
      background: none;
    }

    &-item.__active {
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 2px solid $primary;
      opacity: 0.97;
      position: relative;

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $primary;
        border-radius: 50%;
      }
    }
  }
}
