.DownloadTeaser-Container {
  margin: 0 auto;
  padding: 40px 0;
  width: 83.33%;

  @media (min-width: #{$small-desktop}) {
    width: 66.66%;
  }

  @include mobile {
    width: 100%;
  }

  .DownloadTeaser {
    .DownloadItem:last-of-type {
      box-shadow: 0 -1px 0 0 $black-10, 0 1px 0 0 $black-10;
    }

    &-Headline {
      margin: 0;
      padding: 0 0 32px 0;
      color: $black-80;

      @include tablet {
        line-height: 24px;
      }

      @include mobile {
        padding: 0 24px 32px 24px;
        line-height: 24px;
      }

      &-Title {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 8px;
        font-weight: bold;
        letter-spacing: 2px;

        @media (min-width: #{$small-desktop}) {
          font-size: 36px;
          margin-bottom: 26px;
          margin-top: 0;
        }
      }

      @include rich-text-list-style;
    }

    &-ShowMore {
      margin: 32px auto 0 auto;
      font-weight: bold;
      @include text-section-title-small;
      color: $primary;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      cursor: pointer;

      @include mobile {
        padding: 0 24px;
      }

      svg {
        margin: 0 16px 2px 0;
      }

      &:hover {
        color: $primary-hover;

        svg {
          color: $primary-hover;
        }
      }
    }
  }
}
