#map {
  // Map InfoWindow wrapper
  .gm-style-iw-c {
    padding: 0;
    border-radius: 4px;

    @media (min-width:#{$small-desktop}) {
      //overwrite inline style overflow hidden
      overflow: unset !important;
      max-height: 428px !important;
    }

    .gm-ui-hover-effect {
      top: 0px !important;
      right: 0px !important;

      span,
      img {
        margin: 0 !important;
        width: 20px !important;
        height: 20px !important;
      }
    }

    button.gm-ui-hover-effect {
      padding-top: 12px !important;
    }

    .gm-style-iw-d {
      overflow: auto !important;

      @media (min-width:#{$small-desktop}) {
        overflow: unset !important;
        max-height: 400px !important;
      }
    }
  }

  // Map InfoWindow content
  .ContactPointInfo {
    padding: 30px 24px 18px;

    &:hover {
      background-color: $white;
    }

    &:first-child {
      .ContactPointInfo-Title-Tooltip {
        bottom: 120%;

        &::after {
          top: 100%;
          border-color: $black transparent transparent transparent;
        }
      }
    }

    &-Title {
      font-size: 14px;
      line-height: 19px;
    }

    &-DetailsLink {
      margin-right: 8px;
    }

    .ContactPointInfo-MessageSavedContact {
      display: none;
    }

    @media (max-width:#{$small-desktop}) {
      .ContactPointInfo-Title-StarContainerWithClick.Active .ContactPointInfo-Title-Tooltip {
        visibility: hidden;
        display: none;
      }
    }
  }
}
