.RichTextWithCheckmarks {
  min-height: 50px;
  padding-bottom: 40px;
  color: $black-80;
  &.WhiteColorStyle {
    color: $white;
    padding-top: 32px;
  }
  &-Container {
    width: 66.66%;
    margin: 0 auto;
    cursor: default;
    @include tablet {
      width: 100%;
      padding: 0 40px;
    }
    @include mobile {
      width: 100%;
    }
  }
}
