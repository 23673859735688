.HeroStory {
  margin: 0 auto;
  width: 83.33%;
  padding-top: 120px;
  margin-bottom: 40px;

  @include tablet {
    width: 100%;
    padding-top: 56px;
  }

  @include mobile {
    width: 100%;
    position: static;
    padding-top: 40px;
  }

  &-Title {
    color: $black-80;
    width: 80%;
    margin: 24px auto;
    cursor: default;

    @include tablet {
      width: 100%;
      padding: 0 64px;
      margin: 32px auto;
    }

    @include mobile {
      width: 100%;
      margin: 16px auto 32px auto;
      padding: 0 24px;
    }
  }

  &-Button {
    width: 80%;
    margin: 0 auto;

    a {
      text-transform: uppercase;
      color: $primary;
      font-weight: bold;
      letter-spacing: 3px;
      display: block;
      color: $primary;
      font-size: 14px;
    }

    svg {
      margin-bottom: -3px;
      margin-right: 8px;
    }

    @include tablet {
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      padding: 0 24px;
    }

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }
  }

  &-Image {
    width: 100%;
    height: auto;
    display: block;
  }

  &-CategoryWrapper {
    width: 80%;
    margin: 32px auto;
    @include text-section-title-small;

    @include tablet {
      width: 100%;
      padding: 0 64px;
      margin-top: 0;
    }

    @include mobile {
      width: 100%;
      line-height: 16px;
      font-size: 12px;
      padding: 0 24px;
    }
  }

  &-Category {
    font-weight: bold;
    color: $black-60;
    text-transform: uppercase;
    cursor: default;

    @include mobile {
      letter-spacing: 2.57px;
    }
  }

  &-Date {
    font-weight: 400;
    color: $black-80;
    padding-left: 16px;
    border-left: 1px solid $black-80;
    margin-left: 16px;
    cursor: default;
    text-transform: capitalize;

    @include mobile {
      letter-spacing: 1.5px;
    }
  }

  &-LeadText {
    width: 80%;
    margin: 0 auto;
    color: $black-60;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    cursor: default;
    @include rich-text-a-style;
    @include rich-text-list-style;

    @include tablet {
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      font-size: 22px;
      line-height: 30px;
      padding: 0 24px;
    }
  }

  &-SocialLinkWrapper {
    width: 32px;
    float: right;
    margin-top: 40px;
    margin-bottom: 24px;

    @include tablet {
      float: none;
      width: auto;
      text-align: end;
      margin-bottom: 0;
      padding-right: 24px;
      margin-top: 24px;
    }

    @include mobile {
      float: none;
      width: auto;
      margin: 32px auto 0 auto;
      text-align: center;
    }
  }

  &-EmailShare {
    height: 32px;
    width: 32px;
    display: inline-block;
    margin-top: 4px;

    svg {
      width: 32px;
      height: 32px;
    }

    @include tablet {
      margin: 0 4px;
    }

    @include mobile {
      margin: 0 8px;
    }

    &:hover {
      svg.sprite-icon {
        color: $black-60;
      }
    }
  }
}

.GeneralLink.HeroStory-Button a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}
