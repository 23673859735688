.Menu-header {
  .MenuInfo {
    padding-right: 32px;
    display: flex;
    align-items: center;
    height: 100%;
    @include small-desktop {
      padding-right: 24px;
    }
    &-item {
      &:last-child {
        height: 100%;
      }
      color: $black-60;
      margin-left: 32px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1px;
      cursor: pointer;
      display: inline;
      text-transform: uppercase;
      @include small-desktop {
        margin-left: 24px;
      }
    }
  }
}
