@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';

.AddToCartTeaser {
  --image-width: 80px;

  background-color: #fafafa;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include min-small-desktop {
    --image-width: 140px;

    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    gap: 24px;
    min-height: 140px;
  }

  + .AddToCartTeaser {
    margin-top: 16px;
  }
}

.AddToCartTeaser__price-and-add-to-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @include min-small-desktop {
    flex-direction: column;
    align-items: flex-end;
    flex: 1 1 auto;
  }
}

.AddToCartTeaser__image-and-product-info {
  display: flex;
  gap: 16px;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include min-small-desktop {
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: start;
  }
}

.AddToCartTeaser--no-image {
  .AddToCartTeaser__image-and-product-info {
    flex-direction: row;
  }

  @include min-small-desktop {
    .AddToCartTeaser__price-and-add-to-cart {
      flex: 1 1 50%;
    }

    .AddToCartTeaser__image-and-product-info {
      flex: 1 1 50%;
    }
  }
}

.AddToCartTeaser--no-variant {
  display: block;
  min-height: auto;
}

.AddToCartTeaser__image {
  width: var(--image-width);
  height: var(--image-width);
  padding: 8px;
  background-color: $white;
  display: flex;
  justify-self: end;
  flex-shrink: 0;

  img {
    width: 100%;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
  }

  @include min-small-desktop {
    padding: 16px;
  }
}

.AddToCartTeaser__price {
  display: flex;
  justify-content: space-between;

  @include min-small-desktop {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.AddToCartTeaser__AddToCart {
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
}

.AddToCartTeaser__price-details {
  display: flex;
  align-items: flex-end;
}

.AddToCartTeaser__product-info {
  display: flex;
  flex-direction: column;
}

.AddToCartTeaser__tags {
  margin-top: 16px;
  display: flex;
  gap: 8px;
}

.AddToCartTeaser__article-nr-label {
  color: #676767;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.AddToCartTeaser__article-nr {
  display: flex;
  gap: 8px;
  align-items: center;
}

.AddToCartTeaser__article-nr-text {
  color: $black;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  @include min-small-desktop {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.015em;
  }
}

.AddToCartTeaser__find-a-dealer {
  justify-content: space-between;
  flex: 1 1 auto;

  @include min-small-desktop {
    display: flex;
  }

  .FindADealer {
    text-align: left;
  }
}
