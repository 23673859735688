@mixin showFacetSticky {
  position: sticky;
  top: 70px;
  z-index: 2;
}

// Put a shadow on the bottom, to better see the FacetManager ontop of the content
@mixin boxShadow {
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
}

.FacetStickyWrapper {
  // Put some space after the wrapper (especially useful when there are no BeanTabs)
  margin-bottom: 30px;

  @include boxShadow;

  // On tablet the top navigation might show up
  // Therefor we only show the facet sticky when scrolling down and place it after the top nav.
  @include tablet {
    &.isStickyForTablet {
      @include showFacetSticky;

      // The FacetManager needs some layout extraction (absolute positioning) to not mess up the useScrollDirection() logic (rerendering all the times)
      .FacetManager--opened {
        position: absolute;
        width: 100%;
        @include boxShadow;
      }
    }
  }

  // On desktop we always show the facet sticky after the top-navigation
  @include min-small-desktop {
    @include showFacetSticky;
  }
}
