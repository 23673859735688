.SearchResults__Main {
  background-color: #f9f9f9;
  scroll-margin-top: 70px; // Perfectly aligns this element to the top (because of the top-header)
}

.SearchResults__Filters {
  display: none;
}

.SearchResults__Filters--visible {
  display: block;
}

.SearchResults__Header {
  max-width: 1440px;
  padding: 24px 24px 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  overflow: hidden;
  align-items: center;

  @include min-small-desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktop {
    padding-top: 10px;
    padding-left: 130px;
    padding-right: 130px;
  }
}

.SearchResults__NumberOfResults {
  font-size: 12px;
  letter-spacing: 2.57px;
  line-height: 16px;
  font-weight: 700;
  color: #676767;
  text-transform: uppercase;

  @include min-tablet {
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 19px;
  }
}
