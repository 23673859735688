.ProductHeroMainImage {
  margin-bottom: 24px;
  height: 480px;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
  }
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
