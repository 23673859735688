.Table {
  font-family: var(--font-family-base);
  border-collapse: collapse;
  border: 0;
  position: relative;
  border-collapse: collapse;
  margin: 0;

  th,
  td {
    background-color: $white;
  }

  tr {
    border: 0;

    &:hover {
      td {
        background-color: $black-4;
        cursor: pointer;
      }
    }
  }

  tr th {
    font-family: var(--font-family-base);
    font-size: 12px;
    font-weight: 600;
    line-height: 16.39px;
    letter-spacing: 0.14em;
    text-align: left;
    border: 0;
    border-bottom: 1px solid $black-10;
    padding: 20px 16px;
  }

  tr td {
    border: 0;
    border-bottom: 1px solid $black-10;
    padding: 20px 16px;
  }
}

.TextCell {
  font-family: var(--font-family-base);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: $black-60;
}

.BoldTextCell {
  font-family: var(--font-family-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-align: left;
  color: $black;
}

.AvailabilityCell {
  display: flex;
  gap: 16px;
  align-items: center;
}

.PriceCell {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-60;
}

.PriceCell .PriceCell__CompareAtPrice.stroken-through {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.PriceCell__Price--discount {
  color: $primary;
}

.MoneyCell {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-60;
}

.ImageWithTextCell {
  display: flex;
  gap: 16px;
  align-items: center;
}

.ImageWithTextCell img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.ImageWithTextCell span {
  @extend .TextCell;
  @extend .TextCell--left;
}

.Table__Row--highlighted {
  td {
    background-color: $black-4;

    &:first-child {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: $primary;
      }
    }
  }
}

.Table thead th {
  position: sticky;
  top: 0;
  background: $white;
  z-index: 1;
}

.Table_header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $black;
}

.Table__sorting {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.Table__sort {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;

  svg {
    color: $black-30;
  }

  &:hover {
    svg {
      color: $black-80;
    }
  }
}

.Table__sort--active {
  svg {
    color: $black-80;
  }
}

.Table--sticky-first {
  th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
  }

  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
}

.Table--sticky-last {
  th:last-child {
    position: sticky;
    right: 0;
    top: 0;
    z-index: 2;
  }

  td:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
  }
}

.Table_header--large {
  min-width: 200px;
}

.Table_header--no-break {
  white-space: nowrap;
}

.Table_header--right {
  justify-content: flex-end;
}

.Table_header--center {
  justify-content: center;
}

.TextCell--no-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PriceCell--left {
  justify-content: flex-start;
}

.PriceCell--right {
  justify-content: flex-end;
}

.PriceCell--center {
  justify-content: center;
}

.MoneyCell--left {
  justify-content: flex-start;
}

.MoneyCell--right {
  justify-content: flex-end;
}

.MoneyCell--center {
  justify-content: center;
}

.TextCell--left,
.BoldTextCell--left {
  text-align: left;
}

.TextCell--right,
.BoldTextCell--right {
  text-align: right;
}

.TextCell--center,
.BoldTextCell--center {
  text-align: center;
}

.TextCell--primary,
.BoldTextCell--primary {
  color: $primary;
}