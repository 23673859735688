.CompareUSPItem {
  width: 100%;
  @include rich-text-list-style;
  padding: 24px 24px 16px 24px;
  color: $black-80;
  box-shadow: inset 0 -1px 0 0 $black-10;
  &:last-child {
    box-shadow: unset;
  }
  @media (min-width: #{$small-desktop}) {
    width: 20%;
    box-shadow: unset;
    padding: 0 8px 0 0;
  }
  ul {
    @media (min-width: #{$small-desktop}) {
      padding-right: 24px;
    }
  }
  &-Title {
    display: inline-block;
    padding-bottom: 12px;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 25px;
    text-transform: uppercase;

    @media (min-width: #{$small-desktop}) {
      display: none;
    }
  }
}
