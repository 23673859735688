.WishlistComponent {
  &-Container {
    background-color: $black-3;
    margin-top: 24px;
    padding: 8px 16px;

    a {
      display: inline-block;
    }
    @media (min-width: #{$tablet}) {
      padding: 16px 24px;
      margin-top: 32px;
    }
    &-LogIn {
      display: flex;
      justify-content: space-between;
    }
  }
  &-Name {
    color: $black-80;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    @media (min-width: #{$tablet}) {
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
    }
  }
  &-Details {
    color: $black-60;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-top: 8px;
    padding-right: 8px;
    @media (min-width: #{$tablet}) {
      padding-top: 0;
    }
  }
  &-Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black-60;
    font-weight: bold;
    @include text-title-xs;
    text-transform: uppercase;
    @media (min-width: #{$tablet}) {
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 19px;
    }
    &-NumberWishlist {
      display: inline-block;
    }
  }

  .OverlayDialog {
    @media (max-width: 767px) {
      background-color: $white;
    }
    &-DetailsContainer {
      @media (max-width: 767px) {
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .SpacingWithoutAddWishlistButtons {
    margin-bottom: 24px;
  }
}
