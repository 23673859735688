.SolutionCardsGallery {
  margin: 0 auto;
  padding: 48px 0;
  overflow: hidden;
  @media (min-width: #{$small-desktop}) {
    padding: 80px 0;
  }
  &-Headline {
    padding: 0 12px 30px 12px;
    @media (min-width: #{$small-desktop}) {
      padding: 0 12px 56px 12px;
    }
  }
  &-Desktop {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    &-FirstCol,
    &-SecondCol,
    &-ThirdCol {
      flex: 33.33%;
    }
    &-SecondCol {
      padding-top: 60px;
      @include desktop {
        padding-top: 80px;
      }
    }
    &-ThirdCol {
      padding-top: 120px;
      @include desktop {
        padding-top: 160px;
      }
    }
  }

  &-Mobile {
    overflow: hidden;
    &-TabletContainer {
      margin-right: 12px;
      display: flex;
      .SolutionCard {
        width: 50%;
      }
    }

    &-MobileContainer {
      margin-right: 12px;
    }
  }
}
