@font-face {
  font-family: 'Avenir Next World';
  font-weight: 700;
  font-display: swap;
  src: url('Fonts/AvenirNextWorld/AvenirNextWorldBold_normal_normal_subset1.woff2') format('woff2'),
    url('Fonts/AvenirNextWorld/AvenirNextWorldBold_normal_normal_subset1.woff') format('woff');
  unicode-range: U+0020-007E, U+00A1-017E, U+01E6-01E7, U+0440, U+2013, U+2018-201A, U+201C-201E, U+2020-2022, U+2026,
    U+2032-2033, U+2039-203A, U+20A1, U+20A4, U+20A6-20A7, U+20A9-20AC, U+20B4-20B5, U+20B8-20BA, U+2122;
}
@font-face {
  font-family: 'Avenir Next World';
  font-weight: 700;
  font-display: swap;
  src: url('Fonts/AvenirNextWorldSubSup/AvenirNextWorldBold_normal_normal_subset1.woff2') format('woff2'),
    url('Fonts/AvenirNextWorldSubSup/AvenirNextWorldBold_normal_normal_subset1.woff') format('woff');
  unicode-range: U+2070, U+2074-208e, U+2099;
}

@font-face {
  font-family: 'Avenir Next World';
  font-weight: 400;
  font-display: swap;
  src: url('Fonts/AvenirNextWorld/AvenirNextWorldRegular_normal_normal_subset1.woff2') format('woff2'),
    url('Fonts/AvenirNextWorld/AvenirNextWorldRegular_normal_normal_subset1.woff') format('woff');
  unicode-range: U+0020-007E, U+00A1-017E, U+01E6-01E7, U+0440, U+2013, U+2018-201A, U+201C-201E, U+2020-2022, U+2026,
    U+2032-2033, U+2039-203A, U+20A1, U+20A4, U+20A6-20A7, U+20A9-20AC, U+20B4-20B5, U+20B8-20BA, U+2122;
}
@font-face {
  font-family: 'Avenir Next World';
  font-weight: 400;
  font-display: swap;
  src: url('Fonts/AvenirNextWorldSubSup/AvenirNextWorldRegular_normal_normal_subset1.woff2') format('woff2'),
    url('Fonts/AvenirNextWorldSubSup/AvenirNextWorldRegular_normal_normal_subset1.woff') format('woff');
  unicode-range: U+2070, U+2074-208e, U+2099;
}

@font-face {
  font-family: 'Avenir Next World';
  font-weight: 600;
  font-display: swap;
  src: url('Fonts/AvenirNextWorld/AvenirNextWorldDemi_normal_normal_subset1.woff2') format('woff2'),
    url('Fonts/AvenirNextWorld/AvenirNextWorldDemi_normal_normal_subset1.woff') format('woff');
  unicode-range: U+0020-007E, U+00A1-017E, U+01E6-01E7, U+0440, U+2013, U+2018-201A, U+201C-201E, U+2020-2022, U+2026,
    U+2032-2033, U+2039-203A, U+20A1, U+20A4, U+20A6-20A7, U+20A9-20AC, U+20B4-20B5, U+20B8-20BA, U+2122;
}
@font-face {
  font-family: 'Avenir Next World';
  font-weight: 600;
  font-display: swap;
  src: url('Fonts/AvenirNextWorldSubSup/AvenirNextWorldDemi_normal_normal_subset1.woff2') format('woff2'),
    url('Fonts/AvenirNextWorldSubSup/AvenirNextWorldDemi_normal_normal_subset1.woff') format('woff');
  unicode-range: U+2070, U+2074-208e, U+2099;
}

@font-face {
  font-family: 'Avenir Next World Cyrylic';
  font-weight: 400;
  font-display: swap;
  src: url('Fonts/AvenirNextWorldCyrylic/AvenirNextWorldRegular_normal_normal.woff2') format('woff2'),
    url('Fonts/AvenirNextWorldCyrylic/AvenirNextWorldRegular_normal_normal.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next World Cyrylic';
  font-weight: 700;
  font-display: swap;
  src: url('Fonts/AvenirNextWorldCyrylic/AvenirNextWorldBold_normal_normal.woff2') format('woff2'),
    url('Fonts/AvenirNextWorldCyrylic/AvenirNextWorldBold_normal_normal.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 400;
  font-display: swap;
  src: url('Fonts/NotoSansJP/Noto-Sans-JP-Regular.woff2') format('woff2'),
    url('Fonts/NotoSansJP/Noto-Sans-JP-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 700;
  font-display: swap;
  src: url('Fonts/NotoSansJP/Noto-Sans-JP-700.woff2') format('woff2'),
    url('Fonts/NotoSansJP/Noto-Sans-JP-700.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 400;
  font-display: swap;
  src: url('Fonts/NotoSansSC/Noto-Sans-SC-Regular.woff2') format('woff2'),
    url('Fonts/NotoSansSC/Noto-Sans-SC-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 700;
  font-display: swap;
  src: url('Fonts/NotoSansSC/Noto-Sans-SC-700.woff2') format('woff2'),
    url('Fonts/NotoSansSC/Noto-Sans-SC-700.woff') format('woff');
}
