.PageLinkerList {
  padding: 48px 0;
  @media (min-width: #{$small-desktop}) {
    padding-top: 104px;
    padding-bottom: 120px;
  }
  .componentContainer {
    padding: 0 !important;
  }
  &-Headline {
    margin-bottom: 16px;
    color: $black-80;
  }
  &-Placeholder {
    padding: 20px 0;
    @media (min-width: #{$small-desktop}) {
      padding: 24px 0 40px;
    }
  }
}
