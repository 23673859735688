.ShareEmailLink {
  margin: 4px 0;
  display: inline-block;
  width: 32px;
  height: 32px;

  @include tablet {
    margin: 0 4px;
  }

  @include mobile {
    margin: 0 8px;
  }

  &-Link {
    width: 32px;
    height: 32px;
    display: inline-block;
  }
}
