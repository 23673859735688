.MyAccountOrderList__Container {
  padding-bottom: 120px;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.MyAccountOrderList__NoOrders {
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    margin: 0;
  }
}

.MyAccountOrderList__AlertBoxWrapperMobile {
  padding: 48px 20px 24px 20px;
}

.MyAccountOrderList__NoOrdersWrapperMobile {
  padding: 48px 20px;
}

.MyAccountOrderList__Loading {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @include max-small-desktop {
    margin-top: 40px;
  }
}
