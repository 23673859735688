.SuccessMessage {
  border-radius: 4px;
  display: flex;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  max-width: 720px;
  margin-bottom: 40px;
  &-Rectangle {
    width: 4px;
    border-radius: 4px 0 0 4px;
    background-color: $alert-ok;
  }
  &-Content {
    display: flex;
    padding: 8px 16px 8px 20px;
    align-items: center;
  }
  &-Title {
    display: block;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    color: $black-60;
  }
  &-Text {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    color: $black-60;
  }
  &-TextWrapper {
    padding-left: 16px;
  }
}
