.FilterToggle {
  box-sizing: border-box;
  height: 32px;
  // width: 81px;
  border: 1px solid $primary-10;
  border-radius: 4px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;

  &:hover {
    background-color: $primary-10;
  }

  &-text {
    height: 16px;
    // width: 52px;
    color: $black-80;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 16px;
    // TODO: needs to be changed.
    padding-left: 16px;
    padding-right: 25.1px;
  }
}

.FilterActive {
  border: 1px solid $primary;
  background-color: $white;
}

// TODO: check with cristi colors and implementation
.FilterDisabled {
  background-color: $white;
  border: 1px solid $black-10;
  cursor: default;
  &:hover {
    background-color: $white;
  }

  .FilterToggle-text {
    color: $black-30;
  }
}
