.LegalText {
  margin: 0;
  font-size: 12px;
}

// Stock information
.DeliveryTime,
.StockInformation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  letter-spacing: 0.84px;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

.StockInformation__Label,
.DeliveryTime__Label {
  font-weight: 700;
}

.DeliveryTime--Short,
.StockInformation--InStock {
  color: $alert-ok;
}

.StockInformation__LimitedStockWarning {
  color: $black-80;
}

.StockInformation--NotInStock {
  color: $black-30;
}

.DeliveryTime--Middle,
.StockInformation--CurrentlyNotInStock {
  color: $orange;
}

.DeliveryTime--Long {
  color: $alert-error;
}
