.PromotionBox {
  margin-bottom: 64px;

  @include mobile {
    margin-bottom: 0;
  }

  @include tablet {
    margin-bottom: 40px;
  }

  &-Container {
    width: 83.33%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 320px;
    height: auto;

    @include tablet {
      width: 100%;
      min-height: 240px;
    }

    @include mobile {
      width: 100%;
      min-height: 164px;
    }
  }

  &-Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  &-Wrapper {
    position: relative;
    height: 100%;
    padding-block: 56px;
    padding-left: 100px;
    padding-right: 50px;
    cursor: default;

    @include tablet {
      padding-bottom: 40px;
      padding-inline: 40px;
    }

    @include mobile {
      padding-bottom: 17px;
      padding-inline: 24px;
    }
  }

  &-Title {
    color: $white;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 49px;
    text-transform: uppercase;
    margin: 0;

    @include tablet {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
    }

    @include mobile {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 19px;
    }
  }

  &-SubTitle {
    color: $white;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0.7px;
    line-height: 49px;

    @include tablet {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      font-weight: bold;
    }

    @include mobile {
      font-size: 14px;
      letter-spacing: 0.17px;
      line-height: 19px;
      font-weight: 500;
    }
  }

  .GeneralLink {
    margin-top: 28px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;

    @include tablet {
      margin-top: 16px;
    }

    @include mobile {
      margin-top: 16px;
    }

    a {
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      padding: 12px 12px 12px 16px;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include text-title-xs;
      display: block;
      background-color: $primary;

      @include mobile {
        text-transform: none;
      }
    }

    &:hover a {
      background-color: $primary-hover;
    }
  }
}
