.Facet {
  position: relative;
  vertical-align: top;
  width: 100%;
  display: none;

  @include min-tablet {
    width: 33.33%;
    padding-bottom: 16px;
    display: inline-block;
    padding-right: 20px;
  }

  @include desktop {
    width: 25%;
  }
}

.Facet--visible {
  display: inline-block;
}

.Facet__Header {
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0;
  background-color: #fff;
  padding: 19px 40px 18px 24px;
  box-shadow: 1px 0 0 0 #e5e5e5, 0 1px 0 0 #e5e5e5, 1px 1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5,
    inset 0 1px 0 0 #e5e5e5;
  background-position: right 16px center;
  color: #333;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  background-image: url(./down.svg);

  @include min-tablet {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 12px 40px 12px 24px;
  }
}

.Facet__Header-Title {
  text-transform: uppercase;
}

.Facet__Header-Breadcrumbs {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  font-family: var(--font-family-base);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
}

.Facet__Values {
  background: white;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  vertical-align: top !important;
  border: none;
  display: none;
}

.Facet__Values-Header {
  overflow: hidden;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: none;
  padding: 10px 20px;
  background-color: #f9f9f9;
}

.Facet__Values-Header-Title-Section {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
}

.Facet__Values-Header-Title {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 20px;
  text-transform: uppercase;
}

.Facet__List {
  width: 100%;
  background-color: #fff;
  box-shadow: inset 4px 0 0 0 $primary, inset 0 -1px 0 0 $black-10;

  @include min-tablet {
    box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0 0 4px 4px;
    position: absolute;
    z-index: 999;
  }
}

.Facet__Values-List {
  list-style: none;
  margin: 0;
  padding: 24px;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: $black-30 $white;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $black-30;
    border-radius: 3px;
  }

  @include min-tablet {
    max-height: 200px;
    overflow: auto;
    padding: 0 16px 16px;
  }
}

.Facet__Values-List-Item {
  padding: 0 20px;
  position: relative;
  border-radius: 5px;
  border: 2px solid $black-10;
  color: #333;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin: 8px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include min-tablet {
    padding: 0 10px;
  }
}

.Facet__Values-List-Item-Results-Count {
  position: absolute;
  right: 5px;
  top: 9px;
  font-size: 10px;

  @include min-tablet {
    position: static;
    margin-left: 4px;
  }
}

.Facet__Values-List-Item--selected {
  border-color: #b40028;
}

.Facet__Values-List-Item-Label {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  display: block;

  [type='checkbox'] {
    display: none;
  }

  @include min-tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.Facet__Values-List-Item-Caption {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  color: #333;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding: 8px 0;
}

.Facet__Values-Filter {
  padding: 24px;
  margin-bottom: 8px;
  border-bottom: 1px solid $black-4;
  position: relative;

  @include min-tablet {
    padding: 8px 16px 16px 16px;
  }
}

.Facet__Filter-Icon {
  color: $black-30;
  position: absolute;
  right: 32px;
  top: 34px;
  width: 16px;
  height: 16px;

  @include min-tablet {
    right: 24px;
    top: 20px;
  }
}

.Facet__Filter-Clear {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 30px;
  color: $black-30;
  width: 24px;
  display: flex;

  @include min-tablet {
    right: 24px;
    top: 14px;
  }
}

.Facet__Filter-Input {
  border-radius: 4px;
  background: $white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 8px 40px 8px 16px;
  border: none;
  font-size: 16px;

  @include min-tablet {
    font-size: 14px;
  }
}

.Facet--opened {
  .Facet__Header {
    background-image: url(./up.svg);
    border-radius: 4px 4px 0 0;

    @include min-tablet {
      box-shadow: none;
      z-index: 11;
      position: relative;
    }
  }

  .Facet__Values {
    display: block;
  }

  .Facet__List {
    @include min-tablet {
      border-radius: 4px;
      background: $white;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
      padding-top: 40px;
      z-index: 10;
      transform: translateY(-40px);
    }
  }
}

.Facet--has-value {
  .Facet__Header {
    padding-top: 12px;
    padding-bottom: 11px;
    color: #333;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1.43px;
    line-height: 14px;

    @include min-tablet {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
