.InlineConfirmationDialog {
  background-color: $black-3;
  border-left: 2px solid $primary;
  padding: 16px;
  color: $black-80;
  font-weight: 700;
  line-height: 1.2;
  width: 100%;
}

.InlineConfirmationDialog__Message > p {
  margin-top: 0;
}

.InlineConfirmationDialog__Actions {
  display: flex;
  justify-content: space-between;
  gap: 50px;

  .DeleteItemButton--cancel {
    text-align: left;
  }

  .DeleteItemButton--confirm {
    text-align: right;
  }

  svg {
    flex-shrink: 0;
  }
}
