.CompareApplication {
  &-Row {
    @media (min-width:#{$small-desktop}) {
      display: flex;
      width: 100%;
      color: $black-60;
      font-weight: 400;
      box-shadow: inset 0 -1px 0 0 $black-10;
      background-color: $white;
      font-size: 14px;

      &:hover {
        background-color: $black-3;
      }

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }
  }

  &-Item {
    @media (min-width:#{$small-desktop}) {
      width: 20%;

      &-Image {
        height: 40px;
        width: 40px;
        background-color: $black-4;
        display: inline-block;
        flex-shrink: 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      &-Title {
        padding-left: 16px;
      }

      &.CompareApplication-Name {
        display: flex;
        padding: 8px 0 8px 32px;
        letter-spacing: 0px;
        align-items: center;
      }

      &.CompareApplication-Value {
        padding-left: 20px;
        align-self: center;
      }
    }
  }

  &-Column {
    padding: 24px;
    box-shadow: inset 0 -1px 0 0 $black-10;

    &-Title {
      color: $black-80;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
      text-transform: uppercase;
    }

    &-Value {
      padding-top: 16px;
      display: flex;
      align-items: center;

      &-Name {
        padding-left: 16px;
        color: $black-60;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
      }
    }
  }

  &-MoreButton {
    color: $primary;
    font-weight: bold;
    text-transform: uppercase;
    @include text-section-title-small;
    display: inline-block;
    padding-top: 24px;
    cursor: pointer;

    svg {
      margin-right: 8px;
      margin-bottom: -3px;
    }

    @media (min-width:#{$small-desktop}) {
      padding: 16px 0 16px 32px;

      &:hover {
        color: $primary-hover;

        svg.sprite-icon {
          color: $primary-hover;
        }
      }
    }
  }
}
