.shopify-price {
  &.big {
    color: var(--shades-black-80, #333);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @include desktop {
      font-size: 36px;
      line-height: 48px;
    }
  }

  &.stroken-through {
    color: var(--shades-black-60, #676767);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
  }

  &.current-price {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;

    // TODO: or min-small-desktop?
    @include desktop {
      font-size: 18px;
      letter-spacing: 0.015em;
      text-align: left;
    }
  }

  &.old-price {
    color: $black-60;
    text-decoration: line-through;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

    // There are no desktop style changes
  }

  &.bundle-price {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;

    @include desktop {
      font-size: 18px;
      letter-spacing: 0.015em;
    }
  }

  &.sell {
    color: $primary;
  }
}
