.SearchResultList {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 24px;

  @include min-small-desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktop {
    padding-top: 40px;
    padding-left: 130px;
    padding-right: 130px;
  }
}

.SearchResultList--downloads {
  .SearchResultList__Layout.ListLayout {
    .SearchResultList__Item {
      padding-top: 8px;

      &:empty {
        padding-top: 0;
      }
    }
  }
}

.SearchResultList__Layout {
  &.CardLayout {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-right: -20px;
    position: relative;

    .SearchResultList__Item {
      display: flex;
      position: relative;
      font-size: 0.9em;
      width: 300px;
      flex-grow: 1;
      margin: 0 20px 20px 0;
      border-radius: 2px;

      &:empty {
        width: 300px;
        height: 0;
        flex-grow: 1;
        margin-right: 20px;
      }
    }
  }

  &.ListLayout {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-bottom: 32px;

    .SearchResultList__Item {
      border-bottom: none;
      padding: 24px 0 0;
    }
  }
}
