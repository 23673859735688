.ContactPointInfo {
  padding: 24px 32px 32px 32px;
  box-shadow: 0 -1px 0 0 $black-10;
  cursor: default;

  @include mobile {
    padding: 24px 24px 24px 24px;
  }

  &:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
    background-color: $primary-4;
  }

  &:first-child {
    .ContactPointInfo-Title-Tooltip {
      bottom: -112px;

      &::after {
        bottom: 100%;
        top: auto;
        border-color: transparent transparent $black transparent;
      }
    }
  }

  &-Category {
    color: $black-80;
    font-size: 10px;
    letter-spacing: 2.14px;
    line-height: 14px;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 8px;
  }

  &-Title {
    color: $black-80;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 32px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 4px;

    @include mobile {
      padding-bottom: 12px;
    }

    &-Tooltip {
      @include tooltip-product;
      width: 240px;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      white-space: normal;
      opacity: 0.8;
      font-weight: 400;
      padding: 12px 12px 12px 16px;
      left: -72px;

      &::after {
        left: 206px;
      }
    }

    &-Star,
    &-StarFilled {
      display: inline-block;
      cursor: pointer;
    }

    &-StarContainer {
      position: relative;
      margin-left: 12px;
      display: inline-block;

      @media (min-width:#{$small-desktop}) {
        &:hover {
          .ContactPointInfo-Title-Tooltip {
            visibility: visible;
          }
        }
      }
    }

    &-StarContainerWithClick {
      @media (max-width:#{$small-desktop}) {
        .ContactPointInfo-Title-Tooltip {
          visibility: hidden;
        }

        &.Active {
          .ContactPointInfo-Title-Tooltip {
            visibility: visible;
          }
        }
      }
    }

    &-Star {
      svg {
        margin-bottom: -6px;
      }
    }

    &-StarFilled {
      svg {
        margin-bottom: -4px;
        margin-left: 2px;
      }
    }
  }

  &-MessageSavedContact {
    color: $black-60;
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 16px;
    background-color: $alert-info-10;
    padding: 4px 8px 4px 12px;
    margin-top: 8px;

    @media (min-width:#{$small-desktop}) {
      font-size: 14px;
      letter-spacing: 0.35px;
      line-height: 22px;
      margin-top: 12px;
      padding: 12px 12px 12px 16px;
    }
  }

  &-Address {
    color: $black-60;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &-Number {
    a {
      color: $black-60;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 26px;
      display: inline-block;
    }

    &:first-child {
      margin-top: 16px;
    }

    svg {
      margin-right: 8px;
      margin-bottom: -2px;
    }

    @include mobile {
      a {
        color: $primary;
      }
    }

    &:hover {
      a,
      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }

  &-Link {
    a {
      color: $primary;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 26px;
      display: inline-block;
      word-break: break-all;
    }

    svg {
      margin-right: 8px;
      margin-bottom: -3px;
    }

    &:hover {
      a,
      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }

  &-DetailsLink {
    display: inline-block;

    a {
      color: $primary;
      text-transform: uppercase;
      font-weight: bold;
      @include text-section-title-small;
      display: inline-block;
    }

    svg {
      margin-left: 8px;
      margin-bottom: -3px;
    }

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }
  }

  &-Provider {
    color: $primary;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: right;
    display: inline-block;
  }

  &-BottomLinks {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 16px;

    @include mobile {
      display: block;
    }

    &.WithoutDetailsLink {
      justify-content: flex-end;
    }
  }

  &-ContactLink {
    display: inline-block;
    margin-right: 0;

    a {
      display: inline-block;
      padding: 12px 12px 12px 16px;
      color: $white;
      font-weight: bold;
      @include text-title-xs;
      background-color: $primary;
      border-radius: 4px;
      text-transform: uppercase;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    }

    &:hover {
      a {
        background-color: $primary-hover;
        color: $white;
      }
    }

    @include mobile {
      display: block;
      margin-top: 16px;

      a {
        display: block;
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;
      }
    }

    &-Disable {
      padding: 12px 12px 12px 16px;
      color: $white;
      font-weight: bold;
      @include text-title-xs;
      background-color: $primary-30;
      border-radius: 4px;
      text-transform: uppercase;

      @include mobile {
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;
      }
    }
  }
}

.GeneralLink.ContactPointInfo-Link a,
.GeneralLink.ContactPointInfo-DetailsLink a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}
