.BundleSectionLink {
  margin-top: 24px;
  display: grid;
  gap: 16px;
}
.BundleSectionLink__label {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: $black-80;

  @include min-small-desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.BundleSectionLink__text {
  a {
    display: inline-block;
    color: $primary;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.24em;

    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: $primary-hover;

      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }
}
