.ProductDetails {
  background-color: $white;

  &-Wrapper {
    margin: 0 auto;
    padding: 56px 0;

    @include desktop {
      padding: 120px 0 80px;
    }

    @include small-desktop {
      padding: 120px 0 80px;
    }
  }

  &-Headline {
    margin-bottom: 24px;

    @media (min-width: #{$small-desktop}) {
      margin-bottom: 40px;
    }
  }

  &-FirstAbstract,
  &-SecondAbstract {
    @include text-body;
    color: $black-60;

    @media (min-width: #{$small-desktop}) {
      width: 75%;
    }

    &-Hide {
      display: none;
    }
  }

  &-SecondAbstract {
    margin-top: 24px;
  }

  &-More,
  &-Less {
    margin-top: 24px;
    color: $primary;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $primary-hover;

      svg {
        &.sprite-icon {
          color: $primary-hover;
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;

      &.sprite-icon {
        color: $primary;
      }
    }

    &-Hide {
      display: none;
    }
  }

  &-More {
    svg {
      margin: 0 8px 2px 0;
    }
  }

  &-Less {
    svg {
      margin: 0 8px 3px 0;
    }
  }
}
