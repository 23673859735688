// Carousel css
.alice-carousel__play-btn-wrapper {
  display: none;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none;
}

.alice-carousel__dots-item,
.alice-carousel__dots-item.__active,
.alice-carousel__dots-item:hover {
  background: none;
}

.alice-carousel__dots-item.__active {
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  border: 2px solid $primary;
  opacity: 0.97;
}

.alice-carousel__dots-item {
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  border: 2px solid #676767;
  opacity: 0.97;
}

// Component
.objectSlider {
  &-arrowLeft,
  &-arrowRight {
    position: absolute;
    top: 40%;
    cursor: pointer;
    color: blue;
  }

  &-arrowLeft {
    left: -40px;
  }

  &-arrowRight {
    right: -40px;
    outline: none;
  }
}
