.Dialog {
  position: relative;
  z-index: 110;
}

.Dialog--enter {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.Dialog--enter-from {
  transform: translateY(1rem);

  @include min-small-desktop {
    transform: translateY(0) scaleX(0.95) scaleY(0.95);
  }
}

.Dialog--enter-to {
  transform: translateY(0);
  opacity: 1;

  @include min-small-desktop {
    transform: translateX(0) scaleX(1) scaleY(1);
  }
}

.Dialog--leave {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.Dialog--leave-from {
  transform: translateY(0);
  opacity: 1;

  @include min-small-desktop {
    transform: translateY(0) scaleX(1) scaleY(1);
  }
}

.Dialog--leave-to {
  transform: translateY(1rem);
  opacity: 0;

  @include min-small-desktop {
    transform: translateY(0) scaleX(0.95) scaleY(0.95);
  }
}

.Dialog__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Dialog__backdrop--enter {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.Dialog__backdrop--enter-from {
  opacity: 0;
}

.Dialog__backdrop--enter-to {
  opacity: 1;
}

.Dialog__backdrop--leave {
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.Dialog__backdrop--leave-from {
  opacity: 1;
}

.Dialog__backdrop--leave-to {
  opacity: 0;
}

.Dialog__screen {
  overflow-y: auto;
  width: 100vw;
  z-index: 10;
  inset: 0px;
  position: fixed;
}

.Dialog__placement {
  padding: 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 100%;
  display: flex;
}

.Dialog__wrapper {
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: left;
  background-color: #ffffff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
}

.Dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px 16px 20px;

  @include min-small-desktop {
    padding-left: 24px;
    padding-right: 24px;
    gap: 16px;
  }
}

.Dialog__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  color: $black-80;
  padding-right: 16px;
}

.Dialog__close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}
