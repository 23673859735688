.WishlistPopup {
  padding: 32px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  width: 380px;
  position: absolute;
  right: -12%;
  top: 40px;
  text-align: left;
  cursor: default;
  &:before {
    content: '';
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 36px;
    height: 80px;
    bottom: 66%;
    right: 20%;
    z-index: -1;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    @include mobile {
      display: none;
    }
  }
  &:after {
    bottom: 100%;
    right: 18%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: $white;
    border-width: 10px;
    margin-left: -18px;
    @include mobile {
      display: none;
    }
  }

  @include tablet {
    right: 16%;
  }
  @include mobile {
    @include mobile {
      position: fixed;
      width: 100%;
      background-color: $white;
      padding: 24px;
      z-index: 105;
      top: 0;
      right: 0;
      height: 100vh;
      max-height: 100vh;
      overflow: auto;
    }
  }
  &-Heading {
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
      @media (min-width: #{$tablet}) {
        display: none;
      }
    }
  }
  &-Title {
    color: $black-80;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 25px;
    text-transform: uppercase;
  }
  &-Text {
    color: $black-60;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-top: 4px;
  }
  &-WishlistButton {
    margin-top: 32px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    display: inline-block;
    font-size: 0;
    @include mobile {
      display: block;
    }

    a {
      padding: 12px 16px;
      color: $white;
      font-weight: bold;
      @include text-title-xs;
      background-color: $primary;
      border-radius: 4px;
      text-transform: uppercase;
      display: inline-block;

      @include mobile {
        display: block;
        text-align: center;
        padding: 18px 16px;
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 19px;
      }
    }
    &:hover {
      a {
        color: $white;
        background-color: $primary-hover;
      }
    }
  }
  &-ContinueButton {
    font-weight: bold;
    text-align: center;
    padding: 18px 16px;
    @include text-section-title-small;
    color: $primary;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin-top: 24px;
    cursor: pointer;
  }
  &-Product {
    margin-top: 24px;
    display: flex;
    align-items: center;
    &-Image {
      height: 60px;
      width: 62px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-Details {
      padding-left: 16px;
      &-Title {
        color: $black-80;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
        display: block;
      }
      &-ArticleLabel,
      &-Article {
        color: $black-60;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
}
