.ContactInfo {
  padding: 36px 40px;
  background-color: $black-3;
  width: 100%;

  @include tablet {
    padding: 72px 24px;
  }

  @include mobile {
    padding: 72px 24px;
  }

  &-Title {
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    padding-bottom: 12px;
  }

  &-ContainerPartner {
    padding-bottom: 12px;
  }

  &-Partner {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2.14px;
    font-size: 12px;
    padding-right: 8px;
  }

  &-Provider {
    color: $primary;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 14px;
    display: inline-block;
  }

  &-Subtitle,
  &-BottomSubtitle {
    color: $black-80;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 25px;
    padding-bottom: 16px;
  }

  &-Address {
    color: $black-80;
    font-weight: 400;
    @include text-body;
    padding-bottom: 16px;
  }

  &-Schedule,
  &-Phone {
    color: $black-80;
    font-weight: 400;
    @include text-body;
  }

  &-PhoneIcon {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: $primary;
    padding: 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    display: block;

    svg {
      width: 24px;
      height: 24px;

      &.sprite-icon {
        color: $white;
      }
    }

    &:active {
      background-color: $primary-hover;
    }
  }

  &-MiddleLine {
    box-shadow: inset 0 -1px 0 0 $black-10;
    margin: 32px 0 36px 0;
    height: 1px;
  }

  &-Description {
    color: $black-80;
    font-weight: 400;
    @include text-body;
    padding-bottom: 16px;
  }

  &-Link {
    font-weight: bold;
    @include text-section-title-small;
    display: inline-block;
    text-transform: uppercase;

    @include tablet {
      letter-spacing: 2px;
    }

    a {
      color: $primary;
    }

    svg {
      margin-bottom: -1px;
      margin-left: 12px;

      @include tablet {
        margin-left: 8px;
      }
    }

    &:hover {
      a,
      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }
}

.GeneralLink.ContactInfo-Link a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}
