.ProductDetailsLabel {
  margin-top: 32px;
  margin-bottom: 32px;

  a {
    display: inline-block;
    color: $primary;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    text-decoration: none;

    svg {
      margin-right: 8px;
      margin-bottom: -2px;
    }

    &:hover {
      color: $primary-hover;

      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }
}
