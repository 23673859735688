.NewsletterSubscription {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 0;

  &Container {
    background-color: #f9f9f9;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./../../../assets/css-svg/main-background-image.svg);
  }

  @media (min-width: #{$tablet}) {
    align-items: center;
    display: flex;
  }

  &_Header {
    display: flex;
    flex: 6;
    flex-direction: column;
    margin-right: calc(100% / 12);

    h2 {
      color: $black-80;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      margin: 0 0 24px;
      order: 2;
      padding: 0;

      @media (min-width: #{$tablet}) {
        margin-bottom: 0;
      }
    }

    h3 {
      color: $black-60;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      order: 1;
      margin: 0 0 24px;
      padding: 0;
      text-transform: uppercase;
    }
  }

  &_Form {
    display: flex;
    flex-direction: column;
    flex: 4;
    margin: 24px 0 0 0;

    @media (min-width: #{$mobile}) {
      flex-direction: row;
      margin: 0;
    }

    &.SitecoreForm {
      padding: 0;
      width: auto;
    }

    .InputV2_Container {
      display: flex;
      flex: 1;
      position: relative;
    }

    input {
      flex: 1;
    }

    button {
      flex: 1;
      margin: 24px 0 0 0;
      width: 100%;

      @media (min-width: #{$mobile}) {
        margin-left: 24px;
        margin-top: 0;
        width: auto;
      }
    }

    .invalid {
      bottom: -24px;
      position: absolute;
      left: 0;
      max-width: 100%;
    }
  }

  &_InputCol {
    flex: 1;
  }

  .GeneralLink {
    display: inline-block;
    border-radius: 4px;

    a {
      display: inline-block;
      padding: 12px 16px;
      background-color: $primary;
      color: $white;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      font-size: 12px;
      letter-spacing: 2.57px;
      line-height: 32px;
      height: 56px;
      margin: 0 0 0 24px;
      text-transform: uppercase;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    }

    &:hover a {
      background-color: $primary-hover;
      color: $white;
    }
  }
}
