.ScrollSnap__Container {
  position: relative;
  overflow: hidden;
  background-color: white;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    height: 1px;
    width: 200vw;
    left: -50vw;
    bottom: 0;
    box-shadow: inset 0 -1px 0 0 #e5e5e5;
  }
}

.ScrollSnap {
  width: 100%;
  white-space: nowrap;
  background-color: #fff;
  text-align: left;
  border-bottom: none;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: auto;
  padding-left: 24px;
  padding-right: 24px;
  min-height: 40px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  @include min-small-desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktop {
    min-height: 52px;
    padding-left: 130px;
    padding-right: 130px;
  }

  * .ScrollSnap--focus {
    scroll-snap-align: center;

    .ScrollSnap--initialized & {
      // Override the scroll-snap-align after initializing, to unforce its power
      scroll-snap-align: none;
    }
  }
}

.ScrollSnapArrow {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 83px; // This height will get overriden with react inline-style
  z-index: 2;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  background: linear-gradient(90deg, white, #ffffffeb, transparent);
  transition: transform 400ms ease;
  pointer-events: none;

  &.ScrollSnapArrow--disabled {
    display: none;
  }

  &.ScrollSnapArrow--left {
    transform: translateX(-100%);
  }

  &.ScrollSnapArrow--right {
    left: unset;
    right: 0;
    justify-content: end;
    padding-left: 0;
    padding-right: 10px;
    background: linear-gradient(90deg, transparent, #ffffffeb, white);
    transform: translateX(100%);
  }

  &.ScrollSnapArrow--show {
    transform: translateX(0);
  }
}
