.ReadMoreRichText {
  padding: 48px 0;

  @media (min-width: #{$small-desktop}) {
    padding: 112px 0;
  }

  &-Headline {
    margin-bottom: 16px;
  }

  &-FirstAbstract,
  &-SecondAbstract {
    @include text-body;
    color: $black-60;
    @include rich-text-a-style;
    @include rich-text-list-style;

    @media (min-width: #{$small-desktop}) {
      font-size: 24px;
      line-height: 33px;
      width: 75%;
    }
  }

  &-SecondAbstract {
    margin-top: 24px;
  }

  &-More,
  &-Less {
    margin-top: 24px;
    color: $primary;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;

    &:hover {
      color: $primary-hover;

      svg {
        color: $primary-hover;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      color: $primary;
    }
  }

  &-More {
    svg {
      margin: 0 16px 2px 0;
    }
  }

  &-Less {
    svg {
      margin: 0 16px 3px 0;
    }
  }
}
