$desktop: 1440px;
$small-desktop: 960px;
$tablet: 768px;
$mobile: 320px;

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: #{$small-desktop}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin min-small-desktop {
  @media (min-width: #{$small-desktop}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$small-desktop - 1px}) {
    @content;
  }
}

@mixin min-tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin max-small-desktop {
  @media (max-width: #{$small-desktop - 1px}) {
    @content;
  }
}
