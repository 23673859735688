.ContentAccordion {
  margin: 0 auto !important;
  padding: 40px 0;

  &-Column {
    @include tablet {
      padding: 0 40px !important;
    }
    &-Title {
      color: $black-80;
      margin-bottom: 27px;
      @include tablet {
        margin-bottom: 10px;
      }
      @include mobile {
        padding-left: 24px;
        padding-right: 24px;
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 33px;
        margin-bottom: 10px;
      }
    }

    &-Text {
      @include rich-text-list-style;
      @include rich-text-a-style;
      color: $black-80;
      @include text-body;
      margin-bottom: 32px;
      @include mobile {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 24px;
      }
      @include tablet {
        margin-bottom: 24px;
      }
    }
  }

  .ContentAccordionElements-Item-BottomBorder:last-child {
    border-bottom: 1px solid $black-10;
  }
}
