.ColumnCategory {
  @include mobile {
    margin-top: 56px !important;
  }

  &-Title {
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    padding-bottom: 28px;
    cursor: default;
  }
}

.ThreeColumnCategory {
  &.WhiteColorStyle {
    .ColumnCategory {
      &-Title {
        color: $white;
      }
    }
  }
}
