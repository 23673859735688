.SearchResultTab {
  color: #67768b;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: normal;
  font-weight: 700;
  font-size: var(--font-size-section-title-small);
  letter-spacing: var(--letter-spacing-section-title-small);
  line-height: var(--line-height-section-title-small);
  border: none;
  border-bottom: 2px solid transparent;
  padding: 16px 24px 14px;
  margin: 0;
  background-color: transparent;
  position: relative;
  z-index: 1;
}

.SearchResultTab--active {
  color: #d40028;
  border-bottom: 2px solid #d40028;
}

.SearchResultTab__Text {
  display: inline-block;
  vertical-align: middle;
  line-height: 52px;
  margin: 0;
}
