.FacetBreadcrumb__List {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;

  @include min-tablet {
    display: block;
  }
}

.FacetBreadcrumb__List-Item {
  display: inline-block;
}

.FacetBreadcrumb {
  background: none;
  border: none;
  border-radius: 16px;
  background-color: #f4f4f4;
  padding: 8px 8px 8px 16px;
  margin: 4px !important;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .FacetBreadcrumb__Clear {
      background-color: #b40028;
    }
  }
}

.FacetBreadcrumb__Text {
  margin-right: 8px;
}

.FacetBreadcrumb__Clear {
  background-color: #b4b4b4;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 6px;

  svg {
    top: 4px;
    left: 4px;
    border-radius: 16px;
    width: 8px;
    height: 8px;
    color: #ffffff;
  }
}
