.CompareTechnicalSpecsNavigationHeader {
  width: 100%;
  height: 56px;
  display: inline-block;
  background-color: $black-3;
  border-bottom: 1px solid $border;
  margin-bottom: 20px;

  &-ArrowLeft {
    width: 10%;
    display: inline-block;
    padding-left: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;

      g {
        transform: translateX(8px) rotate(90deg);
      }
    }
  }

  &-ArrowRight {
    width: 10%;
    display: inline-block;
    padding-right: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-SpecsName {
    display: inline-block;
    width: 80%;
    text-align: center;
    color: $black-60;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2.57px;
    line-height: 56px;
    text-transform: uppercase;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
