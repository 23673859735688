:root {
  --font-size-list-element: --font-size-body;

  --line-height-list-element: --line-height-body;
  --line-height-ordered-list-element: 32px;
}

@mixin content-padding {
  padding: 0 130px 0 130px;

  @include tablet {
    padding: 0 10px 0 10px;
  }

  @include mobile {
    padding: 0 10px 0 10px;
  }
}

@mixin button-link {
  color: $white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.57px;
  text-decoration: none;
  padding: 16px 24px;
  display: inline-block;
  min-height: 40px;
  min-width: 133px;
  border-radius: 4px;
  background-color: $primary;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: $primary-hover;
  }
}

@mixin small-button-link {
  color: $white;
  font-size: 12px;
  letter-spacing: 2.57px;
  line-height: 16px;
  text-decoration: none;
  padding: 12px 16px;
  display: inline-block;
  font-weight: bold;
  border-radius: 4px;
  background-color: $primary;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: $primary-hover;
  }
}

@mixin button-link-secondary {
  color: $primary;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.57px;
  text-decoration: none;
  padding: 16px 24px;
  display: inline-block;
  min-height: 40px;
  min-width: 133px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}

@mixin tooltip-product {
  font-weight: bold;
  white-space: nowrap;
  visibility: hidden;
  background-color: $black;
  color: $white;
  border-radius: 4px;
  padding: 5px 8px 5px 8px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 14px;
  opacity: 0.9;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $black transparent transparent transparent;
  }
}

@mixin rich-text-list-style {
  strong,
  b {
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: var(--font-size-list-element);
      line-height: var(--line-height-list-element);
      margin-bottom: 8px;
      padding-left: 24px;
      position: relative;

      &::before {
        content: '';
        background-color: $primary;
        width: 7px;
        height: 7px;
        margin-top: 8px;
        margin-bottom: 2px;
        position: absolute;
        left: 4px;
      }
    }
  }

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
      font-size: var(--font-size-list-element);
      line-height: var(--line-height-ordered-list-element);
      padding-left: 48px;
      position: relative;
      margin-top: 24px;

      &:before {
        content: counter(my-awesome-counter);
        color: $primary;
        font-weight: bold;
        letter-spacing: 1.71px;
        height: 32px;
        width: 32px;
        text-align: center;
        border: solid 1px;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 32px;
      }
    }
  }
}

@mixin radio-input-style {
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 10px;
    border: 2px solid $border;
    background-color: $white;
  }

  /* On mouse-over, add a grey background color */
  label:hover input ~ .checkmark {
    border-color: $primary;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    border-color: $primary;
  }

  .checkmark.red-border {
    border-color: $primary;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    height: 8px;
    width: 8px;
    border-radius: 10px;
    left: 2px;
    top: 2px;
    background-color: $primary;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
}

@mixin checkbox-input-style {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid $border;
    background-color: $white;
  }

  /* On mouse-over, change also the checkmark */
  label:hover input ~ .checkmark {
    border-color: $primary;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    border-color: $primary;
  }

  .checkmark.red-border {
    border-color: $primary;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    width: 8px;
    height: 8px;
    background-size: 8px;
    left: 2px;
    top: 2px;
    background-image: url(./../assets/css-svg/checkmark.svg);
    background-repeat: no-repeat;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
}

// Extended checkbox input style which supports hover and focus on label as well
@mixin checkbox-input-style-extended {
  // Apply Default checkbox input style
  @include checkbox-input-style;

  // Further defaults for the extended version
  display: flex;
  cursor: pointer;
  position: relative;

  .checkmark {
    top: 0; // Fixing -1px from the helper
  }

  &:hover {
    color: $primary;

    input ~ .checkmark {
      border-color: $primary;
    }
  }

  input:focus ~ .checkmark {
    border-color: $primary;
  }
}

@mixin component-container {
  max-width: 1440px;
  padding-left: 130px;
  padding-right: 130px;
  margin-left: auto !important;
  margin-right: auto !important;

  @include small-desktop {
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }

  @include tablet {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @include mobile {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@mixin rich-text-a-style {
  a,
  .scEnabledChrome a {
    text-decoration: none;
    color: $primary;
  }

  a:hover,
  .scEnabledChrome a:hover {
    color: $primary-hover;
  }
}

@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
