.SolutionCard {
  position: relative;
  margin: 0 12px 24px 12px;
  overflow: hidden;

  &:hover {
    .SolutionCard-Content-ExploreMore {
      color: $primary-hover;

      svg.sprite-icon {
        color: $primary-hover;
      }
    }

    .SolutionCard-Image {
      transform: scale(1.05);
    }
  }

  &-Overlay {
    width: 100%;
    height: 45%;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.9;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $black 100%);

    @media (min-width: #{$small-desktop}) {
      height: 61%;
    }
  }

  &-Image {
    width: 100%;
    height: 100%;
    transition: transform 1.5s ease;
  }

  &-Content {
    color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px 24px 8px 24px;

    @include small-desktop {
      padding: 24px 32px 24px 32px;
    }

    @include desktop {
      padding: 40px 40px 32px 40px;
    }

    &-Overline {
      text-transform: uppercase;
      @include text-section-title-small;
      font-weight: bold;

      @media (min-width: #{$small-desktop}) {
        font-size: 12px;
        letter-spacing: 2.57px;
        line-height: 16px;
      }
    }

    &-Title {
      padding: 4px 0 8px;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
    }

    &-Description {
      @include text-body;
      margin-bottom: 24px;
      max-height: 72px;
      overflow: hidden;
    }

    &-ExploreMore {
      color: $primary;
      display: flex;
      align-items: center;
      @include text-section-title-small;
      font-weight: bold;
      text-transform: uppercase;

      svg {
        margin-left: 16px;
        margin-bottom: 3px;
      }
    }
  }
}
