.MyLeisterAppPromotion {
  align-items: center !important;
  margin: 0;

  &-ImageWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media (min-width: #{$tablet}) {
      justify-content: unset;
    }
    &.ImageRight {
      @media (min-width: #{$tablet}) {
        justify-content: flex-end;
      }
    }
  }

  &-Image {
    padding: 80px 0;
    max-width: 416px;
    height: auto;

    @include tablet {
      padding: 40px 24px 24px 24px;
      max-width: 320px;
    }

    @include mobile {
      padding: 56px 24px 0 24px;
      max-width: 320px;
    }
  }
  &-Wrapper {
    color: $black-80;
    @include mobile {
      padding: 24px 24px 56px 24px;
    }
    &.WhiteColorStyle {
      color: $white;
    }
  }

  &-Title {
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 42px;
    padding-bottom: 8px;
    padding-top: 24px;
    cursor: default;

    @include max-small-desktop {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      padding-top: 16px;
      padding-bottom: 0;
    }
  }

  &-Subtitle {
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 49px;

    cursor: default;

    @include max-small-desktop {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
    }
  }
  &-Description {
    padding-top: 24px;

    @include max-small-desktop {
      padding-top: 16px;
    }

    p {
      margin: 0;
    }
    @include rich-text-a-style;
    h3,
    h4 {
      padding-bottom: 16px;
    }
    h2 {
      padding-bottom: 24px;
    }
  }

  &-Button {
    margin-top: 24px;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    a {
      padding: 12px 16px;
      display: inline-block;
      background-color: $primary;
      border-radius: 4px;
      color: $white;
      @include text-title-xs;
      font-weight: bold;
      text-transform: uppercase;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    }
    &:hover a {
      background-color: $primary-hover;
      color: $white;
    }
  }
  &-StoreLink {
    .StoreLink {
      .GeneralLink.StoreLink-Link {
        .scTextWrapper.scEnabledChrome {
          display: none;
        }
      }
      @include tablet {
        margin-right: 16px;
        width: 40px;
        height: 40px;
        .StoreLink-Link,
        .StoreLink-Icon,
        .StoreLink-Icon-Hover {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.GeneralLink.MyLeisterAppPromotion-Button a {
  color: $white;
}
