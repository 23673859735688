.ImageSlider {
  margin-bottom: 56px;
  position: relative;
  overflow: hidden;
  margin: auto;
  background-color: $white;
  width: 100%;

  @media (max-width: #{$small-desktop}) {
    box-shadow: inset 0 -1px 0 0 $black-10;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  &:before {
    content: '';
    display: table;
    clear: both;
  }

  &-Container {
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;

    &-Full {
      flex-direction: column;
      flex-flow: column;
      width: 100%;
      min-width: 100%;

      &-Image {
        width: 100%;
        height: auto;
      }
    }

    &-Multiple {
      flex-direction: column;
      flex-flow: column;
      display: flex;
      width: 100%;
      min-width: 100%;

      @media (min-width: #{$small-desktop}) {
        width: 66.66%;
        min-width: 66.66%;

        &.WiderImage {
          width: 84%;
          min-width: 84%;
        }
      }

      &-Wrapper {
        outline: none;
        width: 100%;
        height: 100%;
        font-size: 0;

        &.LowOpacity {
          opacity: 0.28;
          cursor: pointer;
        }
      }

      &-Image {
        width: 100%;
        height: auto;
      }

      &-Caption {
        &-Next {
          position: absolute;
          right: 40px;
          bottom: 24px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          pointer-events: none;

          &-Pagination {
            text-align: right;
            text-transform: uppercase;

            div:first-child {
              pointer-events: none;
              cursor: pointer;
              color: $black-80;
              font-size: 10px;
              letter-spacing: 2.14px;
              line-height: 14px;
            }

            div:last-child {
              pointer-events: none;
              cursor: pointer;
              color: $black-60;
              font-weight: bold;
              font-size: 10px;
              letter-spacing: 2.14px;
              line-height: 14px;
            }
          }

          &-Arrow {
            svg {
              width: 24px;
              height: 24px;
              margin-top: 4px;
              color: $black-60;
            }

            margin-left: 16px;
          }
        }

        &-Back {
          position: absolute;
          left: 40px;
          bottom: 24px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;

          &-Pagination {
            text-transform: uppercase;

            div:first-child {
              color: $black-80;
              font-size: 10px;
              letter-spacing: 2.14px;
              line-height: 14px;
            }

            div:last-child {
              color: $black-60;
              font-weight: bold;
              font-size: 10px;
              letter-spacing: 2.14px;
              line-height: 14px;
            }
          }

          &-Arrow {
            svg {
              width: 24px;
              height: 24px;
              margin-top: 4px;
              color: $black-60;
            }

            margin-right: 16px;
          }
        }
      }
    }
  }

  &-popup {
    padding: 32px 24px;
    background-color: $white;
    z-index: 10;

    @include small-desktop {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 40px 32px;
      width: 560px;
      height: 266px;
      right: 130px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }

    @include desktop {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 56px 48px;
      width: 590px;
      height: 314px;
      right: 130px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }

    &-headline {
      padding-bottom: 16px;
      color: $black-60;
      @include text-title-xs;
      font-weight: bold;
      display: block;
      text-transform: uppercase;

      @include desktop {
        padding-bottom: 24px;
      }

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 19px;
      }
    }

    &-title {
      color: $black-80;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 25px;
      margin-bottom: 8px;

      @media (min-width: #{$small-desktop}) {
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 33px;
      }
    }

    &-description {
      margin-bottom: 16px;
      font-size: 14px;
      color: $black-60;
      line-height: 21px;
      cursor: default;

      @media (min-width: #{$small-desktop}) {
        font-size: 16px;
        margin-bottom: 24px;
      }
    }

    &-button-link {
      a {
        color: $white;
        font-weight: bold;
        @include text-title-xs;
        display: inline-block;
        text-decoration: none;
        padding: 12px 12px;
        border-radius: 4px;
        background-color: $primary;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
          color: $white;
          background-color: $primary-hover;
        }

        &:before {
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }

  &-Dots {
    text-align: center;
    margin-bottom: 56px;

    &-Item {
      margin: 0 4px;
      display: inline-block;
      cursor: pointer;
      height: 18px;
      width: 18px;
      border: 2px solid $black-60;
      opacity: 0.97;
      position: relative;
      border-radius: 50%;
      outline: none;

      &-Active {
        border: 2px solid $primary;

        &-Circle {
          position: absolute;
          border-radius: 50%;
          background-color: $primary;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 6px;
          width: 6px;
        }
      }
    }
  }
}
