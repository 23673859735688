@import '../../../../../assets/colors.scss';

.BackToOverviewSection {
  margin: 24px 0 24px 0;

  @include tablet {
    padding: 24px 0 0;
  }

  @media (min-width: #{$small-desktop}) {
    padding: 80px 0 0;
  }

  .LinkElement-Link {
    padding: 0;
    width: auto;

    svg {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
