.link-button {
  font-family: var(--font-family-base);
  font-weight: bold;
  @include text-section-title-medium;
  display: flex;
  align-items: center;
  color: $primary;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  text-align: left;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    text-decoration: none;
    color: $primary-hover;
  }

  &:visited {
    color: $primary;
    text-decoration: none;
  }

  svg {
    margin-left: 16px;
    margin-bottom: 2px;
    flex-shrink: 0;
  }
}

.link-button--small {
  font-size: 14px;
  line-height: 1.42857;
}

.link-button__before-icon {
  margin-left: 0;
  margin-right: 8px;
  display: flex;

  svg {
    margin-left: 0;
  }
}
