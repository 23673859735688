.BundleDisplay-Container {
  background-color: #f9f9f9;
  gap: 16px;

  padding: 24px 12px;
  @include min-small-desktop {
    padding: 24px;
  }

  // Smaller components for bundles
  .CartItem__quantity {
    height: 48px;
  }

  // Smaller button for bundles
  .AddToCart__Button {
    padding-block: 16px;
  }

  .AddToCart__Line {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }
}

.BundleDisplay__ShowMoreButton {
  margin-block: 4px;
}

.BundleDisplay__BundleName {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-align: left;
}
.BundleDisplay__PriceText {
  display: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: left;
  @include min-small-desktop {
    display: block;
  }
}

.BundleDisplay__ItemContainer {
  display: grid;
  gap: 16px;
  flex-wrap: wrap;
  grid-template-columns: 1fr;
  @include min-tablet {
    grid-template-columns: repeat(2, 1fr);
  }
  // Note: Seems to be a better option to break earlier at 1200 because grid layout jumps as well there.
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.BundleDisplay__FooterCol {
  flex: 1 0 100%;
  @include min-small-desktop {
    flex: 0 1 0 !important; // Required to override the Row's internal styling
  }
}

.BundleDisplay__FooterCol__PriceDetailsRow {
  gap: 16px;

  width: 100%;
  @include min-small-desktop {
    width: max-content;
  }

  @include max-small-desktop {
    border-top: 1px solid $black-10;
    padding-top: 24px;
  }
}
