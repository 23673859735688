.MyAccountOrderDetails__DetailsWrapper {
  @include min-small-desktop {
    background-color: $black-3;
  }
}

.MyAccountOrderDetails__Details {
  display: flex;
  flex-direction: column;

  @include min-small-desktop {
    @include component-container;
    padding-top: 72px;
    padding-bottom: 160px;
    gap: 40px;
  }
}

.MyAccountOrderDetails__Details__Addresses {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  @include max-small-desktop {
    flex-direction: column;
    gap: 0;
  }
}

.MyAccountOrderDetails__Address {
  flex-grow: 1;
  background-color: $white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include max-small-desktop {
    border-top: 1px solid $black-10;
    padding: 48px 20px;
  }
}

.MyAccountOrderDetails__Address__Lines,
.MyAccountOrderDetails__Address__StatusLine {
  display: flex;
  flex-direction: column;
  @include text-sm;
  color: $black-60;
}

.MyAccountOrderDetails__Address__Title {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @include max-small-desktop {
    font-size: 18px;
    letter-spacing: 0.015em;
    line-height: 24px;
  }
}

.MyAccountOrderDetails__OrderedProducts {
  background-color: $white;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @include max-small-desktop {
    padding: 48px 20px;
  }

  table {
    width: 100%;
    table-layout: fixed;

    th,
    td {
      &:first-child {
        width: 400px;
      }
      &:not(:first-child) {
        width: auto;
      }
    }

    tr:hover {
      td {
        background-color: inherit;
        cursor: default;
      }
    }
  }
}

.MyAccountOrderDetails__OrderedProducts__Title {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @include max-small-desktop {
    font-size: 18px;
    letter-spacing: 0.015em;
    line-height: 24px;
  }
}

.MyAccountOrderDetails__CostSummary {
  padding-top: 16px;
  margin-left: auto;
  width: 100%;

  @include min-small-desktop {
    padding-right: 16px;
    width: 380px;
  }
}

.MyAccountOrderDetails__CostSummary__Total {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  @include text-body;
  color: $black-80;
  font-weight: 700;
}

.MyAccountOrderDetails__Breakdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.MyAccountOrderDetails__Breakdown__CostItem {
  display: flex;
  justify-content: space-between;
  @include text-sm;
}

.MyAccountOrderDetails__Breakdown__CostItem__Label {
  color: $black;
}
.MyAccountOrderDetails__Breakdown__CostItem__Amount {
  color: $black-60;
}

.MyAccountOrderDetails__Header {
  @include component-container;
  padding-bottom: 40px;

  @include max-small-desktop {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;
    border-bottom: 1px solid $black-10;
  }
}

.MyAccountOrderDetails__Header__BackTo {
  margin: 56px 0 16px 0;

  @include min-small-desktop {
    margin: 120px 0 16px 0;
  }

  a {
    color: $primary;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    @include text-section-title-small;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
    flex-shrink: 0;
  }

  &:hover {
    a {
      color: $primary-hover;
    }

    svg {
      &.sprite-icon {
        color: $primary-hover;
      }
    }
  }
}

.MyAccountOrderDetails__Header__OrderProperties {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin-top: 8px;

  @include max-small-desktop {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.MyAccountOrderDetails__Summary {
  @include text-sm;
  color: $black-60;
}

.MyAccountOrderDetails__Summary__Price {
  display: inline;
}

.MyAccountOrderDetails__OrderNotFound {
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-top: 40px;
  margin-bottom: 40px;

  @include max-small-desktop {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    margin: 0;
  }
}

.MyAccountOrderDetails__Loading {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @include max-small-desktop {
    margin-top: 40px;
  }
}
