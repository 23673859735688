.FooterLinkColumn {
  display: inline-block;
  width: 200px;
  margin-right: 100px;
  @include tablet {
    width: 50%;
    margin-right: 0;
    margin-bottom: 66px;
  }
  @include mobile {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
  }
  &-title {
    line-height: 25px;
    color: $black-80;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 16px;
    @include mobile {
      margin-bottom: 8px;
    }
  }

  &-link {
    color: $black-60;
    font-size: 18px;
    a {
      text-decoration: none;
      color: $black-60;
      &:hover {
        color: $primary;
      }
    }
  }
}
