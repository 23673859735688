.NoComparedData {
  margin: 40px 0;
  @media (min-width: #{$small-desktop}) {
    margin: 80px 0;
  }
  &-Title,
  &-Description {
    @media (min-width: #{$small-desktop}) {
      width: 66.66%;
    }
  }

  &-Description {
    padding-top: 48px;
    font-weight: 400;
    @include text-body;
    @include rich-text-a-style;
    @include rich-text-list-style;
  }
}
