.GeneralLink {
  color: $black-60;
  font-size: 18px;
  a {
    text-decoration: none;
    color: $black-60;
    &:hover {
      color: $primary;
    }
  }
}
