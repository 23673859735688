.ContactOneColumn {
  min-height: 24px;
  &-Container {
    width: 66.66%;
    margin: 0 auto;
    min-height: 24px;

    @include max-small-desktop {
      width: 100%;
    }

    @include tablet {
      padding: 0 40px;
    }
  }
  .Dropdown {
    width: 100%;
    margin: 0 0 24px 0;
    height: 56px;
    @media (min-width: #{$tablet}) {
      width: calc(100% - 10px);
      margin: 0 0 40px 0;
    }
    &-Wrapper {
      padding-top: 8px;
    }
    &-Pointer {
      top: 24px;
      right: 16px;
    }
    &-Options {
      width: 100%;
      height: auto;
      padding-bottom: 8px;
    }
  }
}
