@import './CartItem/CartItem.scss';
@import './DeleteItemButton/DeleteItemButton.scss';

.Cart__empty {
  display: flex;
  overflow: hidden;
  margin-top: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  flex-grow: 1;

  @include min-small-desktop {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Cart__empty-text {
  color: $black-80;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  padding: 0;
  margin: 0;
}

.Cart__empty-icon {
  height: 4rem;
}

.Cart__main {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Cart__items {
  padding: 0 20px 16px 20px;
  overflow: auto;
  flex-grow: 1;

  @include min-small-desktop {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Cart__item {
  display: flex;
  flex-direction: column;
  width: 100%;

  + .Cart__item {
    border-top: 1px solid $black-10;
  }
}

.Cart__bottom {
  border-top: 1px solid $black-10;
  padding: 24px 20px 40px 20px;

  @include min-small-desktop {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Cart__sub-total,
.Cart__total,
.Cart__discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: $black-80;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.Cart__total {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.Cart__tax-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
  color: $black-80;
}

.Cart__go-to-checkout {
  margin-top: 24px;

  &.button {
    padding: 21px 24px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    height: auto;
    width: 100%;
  }
}
