.SalesAndServicePointDetailPageHero {
  &-Container {
    width: 100%;
    padding: 16px 0 32px;

    @include tablet {
      padding: 40px 64px 32px 64px;
    }

    @media (min-width: #{$small-desktop}) {
      width: 66.66%;
      padding: 40px 0 32px 0;
    }

    margin: 0 auto;

    &-Content {
      display: flex;
      justify-content: space-between;

      @include mobile {
        width: 100%;
        padding: 0 24px;
        display: block;
      }

      &-Wrapper {
        width: 100%;

        @media (min-width: #{$tablet}) {
          width: 75%;
        }
      }

      &-Adress {
        display: flex;
        flex-flow: wrap;
        margin-bottom: 32px;

        &-Label {
          color: $black-60;
          padding-bottom: 8px;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 3px;
          line-height: 32px;
          width: 33.33%;
          text-transform: uppercase;

          @include mobile {
            width: 100%;
          }
        }

        &-Items {
          color: $black-80;
          @include text-body;
          width: 66.66%;

          @include mobile {
            width: 100%;
          }

          &-CompanyName {
            font-weight: bold;
            padding-bottom: 16px;
            padding-top: 2px;
          }
        }

        &-Link {
          font-weight: bold;
          @include text-section-title-small;
          text-transform: uppercase;
          margin-top: 16px;

          a {
            color: $primary;
          }

          svg {
            margin-bottom: -3px;
            margin-right: 8px;
          }

          &:hover {
            a,
            svg {
              color: $primary-hover;
            }
          }
        }
      }

      &-Contact {
        display: flex;
        flex-flow: wrap;

        &-Label {
          color: $black-60;
          padding-bottom: 8px;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 3px;
          line-height: 32px;
          width: 33.33%;
          text-transform: uppercase;

          @include mobile {
            width: 100%;
          }
        }

        &-SocialItems {
          padding: 32px 0 8px 0;

          @include mobile {
            padding: 16px 0 8px 0;
          }

          .SocialLink {
            @include mobile {
              display: inline-block;
              padding-right: 0;
            }

            &-item {
              a {
                width: 32px;
                height: 32px;
                margin-right: 8px;
              }

              .GeneralLink {
                font-size: 0;
              }
            }
          }
        }

        &-Items {
          width: 66.66%;

          svg {
            margin-right: 11px;
            margin-bottom: -2px;
          }

          @include mobile {
            width: 100%;
          }

          &-Number {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 32px;

            a {
              color: $black-80;
            }

            &-Fax {
              background-image: url(./../../../assets/css-svg/fax.svg);
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-bottom: -2px;
              margin-right: 8px;
            }
          }

          &-Link,
          &-Email {
            display: block;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 32px;
            color: $primary;
            text-decoration: none;

            a,
            svg.sprite-icon {
              color: $primary;
            }

            &:hover a {
              color: $primary-hover;
            }
          }
        }
      }

      &-Highlights {
        display: flex;
        flex-flow: wrap;
        margin-top: 24px;

        &-Label {
          color: $black-60;
          padding-bottom: 8px;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 3px;
          line-height: 32px;
          width: 33.33%;
          text-transform: uppercase;

          @include mobile {
            width: 100%;
            padding-bottom: 16px;
          }
        }

        &-Items {
          width: 66.66%;
          line-height: 32px;

          @include mobile {
            width: 100%;
            line-height: 26px;
          }

          &-Item {
            display: flex;
            margin-bottom: 8px;

            svg {
              flex-shrink: 0;
              width: 16px;
              height: 16px;
              margin-right: 12px;
              margin-top: 7px;

              @include mobile {
                margin-top: 4px;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-Logo {
      padding-bottom: 36px;

      @include mobile {
        width: 100%;
        padding-bottom: 8px;
      }

      &-Image {
        margin-bottom: 16px;
        max-width: 180px;
        font-size: 0;

        a {
          display: inline-block;
        }

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
          display: flex;
        }

        @include mobile {
          max-width: 100%;

          margin-bottom: 8px;
        }
      }
    }
  }
}

// For Experince Editor

.SalesAndServicePointDetailPageHero
  .GeneralLink.SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Link
  .scEnabledChrome {
  display: none;
}

.SalesAndServicePointDetailPageHero
  .GeneralLink.SalesAndServicePointDetailPageHero-Container-Content-Adress-Link
  .scTextWrapper.scEnabledChrome,
.SalesAndServicePointDetailPageHero
  .SalesAndServicePointDetailPageHero-Container-Content-Contact-SocialItems
  .scTextWrapper.scEnabledChrome {
  display: none;
}

.SalesAndServicePointDetailPageHero .GeneralLink.SalesAndServicePointDetailPageHero-Container-Content-Adress-Link a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}
