.EditAddressForm {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
}

.EditAddressForm__Content {
  padding: 20px;
  overflow-y: auto;

  @include min-small-desktop {
    padding: 24px;
  }
}

.EditAddressForm__Footer {
  padding: 24px 20px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include min-small-desktop {
    padding: 24px;
  }

  .button ~ .button {
    margin: 0;
  }
}

.EditAddressForm__Disclaimer {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-60;
}
