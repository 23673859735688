.AccountInformations {
  background-color: $white;
  position: relative;
  padding: 56px 24px;
  border-bottom: 1px solid $black-10;
  @media (min-width:#{$tablet}) {
    padding: 32px 40px;
    border-bottom: none;
    margin-bottom: 40px;
  }
  &-Title {
    color: $black-80;
    margin-bottom: 24px;

    @media (max-width:#{$tablet}) {
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
    }
  }

  &-Row {
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    @media (min-width:#{$tablet}) {
      margin-top: 1px;
      line-height: 49px;
      padding: 0 8px;
      box-shadow: 0 -1px 0 0 $black-10, 0 1px 0 0 $black-10;
    }
    &-Labels {
      @media (min-width:#{$tablet}) {
        width: 40%;
      }

      font-size: 14px;
      font-weight: bold;
      letter-spacing: 3px;
      color: $black-60;
      text-transform: uppercase;
    }
    &-TextColumn {
      margin-bottom: 16px;
      @media (min-width:#{$tablet}) {
        margin-bottom: 0;
        width: 40%;
      }
      font-size: 14px;
      letter-spacing: 0px;
      color: $black-60;
    }
    &-Edit {
      text-align: right;
      width: 20%;
      a {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        @include mobile {
          padding-top: 12px;
        }
      }

      svg {
        margin-right: 8px;
        margin-bottom: 5px;
      }

      &-Text {
        display: none;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 49px;
        color: $primary;
        text-transform: uppercase;

        &:hover {
          color: $primary-hover;
        }

        @media (min-width:#{$small-desktop}) {
          display: block;
        }
      }
    }
  }

  &-Delete {
    margin-top: 32px;
    width: 100%;
    @include button-link;
    @media (min-width:#{$tablet}) {
      width: auto;
      @include small-button-link;
    }
  }
}
