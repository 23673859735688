.hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $black-10;
  padding: 0;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.hr--spacing-bottom {
  margin-bottom: 24px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-input-error {
  color: $primary;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
}
