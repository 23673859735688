// Overriding the cookiebot styling

#CybotCookiebotDialog {
  font-family: var(--font-family-base) !important;
}

// Disable specific elements, like the cookiebot logo and the "powered by" text
#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogHeader {
  display: none !important;
}

// Button styling override
#CybotCookiebotDialogFooter {
  #CybotCookiebotDialogBodyButtonsWrapper {
    gap: 12px;
  }

  .CybotCookiebotDialogBodyButton {
    &#CybotCookiebotDialogBodyButtonAccept {
      color: $white !important;
      font-size: 14px !important;
      line-height: 19px !important;
      font-family: var(--font-family-base) !important;
      font-weight: bold !important;
      letter-spacing: 3px !important;
      text-decoration: none !important;
      padding: 16px 24px !important;
      border-radius: 4px !important;
      background-color: $primary !important;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
      text-align: center !important;
      cursor: pointer !important;
      border: none !important;
      text-transform: uppercase !important;
      width: auto !important;
      float: left !important;
    }

    &#CybotCookiebotDialogBodyButtonDecline {
      color: $primary !important;
      font-size: 14px !important;
      line-height: 19px !important;
      font-weight: bold;
      font-family: var(--font-family-base) !important;
      letter-spacing: 3px !important;
      text-decoration: none !important;
      padding: 16px 24px !important;
      border-radius: 4px !important;
      background-color: $white !important;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
      text-align: center !important;
      cursor: pointer !important;
      border: none !important;
      text-transform: uppercase !important;
      width: auto !important;
      border: none !important;
    }
  }
}

//
// Color overrides
//
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: $primary;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid $primary !important;
  color: $primary !important;
}

#CybotCookiebotDialogDetailBulkConsentLink:hover {
  color: $primary !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer > div:first-of-type:focus::-webkit-scrollbar-thumb {
  background: $primary !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer > div:first-of-type:focus {
  scrollbar-color: $primary #f2f2f2 !important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 2px solid $primary !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  border-color: $primary !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: $primary !important;
  border-color: $primary !important;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleDeselectVendorsLink,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
  border: 2px solid $primary !important;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton.select,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
  background-color: $primary !important;
  border-color: $primary !important;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
  color: $primary !important;
}

#CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: $primary !important;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane
  label:not([for='CybotCookiebotDialogBodyLevelButtonNecessary'])
  .CybotCookiebotDialogBodyLevelButtonDescription:hover {
  color: $primary !important;
}

#CybotCookiebotDialogTabContent input:focus + .CybotCookiebotDialogBodyLevelButtonSlider {
  box-shadow: 0 0 1px $primary !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABv2Tabs input[type='checkbox']:checked {
  background-color: $primary !important;
  border-color: $primary !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: $primary !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
  color: $primary !important;
}

@media screen and (min-width: 601px) {
  #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible + span,
  #CybotCookiebotDialog :focus-visible {
    outline: 2px solid $primary !important;
  }
}
