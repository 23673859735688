@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';

.AddToCartNotification {
  position: absolute;
  z-index: 102;
  top: 44px;
  right: -15px;
  text-align: left;
  width: 380px;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  display: none;

  @media (min-width: #{$tablet}) {
    display: block;
  }
}

.AddToCartNotification__Wrapper {
  position: relative;
  background-color: $white;

  &::before {
    content: '';
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 36px;
    height: 80px;
    top: -10px;
    right: 20px;
    z-index: -1;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &::after {
    top: -19px;
    right: 13px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: $white;
    border-width: 10px;
    margin-left: -18px;
  }
}

.AddToCartNotification__Container {
  padding: 32px;
}

.AddToCartNotification__Title {
  height: 25px;
  color: $black-80;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 25px;
  margin-bottom: 4px;
}

.AddToCartNotification__Description {
  color: $black-60;
  font-size: 14px;
  line-height: 21px;
}

.AddToCartNotification__Buttons {
  display: flex;
  margin-top: 24px;
}

.AddToCartNotification__ProductInfo {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.AddToCartNotification__ProductInfo-image {
  overflow: hidden;
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  flex-shrink: 0;

  img {
    max-height: 100%;
    height: auto;
  }
}

.AddToCartNotification__ProductInfo-product-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  color: $black-80;
}

.AddToCartNotification__ProductInfo-product-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  color: $black-60;
}

.CartNotification--enter {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.CartNotification--enter-from {
  opacity: 0;
}

.CartNotification--enter-to {
  opacity: 1;
}

.CartNotification--leave {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.CartNotification--leave-from {
  opacity: 1;
}

.CartNotification--leave-to {
  opacity: 0;
}
