.AvailabilityTooltip {
  position: relative;
  display: inline-flex;
}

.AvailabilityTooltip--available {
  .AvailabilityTooltip__trigger {
    background-color: rgba($alert-ok, 0.3);

    &::after {
      background-color: $alert-ok;
    }
  }
}

.AvailabilityTooltip--unavailable {
  .AvailabilityTooltip__trigger {
    background-color: rgba($black-30, 0.3);

    &::after {
      background-color: $black-30;
    }
  }
}

.AvailabilityTooltip--out-of-stock {
  .AvailabilityTooltip__trigger {
    background-color: rgba($orange, 0.3);

    &::after {
      background-color: $orange;
    }
  }
}

.AvailabilityTooltip__wrapper {
  display: inline-flex;
}

.AvailabilityTooltip__trigger {
  width: 16px;
  height: 16px;
  display: inline-block;

  background-color: rgba($alert-ok, 0.3);
  border: none;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: $alert-ok;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 4px;
  }
}

.AvailabilityTooltip__content {
  position: absolute;
  z-index: 50;
  padding: 16px;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-24px);
  box-shadow: 2px 8px 24px 0px #e5e5e5;
  background-color: $white;

  @include max-small-desktop {
    display: none;
  }

  .StockInformation__Label,
  .DeliveryTime__Value,
  .StockInformation__LimitedStockWarning {
    white-space: nowrap;

    font-weight: 400;
    color: $black;
  }

  .DeliveryTime {
    margin-bottom: 0;
  }

  .StockInformation:last-child,
  .DeliveryTime:last-child {
    margin-bottom: 0;
  }
}

.AvailabilityTooltip--enter {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.AvailabilityTooltip--enter-from {
  opacity: 0;
}

.AvailabilityTooltip--enter-to {
  opacity: 1;
}

.AvailabilityTooltip--leave {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.AvailabilityTooltip--leave-from {
  opacity: 1;
}

.AvailabilityTooltip--leave-to {
  opacity: 0;
}
