.Accessories {
  padding-bottom: 56px;

  @media (min-width:#{$small-desktop}) {
    padding-bottom: 104px;
  }

  &-Category {
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    display: inline-block;
    padding-top: 56px;
    cursor: pointer;
    padding: 16px 24px;
    outline: none;

    @include mobile {
      font-size: 12px;
      letter-spacing: 2.57px;
      line-height: 16px;
    }

    &-Active {
      color: $primary;
      border-bottom: 4px solid $primary;
      display: inline-block;
    }
  }

  &-Top {
    padding-top: 112px;
    background-size: cover;
    background-image: linear-gradient(180deg, $white 0%, rgba(255, 255, 255, 0) 100%),
      url(./../../../assets/css-svg/main-background-image.svg);

    @include mobile {
      padding-top: 48px;
    }

    @include tablet {
      padding-top: 48px;
    }

    &-Wrapper {
      margin: 0 auto;

      @include tablet {
        padding-left: 0;
      }

      @include mobile {
        padding-left: 0;
      }
    }

    &-Categories {
      padding-top: 48px;

      @include max-small-desktop {
        white-space: nowrap;
        overflow: auto;
        padding-top: 24px;
      }
    }
  }

  &-Bottom {
    &-TitleWrapper {
      display: flex;
      justify-content: space-between;

      @include max-small-desktop {
        display: block;
      }
    }

    &-BackButton {
      margin-top: 32px;
      margin-bottom: 32px;
      font-weight: bold;
      @include text-section-title-small;
      color: $primary;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block;
      padding-top: 7px;
      outline: none;

      @media (min-width: #{$small-desktop}) {
        margin-top: 80px;
      }

      @include max-small-desktop {
        padding-top: 0;
      }

      svg {
        margin-right: 16px;
        margin-bottom: -3px;

        @include tablet {
          margin-bottom: -1px;
          margin-right: 16px;
          width: 18px;
          height: 18px;
        }

        @include mobile {
          margin-bottom: -2px;
          margin-right: 8px;
        }
      }

      &:hover {
        color: $primary-hover;

        svg.sprite-icon {
          color: $primary-hover;
        }
      }
    }

    &-Title {
      color: $black-80;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 33px;
      margin-top: 80px;
      margin-bottom: 32px;
      display: inline-block;
      cursor: default;

      @include mobile {
        font-size: 18px;
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }

      &-ItemNumbers {
        color: $black-80;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 0.3px;
        line-height: 33px;

        @include mobile {
          font-size: 18px;
          letter-spacing: 0.23px;
          line-height: 25px;
        }
      }
    }

    &-ShowMore {
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block;

      svg {
        margin-right: 8px;
        margin-bottom: -3px;
      }
    }

    &-Categories {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;

      .SubCategory {
        outline: none;

        &-Wrapper {
          display: inline-block;
          width: 16.66%;
          cursor: pointer;
          padding: 0 12px;

          @include mobile {
            padding: 0 8px;
          }

          @include tablet {
            width: 33%;
            margin: 16px 0;
          }

          @include mobile {
            width: 50%;
            margin: 16px 0;
          }
        }

        &:hover {
          @media (min-width: #{$small-desktop}) {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
          }

          .SubCategory-Name {
            color: $primary;
          }
        }

        &-Image {
          padding: 16px 16px 8px 16px;
          aspect-ratio: 1.3; // Reserve the image space, as the images are loaded lazily
          overflow: hidden; // Long image alt text will generate layout shifts until the image is loaded

          img {
            width: 100%;
          }

          @include mobile {
            padding-top: 0;
          }
        }

        &-Name {
          color: $black-80;
          word-break: break-word;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 1.5px;
          line-height: 19px;
          padding: 0 16px 8px 16px;

          @include mobile {
            padding: 0;
            text-align: center;
          }
        }

        &-Articles {
          color: $black-60;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          padding: 0px 16px 8px 16px;

          @include mobile {
            padding: 0;
            text-align: center;
            line-height: 19px;
          }
        }
      }

      &.WithoutTitle {
        margin-top: 80px;

        @include mobile {
          margin-top: 32px;
        }
      }
    }
  }
}
