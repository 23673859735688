.WishlistItems {
  margin: 112px 0 152px 0;

  &-BackButton {
    display: inline-block;
    margin-bottom: 24px;

    a {
      display: inline-block;
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
    }

    svg {
      margin-right: 8px;
      margin-bottom: -3px;
    }

    &:hover {
      a,
      svg.sprite-icon {
        color: $primary-hover;
      }
    }
  }

  &-Title {
    color: $black-80;
    margin-bottom: 56px;
  }

  &-NumberContainer {
    margin-bottom: 24px;
  }

  &-Number {
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
  }

  &-ActionButtons {
    padding-top: 16px;

    @media (min-width: #{$tablet}) {
      display: flex;
      justify-content: flex-start;
      padding-top: 24px;
    }

    &-QuoteButton {
      margin-right: 0;

      @media (min-width: #{$tablet}) {
        margin-right: 16px;
      }

      a {
        display: block;
        padding: 18px 24px;
        color: $white;
        background-color: $primary;
        text-transform: uppercase;
        font-weight: bold;
        @include text-section-title-small;
        border-radius: 4px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        text-align: center;

        @media (min-width: #{$tablet}) {
          display: inline-block;
        }
      }

      &:hover {
        a {
          color: $white;
          background-color: $primary-hover;
        }
      }
    }

    &-Buttons {
      display: flex;
      justify-content: center;
      margin: 24px auto 0 auto;

      @media (min-width: #{$tablet}) {
        margin: 0;
        justify-content: flex-start;
      }
    }

    &-Share,
    &-Print,
    &-Delete,
    &-Edit {
      width: 56px;
      height: 56px;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      padding: 12px;
      cursor: pointer;
      margin-right: 16px;
      position: relative;

      &-Tooltip {
        @include tooltip-product;
      }
    }

    &-Edit {
      padding-left: 16px;

      svg {
        height: 26px;
        width: 26px;
      }

      @media (min-width: #{$tablet}) {
        &:hover {
          .WishlistItems-ActionButtons-Edit-Tooltip {
            visibility: visible;
          }
        }
      }
    }

    &-Delete {
      svg {
        width: 32px;
        height: 32px;
      }

      @media (min-width: #{$tablet}) {
        &:hover {
          .WishlistItems-ActionButtons-Delete-Tooltip {
            visibility: visible;
          }
        }
      }
    }

    &-Share {
      display: none;

      @media (min-width: #{$tablet}) {
        &:hover {
          .WishlistItems-ActionButtons-Share-Tooltip {
            visibility: visible;
          }
        }
      }
    }

    &-Print {
      @media (min-width: #{$tablet}) {
        &:hover {
          .WishlistItems-ActionButtons-Print-Tooltip {
            visibility: visible;
          }
        }
      }
    }
  }
}
