.Techspec {
  color: $black-60;
  @include text-sm;
}

.Techspec__List {
  margin: 8px 0 0 0;
}

.Techspec__Item-Name {
  display: inline;

  &::after {
    content: ':';
  }
}

.Techspec__Item-Value {
  margin-left: 4px;
  display: inline;

  &::after {
    content: '\A';
    white-space: pre;
  }
}

.Techspec--Columns {
  .Techspec__List {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px 16px;
  }

  .Techspec__Item-Name {
    color: $black;

    &::after {
      content: '';
    }
  }
}
