.AllDownloads {
  // Reusable padding distance for download items
  --download-item-padding-inline: 16px;
  --bulk-download-item-padding-left: 44px;
  @include min-tablet {
    --download-item-padding-inline: 24px;
    --bulk-download-item-padding-left: 56px;
  }

  margin: 0 auto;

  &-BackTo {
    margin: 24px 0 32px 0;

    a {
      color: $primary;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      @include text-section-title-small;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
      flex-shrink: 0;
    }

    @media (min-width:#{$small-desktop}) {
      margin: 80px 0 56px 0;
    }

    &:hover {
      a {
        color: $primary-hover;
      }

      svg {
        &.sprite-icon {
          color: $primary-hover;
        }
      }
    }
  }

  &-Header {
    margin-bottom: 16px;

    @media (min-width:#{$tablet}) {
      margin-bottom: 40px;
    }

    &-Wrapper {
      max-width: 1440px !important;
    }

    &-Title {
      color: $black-80;
      letter-spacing: normal;
      display: block;
    }

    &-ProductName {
      font-weight: 400;
      display: block;
      color: $black-80;
    }
  }

  &-Tabs-Tab {
    font-size: 12px;
    padding: 16px 24px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 19px;
    cursor: pointer;
    color: $black-60;
    text-transform: uppercase;
    position: relative;
    display: inline-block;

    @media (min-width:#{$tablet}) {
      font-size: 14px;
    }

    @media (min-width:#{$small-desktop}) {
      &:hover {
        color: $primary;
      }
    }

    &.Active {
      color: $primary;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: linear-gradient(90deg, #ec0051 0%, #d40028 100%);
      }
    }
  }

  &-Dropdowns {
    @media (min-width:#{$tablet}) {
      display: flex;
    }

    .Dropdown {
      width: 100%;

      @media (min-width:#{$tablet}) {
        width: 280px;
      }

      height: 56px;
      margin: 0 24px 0 0;

      &:first-child {
        margin-bottom: 16px;

        @media (min-width:#{$tablet}) {
          margin-bottom: 0;
        }
      }

      &-Wrapper {
        padding: 13px 40px 12px 16px;

        &-Label {
          margin: 0;
          width: auto;
          display: block;
        }
      }

      &-SelectedOption {
        margin: 0;
        display: block;
        font-weight: 600;
      }

      &-Options {
        width: 100%;
        padding-bottom: 8px;

        @media (min-width:#{$tablet}) {
          width: 280px;
        }

        .Dropdown-Search {
          width: calc(100% - 32px);
        }

        &-Item {
          font-size: 14px;
          letter-spacing: 1.75px;
          line-height: 40px;
          padding: 0 16px;
        }
      }

      &-Pointer {
        top: 24px;
      }
    }
  }

  &-Content {
    max-width: 1440px !important;
    padding-top: 32px;
    padding-bottom: 160px;
  }
}

.AllDownloads-Content-Wrapper {
  background-color: $black-3;
}

.AllDownloads-Content-Header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include min-small-desktop {
    flex-direction: row;
  }

  // Sticky behavior on desktop
  @include min-small-desktop {
    position: sticky;
    top: 70px;
    z-index: 1;
    background-color: $black-3;
    padding-block: 12px;

    .Components-StickyMenu-Padding & {
      top: 127px;
    }
  }
}

.AllDownloads-Bulk-Downloads {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid $black-10;

  @include min-small-desktop {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
  }
}

.AllDownloads-Select-All {
  margin-top: 24px;
  padding-left: 26px;
  @include text-section-title-small;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;

  @include checkbox-input-style-extended;
}
