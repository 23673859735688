.Menu-header {
  .MenuTabs {
    display: flex;
    align-items: center;
    &-tab {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      color: $black-60;
      line-height: 40px;
      padding: 0 32px;
      text-transform: uppercase;
      cursor: pointer;
      border-right: 1px solid $border;
      border-left: 1px solid $border;
      white-space: nowrap;
      &:hover {
        color: $black-80;
      }
      &.active {
        background-color: $white;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        border-bottom: 1px solid $white;
      }
    }
  }
}
