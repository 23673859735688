.SocialLink {
  display: inline-block;
  vertical-align: middle;

  @include mobile {
    display: block;
    text-align: center;
    padding-right: 8px;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  &-item {
    .GeneralLink {
      font-size: 0;
    }

    a {
      text-decoration: none;
      height: 24px;
      width: 24px;
      cursor: pointer;

      margin-right: 16px;
      display: inline-block;
    }
  }
}
