@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';

.FindADealer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include min-small-desktop {
    align-items: flex-end;
    flex-grow: 1;
    justify-content: space-between;
  }
}

.FindADealer__find-a-dealer-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 16px;
}

.FindADealer--important {
  @include min-small-desktop {
    align-items: flex-start;
  }

  .FindADealer__find-a-dealer-info {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
  }
}
