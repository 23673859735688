.MobileMenu {
  .SubMenuItems {
    .Accordion {
      box-shadow: inset 0 -1px 0 0 $black-10;

      &-Header {
        position: relative;
        padding: 19px 30px 18px 0;
        color: $black-80;
        @include text-section-title-small;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        outline: none;

        &:hover {
          color: $primary;
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 8px;
          top: 24px;
          width: 10px;
          height: 10px;
          background-image: url(./../../../assets/css-svg/chevron-down.svg);
          background-repeat: no-repeat;
        }

        &.active {
          color: $primary;

          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &-Body {
        padding-bottom: 17px;

        .aboutItems {
          font-size: 14px;
          color: $black-60;
          margin-bottom: 16px;
        }
      }
    }
  }

  .MenuTabs {
    .Accordion {
      background-color: $gray;
      box-shadow: inset 0 -1px 0 0 $black-10;

      &-Header {
        position: relative;
        cursor: pointer;
        outline: none;
        padding: 13px 40px 13px 24px;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 32px;
          top: 24px;
          width: 10px;
          height: 10px;
          background-image: url(./../../../assets/css-svg/chevron-down.svg);
          background-repeat: no-repeat;
        }

        &.active {
          &:after {
            transform: rotate(180deg);
          }
        }

        &-Company {
          color: $black-80;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1.5px;
          pointer-events: none;
          line-height: 16px;
        }

        &-Label {
          pointer-events: none;
          color: $black-80;
          font-size: 10px;
          letter-spacing: 1.43px;
          line-height: 14px;
        }
      }

      &-Body {
        padding-bottom: 24px;

        .MenuTabs {
          &-tab {
            color: $black-80;
            padding: 19px 48px 18px 48px;
            text-transform: capitalize;
            cursor: pointer;
            line-height: 19px;
            font-size: 14px;
            letter-spacing: 1.5px;

            &.active {
              font-size: 14px;
              letter-spacing: 1.5px;
              color: $black-30;
            }
          }
        }
      }
    }
  }
}

.SecondLevelAccordion {
  &:not(:last-child) {
    box-shadow: inset 0 -1px 0 0 $black-10;
  }

  &-Header {
    position: relative;
    padding: 19px 30px 18px 0;
    color: $black-80;
    font-size: 14px;
    cursor: pointer;
    line-height: 19px;
    letter-spacing: 1.5px;
    font-weight: bold;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 8px;
      top: 24px;
      width: 10px;
      height: 10px;
      background-image: url(./../../../assets/css-svg/chevron-down.svg);
      background-repeat: no-repeat;
    }

    &.active {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &-Body {
    padding-bottom: 17px;

    .aboutItems {
      font-size: 14px;
      color: $black-60;
      margin-bottom: 16px;
    }
  }
}
