.SolutionHero-HeightPlaceholder {
  width: 100%;
  z-index: -1;
  display: flex;
}

.SolutionHero {
  z-index: -1;
  position: fixed;
  width: 100%;
  overflow: hidden;

  &-Image {
    width: 100%;

    @media (min-width: #{$small-desktop}) {
      height: 100%;
      object-fit: cover;
    }
  }

  &-Headline {
    position: relative;
    width: 100%;
    font-weight: bold;

    @media (min-width: #{$small-desktop}) {
      background-image: url(./../../../assets/css-svg/Background-Rectangle.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 690px;
    }

    &-Overline {
      color: $black-60;
      @include text-title-xs;
      text-transform: uppercase;

      @media (min-width: #{$small-desktop}) {
        padding-top: 16px;
        padding-bottom: 8px;
        font-size: 18px;
        letter-spacing: 3.86px;
        line-height: 25px;
      }
    }

    &-Title {
      margin: 16px 0 0 0;
      color: $black-80;
      letter-spacing: 2px;
      font-size: 36px;
      line-height: 42px;

      @media (min-width: #{$small-desktop}) {
        margin: 0;
        font-size: 60px;
        line-height: 82px;
      }
    }

    &-Wrapper {
      padding: 32px 24px;

      @media (min-width: #{$small-desktop}) {
        padding-left: 24px;
        padding-top: 102px;
        padding-right: 130px;
        padding-bottom: 128px;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }

      @include small-desktop {
        padding: 80px 32px 80px 32px;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
}
