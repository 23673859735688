.ItemProductWishlist {
  background-color: $black-3;
  margin-bottom: 16px;
  min-height: 118px;

  @media (min-width: #{$tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px;
  }

  &-Details {
    display: flex;
    align-items: center;

    &-Image {
      width: 177px;
      height: 100%;
      max-height: 118px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-ProductInfo {
      padding-left: 16px;

      &-Title {
        color: $black-80;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }

      &-Details {
        color: $black-60;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }

  &-Actions {
    margin-top: 24px;
    display: flex;

    @media (min-width: #{$tablet}) {
      margin-top: 0;
    }

    &-Compare,
    &-Delete {
      display: inline-block;
      margin-left: 16px;
      cursor: pointer;

      &-Tooltip {
        @include tooltip-product;
      }
    }

    &-Delete svg {
      margin-top: 2px;
    }

    &-CompareWrapper {
      position: relative;

      &-CheckIcon {
        position: absolute;
        top: -8px;
        right: -6px;

        svg.sprite-icon {
          color: $alert-ok;
        }
      }

      @media (min-width: #{$small-desktop}) {
        &:hover {
          .ItemProductWishlist-Actions-Compare-Tooltip {
            visibility: visible;
          }
        }
      }
    }
  }
}
