.EventResultItem__Link {
  font-size: 15px;
  padding: 16px 24px 5px 24px;
  display: block;
  background-color: $white;
  min-height: 105px;
  text-decoration: none;
  position: relative;
  color: #004990;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;

  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);

    @media (min-width: #{$small-desktop}) {
      .EventResultItem__Link-Content-DetailsLabel {
        visibility: visible;
      }
    }
  }
}

.EventResultItem__Link-Content-Row {
  display: block;

  @media (min-width: #{$tablet}) {
    display: flex;
    flex-wrap: wrap;
  }

  &:first-child {
    margin: 0 0 20px 0;
  }
}

.EventResultItem__Link-Content-Details {
  align-items: center;
  color: $black-60;
  display: flex;
  margin: 4px 0 11px 0;
  padding: 0 20px 0 0;
  width: 100%;
  font-size: 14px;

  &:nth-child(3) {
    padding-right: 0;
  }

  @media (min-width: 800px) {
    width: calc(33.3333%);
  }

  svg {
    height: 16px;
    margin: -3px 12px 0 0;
    min-width: 16px;
    width: 16px;
  }
}

.EventResultItem__Link-Content-Title {
  color: $black-80;
  font-family: var(--font-family-base);
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 16px 0;

  @media (min-width: #{$tablet}) {
    font-size: 24px;
  }
}

.EventResultItem__Link-Content-DetailsLabel {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  visibility: hidden;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--font-family-base);
  font-weight: bold;
  @include text-section-title-small;
  color: $primary;

  svg {
    margin-left: 16px;
    margin-top: -4px;
  }
}
