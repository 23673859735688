.HeroCareer {
  margin: 0 auto;
  width: 83.33%;
  padding-top: 120px;
  margin-bottom: 40px;

  @include tablet {
    width: 100%;
    padding-top: 56px;
  }

  @include mobile {
    width: 100%;
    position: static;
    padding-top: 40px;
  }

  @include small-desktop {
    padding-top: 80px;
  }

  &-Title {
    color: $black-80;
    width: 80%;
    margin: 24px auto 56px auto;
    cursor: default;

    @include tablet {
      width: 100%;
      padding: 0 64px;
      margin: 32px auto;
    }

    @include mobile {
      width: 100%;
      margin: 16px auto 32px auto;
      padding: 0 24px;
    }
  }

  &-Button {
    width: 80%;
    margin: 0 auto;

    a {
      text-transform: uppercase;
      color: $primary;
      font-weight: bold;
      letter-spacing: 3px;
      display: block;
      color: $primary;
      font-size: 14px;
      text-decoration: none;
    }

    svg {
      margin-bottom: -3px;
      margin-right: 8px;
    }

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }

    @include tablet {
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      padding: 0 24px;
    }
  }

  &-Image {
    width: 100%;
    height: auto;
    display: block;
  }

  &-Description {
    width: 80%;
    margin: 40px auto 0 auto;
    color: $black-60;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    cursor: default;

    @include rich-text-a-style;
    @include rich-text-list-style;

    @include tablet {
      margin-top: 32px;
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      font-size: 22px;
      line-height: 30px;
      margin-top: 32px;
      padding: 0 24px;
    }
  }

  &-SocialLinkWrapper {
    width: 32px;
    float: right;
    margin-top: 80px;
    margin-bottom: 24px;

    @include tablet {
      float: none;
      width: auto;
      text-align: end;
      margin-top: 24px;
      margin-bottom: 0;
      padding-right: 24px;
    }

    @include mobile {
      float: none;
      width: auto;
      margin: 32px auto 0 auto;
      text-align: center;
    }
  }

  &-OpenPosition {
    width: 80%;
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    margin: 72px auto 0 auto;

    @include tablet {
      margin: 0;
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      margin-top: 32px;
      padding: 0 24px;
    }
  }

  &-EmailShare {
    height: 32px;
    width: 32px;
    display: inline-block;
    margin-top: 4px;

    svg {
      width: 32px;
      height: 32px;
    }

    @include tablet {
      margin: 0 4px;
    }

    @include mobile {
      margin: 0 8px;
    }

    &:hover {
      svg.sprite-icon {
        color: $black-60;
      }
    }
  }
}

.GeneralLink.HeroCareer-Button a {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }
}

.HeroCareer-Info-Row {
  width: 80%;
  display: block;
  padding-bottom: 8px;
  margin: 0 auto 40px auto;

  @media (min-width: #{$tablet}) {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e5e5;
  }

  @include tablet {
    width: 100%;
    padding: 0 64px;
  }

  @include mobile {
    width: 100%;
    padding: 0 24px;
  }
}

.HeroCareer-Info-Details {
  align-items: center;
  color: $black-60;
  display: flex;
  margin: 4px 0 16px 0;
  padding: 0 40px 0 0;
  font-size: 14px;
  line-height: 21px;

  svg {
    color: $primary;
    height: 16px;
    margin: -3px 12px 0 0;
    min-width: 16px;
    width: 16px;
  }
}
