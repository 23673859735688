.TehnicalSpecifications {
  background-image: url(./../../../assets/css-svg/main-background-image.svg);
  background-size: cover;
  width: 100%;
  padding: 56px 0;

  @include small-desktop {
    padding: 80px 0;
  }

  @include desktop {
    padding: 120px 0;
  }

  &-Wrapper {
    margin: 0 auto;

    @include mobile {
      padding: 0 !important;
    }

    @include tablet {
      padding: 0 !important;
    }
  }

  .ScopeOfDelivery {
    margin-top: 40px;

    .ScopeOfDelivery-Head {
      padding-bottom: 20px;
      text-transform: uppercase;
    }
    .ScopeOfDelivery-Row-Value {
      padding: 12px 24px;
    }
    .ScopeOfDelivery-Row-Value,
    .ScopeOfDelivery-Row-Value a {
      color: $black-60;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 28px;
      white-space: wrap;

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
      }
    }
    .ScopeOfDelivery-Row-Value a {
      word-break: keep-all;
      white-space: nowrap;
      text-decoration: none;

      &:hover {
        color: #333333;
        text-decoration: underline;
      }
    }
  }

  .GrayBackground {
    background-color: rgba(216, 216, 216, 0.3);
  }

  &-Head {
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;

    @include mobile {
      display: block;
      padding: 0 24px 32px;
    }

    @include tablet {
      padding: 0 24px 32px;
    }

    &-Systems {
      display: inline-block;
      background-color: $white;
      margin-left: auto;
      padding: 2px 4px;
      text-transform: uppercase;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      border-radius: 16px;

      @include mobile {
        margin-top: 32px;
      }

      &-Metric,
      &-Imperial {
        display: inline-block;
        padding: 5px 16px;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 2.14px;
        line-height: 14px;
        color: $black-60;
        border-radius: 13px;
        outline: none;
        cursor: pointer;
        vertical-align: 2px;

        &-Active {
          background-color: $black-80;
          color: $white;
        }
      }
    }
  }

  &-Row {
    display: flex;

    &-Name {
      word-break: break-word;
      padding: 14px 24px;
      width: 50%;
      color: $black-80;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 2.57px;
      line-height: 20px;
      text-transform: uppercase;

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
        letter-spacing: 3px;
      }

      &-Info,
      &-Info-VariantSelector {
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin-left: 8px;

        svg {
          vertical-align: middle;
          margin-bottom: 4px;
        }
      }

      &-Info {
        &:hover {
          .TehnicalSpecifications-Row-Name-Info-Tooltip {
            visibility: visible;
          }
        }

        &-Tooltip {
          @include tooltip-product;
          white-space: normal;
          bottom: 130%;
          width: 240px;
          line-height: 18px;
          font-size: 11px;
          text-align: center;
          padding: 8px 8px;

          @include mobile {
            width: 180px;
          }
        }
      }

      &-Info-VariantSelector {
        @media (min-width: #{$small-desktop}) {
          &:hover {
            .TehnicalSpecifications-Row-Name-Info-Tooltip-VariantSelector {
              visibility: visible;
            }
          }

          .TehnicalSpecifications-Row-Name-Info-Tooltip-VariantSelector {
            @include tooltip-product;
          }
        }
      }
    }

    &-Value {
      word-break: break-word;
      width: 50%;
      color: $black-60;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 48px;
      padding-right: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
      }
    }
  }
}
