.GeneralDownloadsLister {
  padding-top: 8px;

  .SearchResultList__Layout.ListLayout {
    .SearchResultList__Item {
      padding: 0;
      background-color: $white;

      &:nth-child(even) {
        background-color: $black-4;
      }

      &:empty {
        padding-top: 0;
      }
    }
  }
}

.SearchResults__Header--select-all {
  padding-top: 16px;
  padding-bottom: 16px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include min-small-desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.GeneralDownloadsLister__Select-all {
  @include min-small-desktop {
    padding-left: 24px;
  }
}

.GeneralDownloadsLister__Select-all-text {
  color: $black-60;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4286;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  transform: translateY(1px);
}

.SearchResults__Header--just-results {
  padding-bottom: 16px;
  padding-top: 0;
}

.GeneralDownloadsLister__Bulk-Downloads-Container {
  background-color: $white;
}
.GeneralDownloadsLister__Bulk-Downloads {
  display: flex;
  border-bottom: 0;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 1440px;
  padding: 8px 0 8px 24px;
  margin: 0 auto;
  justify-content: flex-end;

  @include desktop {
    padding-left: 130px;
    padding-right: 130px;
  }
}
