:root {
  --font-family-base: 'Avenir Next World', sans-serif;

  --font-size-h1: 24px;
  --font-size-h2: 24px;
  --font-size-h3: 24px;
  --font-size-h4: 18px;
  --font-size-h5: 14px;
  --font-size-h6: 12px;
  --font-size-body: 16px;
  --font-size-section-title-medium: 16px;
  --font-size-section-title-small: 14px;
  --font-size-text-sm: 14px;
  --font-size-title-xs: 12px;

  --line-height-h1: 33px;
  --line-height-h2: 25px;
  --line-height-h3: 25px;
  --line-height-h4: 19px;
  --line-height-h5: 16px;
  --line-height-h6: 16px;
  --line-height-body: 24px;
  --line-height-section-title-medium: 21px;
  --line-height-section-title-small: 19px;
  --line-height-title-xs: 16px;
  --line-height-text-sm: 1.4285;

  --letter-spacing-h1: 2px;
  --letter-spacing-h2: 1.5px;
  --letter-spacing-h3: 1.5px;
  --letter-spacing-h4: 1.5px;
  --letter-spacing-h5: 0.18px;
  --letter-spacing-h6: 0.18px;
  --letter-spacing-body: 0;
  --letter-spacing-section-title-medium: 3px;
  --letter-spacing-section-title-small: 3px;
  --letter-spacing-title-xs: 2.57px;
}

html,
body {
  font-family: var(--font-family-base);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

html[lang='ru'] {
  --font-family-base: 'Avenir Next World Cyrylic', 'Avenir Next World', sans-serif;
}

html[lang='ja'] {
  --font-family-base: 'Noto Sans JP', 'Avenir Next World', sans-serif;
}

html[lang='zh'] {
  --font-family-base: 'Noto Sans SC', 'Avenir Next World', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  word-break: break-word;
}

h1 {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  letter-spacing: var(--letter-spacing-h1);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  letter-spacing: var(--letter-spacing-h2);
}

h3 {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  letter-spacing: var(--letter-spacing-h3);
}

h4 {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
  letter-spacing: var(--letter-spacing-h4);
}

h5 {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing-h5);
}

h6 {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
  letter-spacing: var(--letter-spacing-h6);
}

@include min-tablet {
  :root {
    --font-size-h1: 36px;

    --line-height-h1: 42px;
    --line-height-h2: 33px;
    --line-height-h3: 33px;
    --line-height-h4: 25px;
    --line-height-h5: 19px;

    --letter-spacing-h2: 2px;
    --letter-spacing-h3: 2px;
    --letter-spacing-h5: 1.5px;
  }
}

@include min-small-desktop {
  :root {
    --font-size-h1: 48px;
    --font-size-h2: 36px;

    --line-height-h1: 66px;
    --line-height-h2: 42px;
  }
}

.Markdown--support-links {
  a {
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
