.MyAccountMobileMenu {
  position: fixed;
  width: 100%;
  height: calc(100vh - 70px);
  left: 0;
  top: 70px;
  text-align: left;
  padding: 24px;
  background-color: $white;
  @media (min-width:#{$small-desktop}) {
    display: none;
  }
  &-Welcome {
    @include text-title-xs;
    font-weight: bold;
    color: $black-60;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &-Link.GeneralLink {
    a {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 32px;
      color: $black-60;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: $primary-hover;
      }
    }
  }

  &-Logout {
    margin-top: 40px;
    @include text-title-xs;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $primary-hover;
    }
  }
}
