.OpenCart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    svg.sprite-icon {
      color: $primary;
    }
  }
}
