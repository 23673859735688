.CareerPromotionBox {
  background-color: $black-4;
  margin: 0 auto !important;
  padding: 56px 0;
  @media (min-width:#{$tablet}) {
    padding: 80px 0;
  }
  @include mobile {
    &-Hide {
      display: none;
    }
  }
  &-HeadlinePadding {
    padding-bottom: 32px;
  }
  &-Image {
    max-width: 32% !important;
    @include mobile {
      max-width: 100% !important;
    }
    &-Item {
      width: 100%;
      height: auto;
    }
  }

  &-Careers {
    &-Images {
      justify-content: space-between !important;
    }
    &-Wrapper {
      @media (min-width:#{$small-desktop}) {
        padding-top: 40px;
      }
      &-Item {
        background-color: $white;
        margin-bottom: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 24px !important;
        cursor: pointer;
        a {
          display: inline-block;
        }
        @include mobile {
          margin-bottom: 16px;
        }

        &-Separator {
          color: $black-60;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 2.57px;
          line-height: 16px;
          margin-bottom: 8px;
        }

        &-Title {
          color: $black-60;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 2.57px;
          line-height: 16px;
          margin-bottom: 8px;
          text-transform: uppercase;

          @include mobile {
            font-size: 10px;
          }
        }

        &-JobDescription {
          color: $black-80;
          font-weight: bold;
          font-size: 24px;
          letter-spacing: 2px;
          line-height: 33px;

          @include mobile {
            font-size: 18px;
            display: inline-block;
            line-height: 25px;
            letter-spacing: 1.5px;
          }
        }
      }
    }

    &-ButtonWrapper {
      &-ButtonLink {
        display: inline-block;
        margin-top: 16px;
        a {
          @include small-button-link;
        }
      }
    }
  }
}
// for Experince Editor
.GeneralLink.CareerPromotionBox-Careers-ButtonWrapper-ButtonLink a {
  color: $white;
}
.GeneralLink.CareerPromotionBox-Careers-ButtonWrapper-ButtonLink .scTextWrapper.scEnabledChrome {
  display: none;
}
