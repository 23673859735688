.ThreeColumnLayout-Item {
  margin-bottom: 24px;
  padding: 0 12px;
  &:hover {
    cursor: pointer;
    .ThreeColumnLayout-Item-Title {
      color: $primary;
    }
  }
  a {
    display: flex;
    .ThreeColumnLayout-Item-Image {
      flex-shrink: 0;
      flex-grow: 0;
      display: inline-block;
      width: 80px;
      height: 80px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &-Placeholder {
        background-color: $black-4;
      }
      @include desktop {
        width: 100px;
        height: 100px;
        margin-right: 24px;
      }
    }
    .ThreeColumnLayout-Item-Content {
      &-Title {
        color: $black-80;
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      &-Description {
        color: $black-60;
        font-size: 14px;
        line-height: 21px;
        max-width: 280px;
      }
    }
  }
}
