.text-input {
  border-radius: 4px;
  padding: 0 16px;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  height: 56px;
  position: relative;
}

.text-input__input {
  appearance: none;
  border: none;
  color: $black-80;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  width: 100%;
  padding-top: 28px;
}

.text-input__label {
  position: absolute;
  color: $black-80;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding-top: 20px;
}

.text-input__label--active {
  padding-top: 10px;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.43px;
  line-height: 12px;
}
