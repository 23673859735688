.SitecoreForm {
  padding-bottom: 80px;
  @media (min-width: #{$tablet}) {
    margin-left: -10px;
  }
  input,
  select,
  textarea,
  span,
  p {
    width: 100%;
  }
  .ProductLink,
  .ContactInfoEmail,
  .ContactInfoEmailOriginal,
  .ContactInfoDetails,
  .HiddenField,
  .JobInformation,
  .DeviceInformation {
    display: none;
  }
  .Form-FileUploadLabel {
    color: $primary;
    font-weight: bold;
    letter-spacing: 2.57px;
    padding: 12px 16px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-bottom: 16px;
    margin-top: 16px;
    position: static;

    &:hover {
      color: $primary-hover;
    }
  }
  .Form-FileUpload {
    display: none;
    box-shadow: inset 0 -1px 0 0 $black-10;
    padding-bottom: 20px;
    position: relative;
    @media (min-width: #{$tablet}) {
      margin-left: 10px;
    }
  }

  .FormFileUploadClose {
    display: none;
    position: absolute;
    font-size: 30px;
    color: $black-60;
    width: auto;
    right: 16px;
    bottom: 12px;
    transform: rotate(45deg);
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
  .LoadMoreUploadInput {
    display: inline-block;
    margin-bottom: 24px;
    margin-top: 16px;
    p {
      border-radius: 4px;
      background-color: $primary;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      border: none;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 16px;
      color: $white;
      padding: 12px 16px;
      text-transform: uppercase;
      cursor: pointer;
      margin: 0;

      @media (min-width: #{$tablet}) {
        margin-left: 10px;
      }

      &:hover {
        background-color: $primary-hover;
      }
    }
  }

  input[type='file']::-webkit-file-upload-button {
    visibility: hidden;
  }
  input[type='file']::file-selector-button {
    visibility: hidden;
  }
  input[type='file']::-ms-browse {
    display: none;
  }
  input[type='file']::-moz-file-upload-button {
    visibility: hidden;
  }
  input[type='file']::-ms-file-upload-button {
    visibility: hidden;
    display: none;
  }
  input[type='file'] {
    display: none;
    box-shadow: none;
    height: auto;
    color: $black-60;
    font-weight: bold;
    @include text-title-xs;
    text-transform: uppercase;
    border-radius: unset;
    margin-left: -80px;
    padding: 0;
  }

  .form-errors {
    padding: 0;

    @media (min-width: #{$tablet}) {
      padding: 0 10px;
    }

    a {
      color: #d40028;
    }

    .invalid {
      color: $black-60;
      font-style: italic;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.35px;
      line-height: 22px;
      padding: 8px 24px;
      background-color: rgba(238, 0, 0, 0.1);

      &:last-of-type {
        margin-bottom: 24px;
        @media (min-width: #{$tablet}) {
          margin-bottom: 40px;
        }
      }
    }
  }
  .invalid {
    padding-top: 3px;
    color: $primary;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
    p {
      margin: 0;
    }
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0 0 8px 0;
    @media (min-width: #{$tablet}) {
      padding: 0 0 24px 0;
    }
  }

  textarea {
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -webkit-appearance: none;
    appearance: none;
    border: none;
    height: 100%;
    resize: none;
    padding: 20px 16px;
    font-weight: 600;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
    padding: 0 16px;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: none;
    height: 56px;
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
  }

  label {
    position: absolute;
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
    padding-left: 16px;
    padding-top: 20px;
    padding-right: 24px;

    &.active {
      padding-top: 10px;
      font-weight: 400;
      font-size: 10px;
      letter-spacing: 1.43px;
      line-height: 12px;
    }
  }

  select {
    height: 56px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: none;
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
    padding: 0 13px;
    background-color: $white !important;

    option {
      font-size: 12px;
      letter-spacing: 1.5px;
      font-weight: 600;
      padding: 12px 16px;
    }
  }

  .dropdown-list {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;

    > select {
      box-shadow: none;
      padding: 0 2px; /* This visually fixes the position on desktop, has no influence on iOS devices (iOS applies 6px) */
    }
  }

  button {
    border-radius: 4px;
    background-color: $primary;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: none;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 16px;
    color: $white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 24px;
    padding-left: 24px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 32px;

    &:hover {
      background-color: $primary-hover;
    }
  }
}

div.Form {
  &-Text {
    color: $black-80;
    font-weight: 400;
    @include text-body;
    display: block;
    padding: 0;
    margin-bottom: 16px;

    @include rich-text-a-style;

    a {
      text-decoration: underline;
    }

    @media (min-width: #{$tablet}) {
      padding: 0 10px;
    }

    &-Bold {
      color: $black-80;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
    }
  }

  &-DropDown {
    &-100 {
      width: 100%;
      display: block;
      padding: 0 0 16px 0;
      @media (min-width: #{$tablet}) {
        padding: 0 10px 16px 10px;
      }
      label {
        pointer-events: none;
      }
    }

    &-50 {
      width: 100%;
      vertical-align: top;
      display: inline-block;
      padding: 0 0 16px 0;
      @media (min-width: #{$tablet}) {
        padding: 0 10px 16px 10px;
        width: 50%;
      }
      label {
        pointer-events: none;
      }
    }
  }

  &-TextArea {
    &-100 {
      width: 100%;
      display: block;
      height: 160px;
      padding: 0 0 16px 0;
      @media (min-width: #{$tablet}) {
        padding: 0 10px 16px 10px;
      }
    }
  }

  &-Input {
    &-100 {
      width: 100%;
      display: block;
      padding: 0 0 16px 0;
      @media (min-width: #{$tablet}) {
        padding: 0 10px 16px 10px;
      }
    }
    &-50 {
      width: 100%;
      @media (min-width: #{$tablet}) {
        width: 50%;
      }
    }

    &-33 {
      width: 65%;
      @media (min-width: #{$tablet}) {
        width: 35%;
      }
    }

    &-one-third {
      width: 35%;
    }

    &-two-thirds {
      width: 65%;
    }

    &-15 {
      width: 35%;
      vertical-align: top;
      display: inline-block;
      padding: 0 20px 16px 0;
      @media (min-width: #{$tablet}) {
        padding: 0 10px 16px 10px;
        width: 15%;
      }
      &-Right {
        width: 35%;
        vertical-align: top;
        display: inline-block;
        padding: 0 0 16px 20px;
        @media (min-width: #{$tablet}) {
          padding: 0 10px 16px 10px;
          width: 15%;
        }
      }
    }

    &-25 {
      width: 100%;
      @media (min-width: #{$tablet}) {
        width: 25%;
      }
    }
    &-50,
    &-33,
    &-25 {
      vertical-align: top;
      display: inline-block;
      padding: 0 0 16px 0;
      @media (min-width: #{$tablet}) {
        padding: 0 10px 16px 10px;
      }
    }
  }
  &-CheckboxSection {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: calc(100% - 20px);
      left: 10px;
      border-bottom: 1px solid $black-10;
      @include mobile {
        display: none;
      }
    }
    @include mobile {
      border-bottom: 1px solid $black-10;
      position: static;
    }
    fieldset {
      padding: 0 0 16px 0;
    }
  }

  &-CheckBox {
    padding: 0;

    @media (min-width: #{$tablet}) {
      padding: 0 10px;
    }

    label {
      display: block;
      position: relative;
      padding: 0 0 16px 24px;
      &.active {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 16px;
        padding: 0 0 16px 24px;
        display: inline-block;
      }
    }

    @include checkbox-input-style;
    @include rich-text-a-style;
  }

  &-Button {
    padding: 0;
    @media (min-width: #{$tablet}) {
      padding: 0 10px;
    }

    button:disabled {
      background-color: $primary-30 !important;
      cursor: default;
    }
  }

  &-Link {
    padding: 0 0 16px 0;
    @media (min-width: #{$tablet}) {
      padding: 0 10px 16px 10px;
    }
  }

  &-Radio {
    padding: 0 0 16px;

    @media (min-width: #{$tablet}) {
      padding: 0 10px 16px;
    }
    label {
      position: relative;
      padding: 0 0 16px 24px;
      margin-right: 24px;
      @media (min-width: #{$tablet}) {
        margin-right: 40px;
      }
      &:first-of-type {
        padding: 0;
        margin: 0;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    @include radio-input-style;
  }
}

.SitecoreForm_V2 {
  button {
    background-color: $primary;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: none;
    cursor: pointer;
    color: $white;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 16px;
    padding: 20px 21px 20px 24px;
    text-transform: uppercase;

    &:hover {
      background-color: $primary-hover;
    }
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: none;
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    height: 56px;
    letter-spacing: 1.5px;
    line-height: 16px;
    padding: 0 16px;

    &:focus + label,
    &.hasValue + label {
      padding-top: 10px;
      font-weight: 400;
      font-size: 10px;
      letter-spacing: 1.43px;
      line-height: 12px;
    }
  }

  label {
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
    padding: 20px 24px 24px 16px;
    position: absolute;
  }

  .invalid {
    color: $primary;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;

    p {
      margin: 0;
    }
  }
}
