.CustomerButton {
  width: 100%;
  background-image: linear-gradient(180deg, $white 0%, rgba(255, 255, 255, 0) 100%),
    url(./../../../assets/css-svg/main-background-image.svg);
  background-size: cover;

  &-Wrapper {
    margin: 0 auto !important;
    padding-bottom: 88px !important;
    padding-top: 42px !important;

    @include tablet {
      padding: 32px 40px 88px 40px !important;
    }
  }

  &-Title {
    color: $black-80;
    margin-bottom: 32px;
    cursor: default;

    &.WhiteColorStyle {
      color: $white;
    }
  }

  &-RemarkText {
    color: $black-60;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1.2px;
    padding-bottom: 64px;
    cursor: default;

    @include rich-text-a-style;
    @include rich-text-list-style;

    &.WithoutRemarkText {
      display: none;
    }

    &.WhiteColorStyle {
      color: $white;
    }
  }

  &-Overline {
    color: $black-60;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    padding-top: 88px;
    padding-bottom: 16px;

    &.WhiteColorStyle {
      color: $white;
    }
  }

  &-ButtonDisable {
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 36px;
    background-color: $primary-30;
    color: $white;

    &-Text {
      display: inline-block;
      padding: 18px 24px;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;

      @include max-small-desktop {
        text-align: center;
        padding: 12px 16px;
        font-size: 12px;
      }
    }
  }

  .GeneralLink {
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 36px;
    cursor: pointer;

    a {
      padding: 18px 24px;
      background-color: $primary;
      display: inline-block;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      color: $white;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;

      @include max-small-desktop {
        text-align: center;
        padding: 12px 16px;
        font-size: 12px;
      }
    }

    &:hover a {
      background-color: $primary-hover;
    }
  }
}
