.BackToLink {
  display: flex;
  align-items: center;
  padding: 40px 100px 0 100px;
  img {
    width: 16px;
    margin-right: 16px;
    height: auto;
  }
  a {
    color: $primary;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
  }
}
