.ImageFacet {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding-bottom: 20px;
  text-align: left;
  border-bottom: none;
  position: relative;
  max-width: 1440px;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  gap: 2px;

  @include min-tablet {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include desktop {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.ImageFacet__Container {
  width: 100%;
  background-color: #fff;
}

.ImageFacet__Item {
  color: #67768b;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: normal;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  word-break: break-word;
  margin-bottom: 20px;
  height: auto;
  cursor: pointer;

  @include min-tablet {
    width: calc(33.33% - 2px);
  }

  @include desktop {
    width: calc(25% - 2px);
  }

  &::before {
    content: '';
    display: inline-block;
    width: 56px;
    height: 56px;
    background-color: #f4f4f4;
    margin-right: 16px;
    flex-shrink: 0;
  }
}

.ImageFacet__Item--Selected {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);

  .ImageFacet__Caption {
    color: #d40028;
  }
}

.ImageFacet__Label {
  cursor: pointer;

  input[type='radio'] {
    display: none;
  }
}

.ImageFacet__Image {
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ImageFacet__Caption {
  display: block;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 19px;
  color: #676767;
  padding-right: 24px;
  margin: 0;
  height: auto;
  text-transform: capitalize;
}

.ImageFacet__Results-Count {
  text-transform: none;
  display: block;
  line-height: 20px;
  color: #676767;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
