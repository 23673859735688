.select {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  position: relative;

  select {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    padding: 0 2px; /* This visually fixes the position on desktop, has no influence on iOS devices (iOS applies 6px) */
    border: none;
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
    padding: 0 13px;
    background-color: $white !important;

    option {
      font-size: 12px;
      letter-spacing: 1.5px;
      font-weight: 600;
      padding: 12px 16px;
    }
  }
}

.select__label {
  position: absolute;
  color: $black-80;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding-left: 16px;
  padding-top: 20px;
  padding-right: 24px;
  pointer-events: none;
}

.select__label--active {
  padding-top: 10px;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.43px;
  line-height: 12px;
}
