.LightboxVideo {
  position: fixed;
  z-index: 101;
  top: 0px;
  left: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);

  &-Details {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 88px;

    @include tablet {
      margin-bottom: 24px;
    }

    @include mobile {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &-Name {
      width: 80%;
      color: $white;
      font-weight: bold;
      font-size: 36px;
      letter-spacing: 2px;
      line-height: 49px;
      display: inline-block;
      padding-left: 24px;
      cursor: default;

      @include tablet {
        font-size: 24px;
        line-height: 33px;
      }

      @include mobile {
        font-size: 19px;
        line-height: 22px;
      }
    }

    &-Close {
      width: 20%;
      text-align: right;
      float: right;
      line-height: 49px;

      @include max-small-desktop {
        line-height: 24px;
      }

      &-Button {
        display: inline-block;
        padding-right: 24px;
        cursor: pointer;
        outline: none;

        svg {
          color: $white;
        }

        @include mobile {
          padding-right: 0;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  &-Video {
    video {
      max-width: 100%;
      outline: none;
      display: block;
      margin: 0 auto;
      max-height: 80vh;

      @media (min-width: #{$small-desktop}) {
        max-height: 600px;
      }
    }
  }
}
