.CategoryPipeDate {
  .pipe-bar {
    margin: 0 16px 0 16px;
    display: inline-block;
  }
  .category {
    color: $black-60;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
  }
  .creationDate {
    color: $black-80;
    font-size: 14px;
    letter-spacing: 3px;
  }
}
