.Modal__OpenCart {
  // Reset button styling
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  height: auto;
  margin-left: 24px;
}
