.NoResults__Text {
  color: #333;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 2px;

  @include min-tablet {
    font-size: 36px;
    line-height: 42px;
  }

  @include min-small-desktop {
    width: 66.66%;
    line-height: 66px;
    font-size: 48px;
  }
}

.NoResults__Query {
  color: #333;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 2px;
  text-transform: uppercase;

  @include min-tablet {
    font-size: 36px;
    line-height: 42px;
  }

  @include min-small-desktop {
    line-height: 66px;
    font-size: 48px;
  }
}
