.HeroActionButton {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 56px;

  @include mobile {
    display: block;
  }

  @media (max-width:#{$small-desktop}) {
    margin-top: 24px;
    padding-bottom: 0;
  }

  &-ButtonWrapper {
    margin-right: 16px;

    .sprite-icon {
      color: $white;
    }

    @media (min-width: #{$small-desktop}) and (max-width: 1120px) {
      margin-right: 8px;
    }

    @include mobile {
      margin-right: 0;
    }
  }

  &-Button {
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;

    a {
      padding: 19px 24px 18px 24px;
      background-color: $primary;
      display: inline-flex;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      color: $white;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
      gap: 16px;

      @include mobile {
        display: flex;
        justify-content: center;
      }
    }

    &:hover a {
      background-color: $primary-hover;
      color: $white;
    }

    @include tablet {
      text-align: center;
    }

    @include mobile {
      display: block;
      text-align: center;
    }
  }

  &-Disable {
    color: $white;
    font-weight: bold;
    @include text-section-title-small;
    padding: 19px 24px 18px 24px;
    background-color: $primary-30;
    display: inline-flex;
    gap: 16px;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: default;
    position: relative;

    &:hover {
      .HeroActionButton-Disable-Tooltip {
        visibility: visible;
      }
    }

    @include tablet {
      display: flex;
      justify-content: center;
    }

    @include mobile {
      display: flex;
      justify-content: center;
    }

    &-Tooltip {
      @include tooltip-product;
    }
  }

  &-IconsWrapper {
    display: flex;
    padding-top: 32px;
    border-top: 1px solid $black-10;

    @include mobile {
      justify-content: center;
      margin-top: 24px;
    }
  }

  &-Icon {
    width: 56px;
    height: 56px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    padding: 12px;
    cursor: pointer;
    margin: 0 8px;
    outline: none;

    &:first-child {
      margin-left: 0;
    }

    &.HeroActionButton-Print {
      margin: 0;
    }
  }

  &-Compare {
    position: relative;

    &:hover {
      svg.sprite-icon {
        color: $primary-hover;
      }

      .HeroActionButton-CompareLabel {
        visibility: visible;
      }
    }

    &-Wrapper {
      position: relative;
    }

    &-CheckIcon {
      position: absolute;
      display: inline-block;
      top: -6px;
      right: 2px;

      svg.sprite-icon {
        color: $alert-ok;
      }
    }
  }

  &-CompareLabel,
  &-PrintLabel,
  &-WishlistLabel {
    @include tooltip-product;
  }

  &-Wishlist {
    &:hover {
      svg.sprite-icon {
        color: $primary-hover;
      }

      .HeroActionButton-WishlistLabel {
        visibility: visible;
      }
    }

    &-Disable {
      cursor: default;

      svg.sprite-icon {
        color: $primary-30;
      }

      &:hover {
        svg.sprite-icon {
          color: $primary-30;
        }
      }
    }
  }

  &-Print {
    position: relative;

    &:hover {
      .HeroActionButton-PrintLabel {
        visibility: visible;
      }

      svg.sprite-icon {
        color: $primary-hover;
      }
    }

    &-Download {
      display: inline-block;
      margin: 0 8px;
    }
  }

  &-ShareWrapper,
  &-WishlistWrapper {
    position: relative;
  }

  &-Share {
    &:hover {
      .HeroActionButton-Share-Icon.sprite-icon {
        color: $primary-hover;
      }

      .HeroActionButton-ShareLabel {
        visibility: visible;
      }
    }
  }

  &-ShareLabel {
    @include tooltip-product;
  }

  &-ShareBox {
    @media (min-width: #{$tablet}) {
      @include tooltip-product;
      background-color: $black-80;
      z-index: 5;
      padding: 24px;
      opacity: 1;
      bottom: 125%;

      &::after {
        border-color: $black-80 transparent transparent transparent;
        border-width: 9px;
        margin-left: -9px;

        @media (min-width: #{$small-desktop}) and (max-width: 1330px) {
          left: 83%;
        }
      }
    }

    @media (min-width: #{$small-desktop}) and (max-width: 1330px) {
      left: 0;
      transform: translateX(-70%);
    }

    @include mobile {
      position: fixed;
      width: 100%;
      visibility: hidden;
      background-color: $white;
      padding: 24px 0;
      z-index: 105;
      top: 0;
      right: 0;
      height: 100vh;
      max-height: 100vh;
      overflow: auto;
    }

    &-Active {
      visibility: visible;
    }

    &-Heading {
      display: flex;
      justify-content: space-between;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 19px;
      text-transform: uppercase;
      padding-bottom: 16px;
      cursor: default;

      @include mobile {
        color: $black-80;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
        letter-spacing: 1.5px;
      }

      svg {
        margin-left: 48px;
        margin-top: 3px;
        width: 12px;
        height: 12px;
        cursor: pointer;

        &.sprite-icon {
          color: $white;

          @include mobile {
            color: $black-80;
          }
        }
      }

      svg:hover {
        &.sprite-icon {
          color: $black-30;
        }
      }
    }
  }

  &-SocialIconsWrapper {
    text-align: left;
  }

  &-ShareLink {
    margin-right: 8px;
    height: 32px;
    width: 32px;
    display: inline-block;

    a {
      display: block;
    }

    @include mobile {
      margin-right: 0;
      display: block;
      border-top: 1px solid $black-10;
      width: auto;
      height: auto;
      padding: 16px 24px;
      text-decoration: none;
      color: $black-60;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;

      a {
        display: flex;
        align-items: center;

        &:hover {
          color: $black-60;
        }
      }
    }

    svg {
      height: 40px;
      width: 40px;

      @include mobile {
        margin-right: 16px;
      }

      @media (min-width: #{$tablet}) {
        height: 32px;
        width: 32px;

        &.sprite-icon {
          color: $white;
        }
      }
    }

    &:hover {
      .sprite-icon {
        color: $black-30;
      }
    }
  }
}

.GeneralLink.HeroActionButton-Button a {
  color: $white;
}
