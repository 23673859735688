.ThreeColumnCategory {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 56px;
  padding-top: 56px;

  @include tablet {
    margin-bottom: 24px;
    margin-top: 24px;
    padding-bottom: 32px;
    padding-top: 32px;
  }

  @media (min-width: #{$small-desktop}) {
    padding-top: 56px;
    padding-bottom: 56px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  &-Container {
    padding-top: 0;
    @media (min-width: #{$tablet}) {
      padding-top: 24px;
    }
  }
}
