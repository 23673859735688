.click_content_block_promotion_video {
  padding-top: 16px;
  padding-bottom: 16px;
}

.VideoPromotionBox {
  position: relative;
  cursor: pointer;
  //use font size 0 to remove image padding
  font-size: 0;
  overflow: hidden;
  min-height: 320px;
  max-height: 600px;
  outline: none;
  background-color: $black;

  @include tablet {
    min-height: 256px;
  }

  @include mobile {
    min-height: 128px;
  }

  @media (min-width: #{$small-desktop}) {
    max-width: 1440px;
    margin: 0 auto;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }

    svg.sprite-icon {
      color: $primary-hover;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 180px;
    opacity: 0.72;
    transform-origin: 0 0;
    transition: transform 1s, visibility 1s ease-in;
  }

  &-Play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    @include tablet {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &-Content {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 64px;

    @include mobile {
      bottom: 16px;
    }

    @include tablet {
      bottom: 40px;
    }

    @include small-desktop {
      max-width: 896px;
    }

    &-Rectangle {
      height: 15px;
      width: 40px;
      box-shadow: inset 0 4px 0 0 $primary;
    }

    &-Category {
      color: $white;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 3.86px;
      line-height: 25px;
      padding-top: 8px;

      @include mobile {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2.57px;
        padding-top: 0;
      }
    }

    &-Title {
      color: $white;
      font-weight: bold;
      font-size: 36px;
      letter-spacing: 2px;
      line-height: 49px;

      @include mobile {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 1.5px;
      }
    }
  }

  &-Overlay {
    position: fixed;
    z-index: 101;
    top: 0px;
    left: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);

    &-Details {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 88px;

      @include tablet {
        margin-bottom: 24px;
      }

      @include mobile {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      &-Name {
        width: 80%;
        color: $white;
        font-weight: bold;
        font-size: 36px;
        letter-spacing: 2px;
        line-height: 49px;
        display: inline-block;
        text-transform: uppercase;

        @include tablet {
          font-size: 24px;
          line-height: 33px;
        }

        @include mobile {
          font-size: 19px;
          line-height: 22px;
        }
      }

      &-Close {
        width: 20%;
        text-align: right;
        cursor: pointer;
        float: right;
        line-height: 49px;

        @include tablet {
          line-height: 33px;
        }

        @include mobile {
          line-height: 22px;
        }

        &-Button {
          display: inline-block;
          padding-right: 24px;
          cursor: pointer;
          outline: none;

          svg.sprite-icon {
            color: $white;
          }

          @include mobile {
            padding-right: 0;

            svg {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    &-Video {
      @media (min-width: #{$small-desktop}) {
        max-height: 600px;
      }

      video {
        outline: none;
        max-width: 100%;
        margin: 0 auto;
        display: block;

        @media (max-width: #{$small-desktop}) {
          max-height: 80vh;
        }
      }
    }
  }
}
