.StoreLink {
  margin-right: 8px;
  display: inline-block;
  cursor: pointer;
  width: 32px;
  height: 32px;
  &-Link {
    position: relative;
    a {
      display: inline-block;
      font-size: 0;
    }
    width: 32px;
    height: 32px;
    display: inline-block;
  }
  &-Icon {
    width: 32px;
    height: 32px;
  }
}
