@mixin text-body {
  font-size: var(--font-size-body);
  letter-spacing: var(--letter-spacing-body);
  line-height: var(--line-height-body);
}

@mixin text-section-title-medium {
  font-size: var(--font-size-section-title-medium);
  letter-spacing: var(--letter-spacing-section-title-medium);
  line-height: var(--line-height-section-title-medium);
}

@mixin text-section-title-small {
  font-size: var(--font-size-section-title-small);
  letter-spacing: var(--letter-spacing-section-title-small);
  line-height: var(--line-height-section-title-small);
}

@mixin text-title-xs {
  font-size: var(--font-size-title-xs);
  letter-spacing: var(--letter-spacing-title-xs);
  line-height: var(--line-height-title-xs);
}

@mixin text-sm {
  font-size: var(--font-size-text-sm);
  line-height: var(--line-height-text-sm);
}
