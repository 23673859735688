.Footer {
  background-color: $white;
  border-top: 1px solid $border;
  &-Bottom {
    padding: 0 0 24px 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    @media (min-width: #{$small-desktop}) {
      max-width: 1920px;
      margin: 0 auto;
    }
    @include small-desktop {
      padding: 0 0 21px 40px;
    }
    @include tablet {
      padding: 0 0 12px 24px;
    }
    @include mobile {
      display: block;
      padding: 0 0 48px 24px;
    }
    &-Group {
      &-Label {
        padding-left: 0;
        margin: 0;
        &:after {
          content: '';
          display: table;
          clear: both;
        }
        @media (min-width: #{$tablet}) {
          display: flex;
          align-items: center;
          padding-left: 24px;
          margin-left: auto;
        }
      }
      &-Copyright {
        @include mobile {
          margin-right: 24px;
          box-shadow: inset 0 1px 0 0 $border;
        }
      }
    }
  }

  &-Top {
    padding: 76px 130px 90px 130px;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    @include small-desktop {
      padding: 80px 40px;
    }
    @include tablet {
      padding: 80px 24px;
      display: flex;
      flex-wrap: wrap;
    }
    @include mobile {
      padding: 32px 24px 0 24px;
    }

    @media (min-width: #{$small-desktop}) {
      max-width: 1920px;
      margin: 0 auto;
    }
  }

  &-Social {
    display: inline-block;
    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .SocialLink .SocialLink-item .GeneralLink {
      .scTextWrapper.scEnabledChrome {
        display: none;
      }
    }
  }
}
