.CoveoProductCarousel {
  margin: 0 auto;
  padding: 56px 0;
  overflow: hidden;

  @include small-desktop {
    padding: 80px 0;
  }

  @include desktop {
    padding: 80px 0;
  }

  &.NoTitle {
    padding-top: 0;

    @include small-desktop {
      padding-top: 0;
    }

    @include desktop {
      padding-top: 0;
    }
  }

  &-Headline {
    margin: 0 auto;
    padding: 0 12px 16px 12px;

    @media (min-width: #{$small-desktop}) {
      padding: 0 12px 16px 12px;
    }
  }

  &-Description {
    @include text-body;
    padding-left: 12px;
    padding-right: 24px;
    color: $black-80;
    @include rich-text-a-style;
    @include rich-text-list-style;
  }

  &-ProductGroup {
    font-weight: bold;
    color: $black-80;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 33px;
    padding: 40px 24px 0 12px;

    @media (min-width: #{$small-desktop}) {
      padding-top: 64px;
      padding-bottom: 18px;
    }

    &.NoTitle {
      padding-top: 0;

      @media (min-width: #{$small-desktop}) {
        padding-top: 0;
      }
    }

    &-Number {
      font-weight: 400;
      letter-spacing: 0.3px;
      padding-left: 12px;
    }
  }

  &-Card {
    padding: 8px 24px 24px 24px;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 12px;

    @media (min-width: #{$small-desktop}) {
      box-shadow: none;
      padding: 24px 24px 32px 24px;
    }

    &-Image {
      width: 124px;
      height: 124px;
      display: flex;
      align-items: center;
      margin: 0 auto;

      @media (min-width: #{$small-desktop}) {
        width: 200px;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 1000ms ease-in;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &-Content {
      &-Title {
        margin-top: 8px;
        color: $black-80;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 19px;
        cursor: pointer;

        &:hover {
          color: $primary-hover;
        }

        @media (min-width: #{$small-desktop}) {
          font-size: 18px;
          letter-spacing: 2px;
          line-height: 25px;
        }
      }

      &-Description {
        color: $black-60;
        font-size: 14px;
        line-height: 21px;
        margin: 8px 0 10px;
      }

      &-CompareBoxWrapper {
        width: 20px;
        height: 6px;
        position: relative;

        .CompareBox {
          @media (min-width: #{$small-desktop}) {
            left: -16px;
            transform: translateX(0);

            &::after {
              left: 24px;
            }
          }
        }
      }

      &-Compare {
        @include checkbox-input-style;
        display: inline-block;
        position: relative;
        padding: 0 0 16px 24px;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 16px;
        cursor: pointer;
        color: $black-80;

        &:hover {
          .checkmark {
            border-color: $primary;
          }
        }
      }
    }
  }

  &-DesktopContainer {
    display: flex;

    .CoveoProductCarousel-Card {
      width: 33.33%;
    }
  }

  &-TabletContainer {
    display: flex;
    padding-right: 12px;

    .CoveoProductCarousel-Card {
      width: 50%;
    }
  }

  &-MobileContainer {
    padding-right: 12px;

    .CoveoProductCarousel-Card {
      padding: 0 12px;
    }
  }
}

.CoveoProductCarousel.WhiteColorStyle {
  .CoveoProductCarousel-Card-Content-Category,
  .CoveoProductCarousel-Card-Content-Title {
    color: $white;
  }

  .objectSlider-arrowRight,
  .objectSlider-arrowLeft {
    svg {
      &.sprite-icon {
        color: $white;
      }
    }
  }

  .alice-carousel__dots-item {
    border: 2px solid $white;
  }

  .alice-carousel__dots-item.__active {
    border: 2px solid $primary;
  }
}
