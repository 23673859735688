.FooterDetailsColumn {
  width: 280px;
  float: right;
  @include tablet {
    display: flex;
    width: 100%;
  }
  @include mobile {
    float: none;
    width: 100%;
    padding-bottom: 20px;
  }

  &-address {
    @include tablet {
      width: 50%;
    }
    &-title {
      color: $black-80;
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      letter-spacing: 1.5px;
    }

    &-text {
      color: $black-80;
      @include text-body;
      margin-top: 16px;
      margin-bottom: 30px;
      @include tablet {
        margin-bottom: 0px;
      }
      @include mobile {
        margin-top: 14.46px;
        margin-bottom: 24px;
      }
    }
  }
  &-details {
    @include tablet {
      width: 50%;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $primary;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 3px;
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 0;
      }
      &-img {
        max-width: 16px;
        margin-right: 15px;
      }
    }
  }

  .IconLink {
    margin-bottom: 16px;
    white-space: nowrap;

    a {
      margin-bottom: 0;
      font-weight: unset;
      font-weight: bold;
    }

    img {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      margin-top: -3px;
    }

    &:hover {
      a {
        color: $primary-hover;
      }
    }
  }
}
