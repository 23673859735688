.ChangeQuantityButton {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  max-width: 36px;
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.ChangeQuantityButton--minus {
  margin-left: auto;
}
