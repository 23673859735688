.Dropdown {
  width: 320px;
  height: 40px;
  margin: 15px auto;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  -webkit-appearance: none;
  border-radius: 4px;
  background-color: $white;
  position: relative;
  @include mobile {
    width: 272px;
  }

  &-Active {
    .Dropdown-Options {
      display: block;
    }
    .Dropdown-Pointer {
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }
  }
  &-Wrapper {
    cursor: pointer;
    padding-top: 4px;
    outline: none;
    height: 100%;

    &-Label {
      height: 14px;
      width: 56px;
      color: $black-80;
      font-weight: 400;
      font-size: 9px;
      vertical-align: 4px;
      letter-spacing: 1.43px;
      line-height: 12px;
      text-transform: uppercase;
      margin-left: 15px;
    }
  }

  &-SelectedOption {
    display: block;
    color: $black-80;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 16px;
    margin-left: 15px;
    margin-top: -5px;
  }

  &-Pointer {
    border: solid $black-80;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: all 0.3s;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &-Options {
    display: none;
    height: auto;
    box-shadow: 0 -6px 0 $white, 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 5;
    width: 320px;
    background-color: $white;
    top: 100%;
    border-radius: 4px;
    padding-bottom: 16px;
    @include mobile {
      width: 272px;
    }
    &-Item {
      outline: none;
      padding: 12px 16px;
      font-weight: 600;
      cursor: pointer;
      color: $black-80;
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 16px;

      &:hover {
        background-color: $black-3;
      }

      &.Active {
        color: $primary;
      }

      &.Input {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          width: 85%;
          height: 32px;
          color: $black-80;
          font-size: 12px;
          letter-spacing: 1.5px;
          line-height: 16px;
          padding-left: 16px;
          border-radius: 4px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
          -webkit-appearance: none;
          border: none;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  &-Search {
    height: 40px;
    width: 280px;
    border: 1px solid $black-10;
    border-radius: 4px;
    position: relative;
    margin: 10px auto;
    @include mobile {
      width: 232px;
    }
    &-Input {
      height: 100%;
      width: 70%;
      border: none;
      border-radius: 5px;
      padding-left: 10px;
      color: $black-60;
      font-weight: 400;
      font-size: 12px;

      &:focus {
        outline: none;
      }
    }

    &-Icon {
      color: $black-60;
      position: absolute;
      right: 10px;
      top: 10px;

      svg {
        font-size: 15px;
      }
    }
  }
}

/* custom scrollbar*/

.simplebar-track.simplebar-vertical {
  width: 8px;
  margin-right: 5px;
}

.simplebar-scrollbar:before {
  background-color: $black-30;
}
