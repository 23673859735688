@import '../../../../assets/colors.scss';

.PreLaunch__Badge {
  background-color: $pre-launch;
  padding: 4px 8px;
  color: $black-80;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  display: inline-block;
  line-height: 1.333333;
  margin-bottom: 8px;
  margin-right: auto;
}
