.FooterLabel {
  color: $white;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 33px;
  background-color: $primary;
  display: inline-block;
  padding: 10px 24px 10px 24px;
  margin-left: 24px;

  @include desktop {
    width: 410px;
  }
  @include small-desktop {
    width: 324px;
  }
  @include tablet {
    width: 272px;
  }
  @include mobile {
    float: right;
    margin-top: 40px;
  }
}
