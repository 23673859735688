.PageDescriptionTeaser {
  margin-bottom: 64px;
  @include content-padding;
  &-headline {
    color: $primary;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 3.86px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &-description {
    color: $black;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    @include rich-text-a-style;
    @include rich-text-list-style;
  }
}
