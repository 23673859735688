.SelectVariantMobile {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 16px 20px;
  position: relative;
  border-bottom: 1px solid $black-10;
}

.SelectVariantMobile--Selected {
  background-color: $black-3;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: $primary;
  }
}

.SelectVariantMobile__selected {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.333333;
  letter-spacing: 0.15em;
  color: $black-60;
  text-align: right;
  margin-top: 8px;
}

.SelectVariantMobile__article-nr {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.7143;
  letter-spacing: 0.15em;
  color: $black;
}

.SelectVariantMobile__button {
  padding: 0;
  align-self: flex-end;
  margin-top: 12px;
}

.SelectVariantMobile__tags {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.SelectVariantMobile__price-details {
  display: flex;
  gap: 2px;
  margin-left: 4px;
  flex-wrap: wrap;
}

.SelectVariantMobile__price {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4286;
  color: $black-60;
}

.SelectVariantMobile__price--promo {
  color: $primary;
}

.SelectVariantMobile__price--compare-at.stroken-through {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4286;
}

.SelectVariantMobile__label {
  display: inline;
  color: $black;
  @include text-sm;
}

.SelectVariantMobile__availability-and-price {
  margin-top: 2px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px 16px;

  .AvailabilityTooltip {
    align-items: center;
    margin-left: 4px;
  }
}

.SelectVariantMobile__availability-and-price + .SelectVariantMobile__tags {
  margin-top: 0;
}
