.Menu {
  // margin-bottom: 40px;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 100;
  .visible-from-lg {
    transition: all 2s ease-out;
    opacity: 0;
    height: 0;
  }
  .visible-until-md {
    transition: all 2s ease-out;

    opacity: 1;
    height: auto;
  }
  @media (min-width: $small-desktop) {
    .visible-from-lg {
      opacity: 1;
      height: auto;
    }
    .visible-until-md {
      opacity: 0;
      height: 0;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &-header {
    max-width: 1920px;
    margin: 0 auto;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }

    &-wrapper {
      border-bottom: 1px solid $border;
      background-color: $black-4;
    }
  }

  &-Content {
    text-align: center;
    position: relative;
    height: 70px;
    padding: 0 32px 0 32px;
    background-color: $white;
    z-index: 10;
    max-width: 1920px;
    margin: 0 auto;
    @include tablet {
      padding: 0 24px;
      border-bottom: 1px solid #e5e5e5;
    }
    @include mobile {
      padding: 0 24px;
      border-bottom: 1px solid #e5e5e5;
    }
    &-Logo {
      display: block;
      position: absolute;
      left: 24px;
      top: 18px;
      @include desktop {
        left: 32px;
      }

      @include small-desktop {
        left: 32px;
      }
    }

    .IconsMenu {
      display: flex;
      position: absolute;
      align-items: center;
      right: 24px;
      top: 23px;
      @include desktop {
        right: 32px;
      }
    }

    &-Wrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    &-Sticky {
      border-bottom: 1px solid $border;
      background-color: $white;
    }
  }
  .Breadcrumb-Container {
    max-width: 1920px;
    margin: 0 auto !important;
    display: flex;
    background-color: $white;
    &-Row {
      width: 70%;
      &-Account {
        width: 30%;
      }
    }
  }

  &-Global-Searchbox {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-out;
    &.Active {
      height: 88px;
      overflow: visible;
    }
    .coveo-for-sitecore-search-box-container {
      max-width: 100% !important;
      height: 88px;
      background-color: $black;

      @include component-container;
      .CoveoSearchbox {
        max-width: 1660px;
        margin: 0 auto;
        padding-top: 16px;
        .magic-box {
          margin-right: 16px;
          border: none;

          .magic-box-input {
            height: 56px;

            .magic-box-underlay {
              height: 56px;
              font-family: var(--font-family-base);
            }

            input {
              color: $black-80;
              font-family: var(--font-family-base);
              font-weight: 600;
              font-size: 12px;
              letter-spacing: 1.5px;
              line-height: 16px;
              height: 56px;
              padding: 16px 49px 16px 12px;
            }
          }
        }
        .CoveoSearchButton {
          height: 56px;
          background-color: $primary;
          border-radius: 4px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
          border: 0;

          .coveo-search-button {
            .coveo-search-button-svg {
              color: $white;
            }
          }
        }
      }
    }

    @media (max-width:#{$tablet}) {
      .coveo-for-sitecore-search-box-container {
        .CoveoSearchbox {
          padding-top: 24px;
          margin-right: 0;
          .magic-box {
            margin-right: 0;
            .magic-box-input {
              height: 40px;
              .magic-box-clear {
                height: 38px;
                line-height: 38px;
              }
              .magic-box-underlay {
                height: 40px;
              }
              input {
                height: 40px;
              }
            }
          }
        }
      }
      .CoveoSearchButton {
        display: none !important;
      }
    }
    .magic-box-suggestions {
      width: 100vw;
      left: -24px !important;
      top: calc(100% + 15px) !important;
      padding: 32px 24px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      border-bottom: 1px solid $black-10;
      min-height: 316px;
      overflow: auto !important;
      max-height: 70vh;

      @include desktop {
        padding: 40px 130px !important;
        left: -130px !important;
      }

      @include small-desktop {
        padding: 32px !important;
        left: -32px !important;
      }

      .coveo-magicbox-suggestions {
        border: none !important;
        .coveo-top-field-suggestion-header .coveo-caption {
          color: $black-60;
          font-weight: bold;
          font-family: var(--font-family-base);
          @include text-section-title-small;
        }

        & > .coveo-omnibox-selectable {
          color: $black-80;
          font-weight: 400;
          letter-spacing: 0;
          font-size: 14px;
          font-family: var(--font-family-base);
          line-height: 32px;
          width: 100%;
          border: none !important;

          @media (min-width:#{$tablet}) {
            width: 50%;
            font-size: 16px;
            line-height: 24px;
          }
          &:hover {
            background-color: none;
            background: none;
            color: $primary;
            .coveo-omnibox-hightlight {
              color: $primary;
            }
          }
        }

        .coveo-result-list-container {
          width: 100%;

          @media (min-width:#{$tablet}) {
            width: 50%;
            height: 248px;
            overflow: hidden;
            top: 24px;
            right: 0;
            padding-left: 60px;
            padding-bottom: 24px;
            position: absolute;

            &::before {
              content: '';
              display: table;
              position: absolute;
              left: 0;
              top: 24px;
              width: 1px;
              height: 220px;
              background-color: $black-10;
            }
          }
        }
      }
    }
  }
}
