.PersonHolderBox {
  // we use !important to overide inline styles from react grid system
  background: linear-gradient(to left, $black-3 81%, transparent 0);
  align-items: center !important;

  @include mobile {
    background-color: $black-3;
  }

  @include tablet {
    background: linear-gradient(to right, $black-3 94%, transparent 0);
  }

  &-Container {
    padding-top: 72px;

    @include small-desktop {
      padding-right: 32px;
    }

    @include mobile {
      padding-top: 24px;
    }

    @include tablet {
      padding-left: 24px;
      padding-top: 56px;
    }
  }

  &-Image {
    width: 100%;
    height: auto;

    &-Wrapper {
      margin-top: 80px;
      margin-bottom: 80px;

      @include mobile {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }
  }

  &-TextContent.Tablet {
    img {
      position: absolute;
      top: -40px;
      width: 50%;
      right: 0;
    }
  }

  &-TextContent {
    max-width: 460px;
    margin-left: 40px;

    @include tablet {
      max-width: 50%;
    }

    @include mobile {
      margin-left: 24px;
      margin-right: 24px;
    }

    &-FirstName,
    &-LastName {
      color: $black-80;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 33px;
      padding-bottom: 8px;
      display: inline-block;
    }

    &-Title {
      height: 19px;
      color: $black-60;
      font-weight: bold;
      @include text-section-title-small;
      margin-bottom: 16px;
      text-transform: uppercase;

      @include tablet {
        margin-top: 32px;
        max-width: 85%;
      }

      @include mobile {
        font-size: 12px;
        letter-spacing: 2.57px;
        line-height: 16px;
      }
    }

    &-Name {
      color: $black-80;
      font-weight: bold;
      font-size: 36px;
      letter-spacing: 2px;
      line-height: 42px;
      margin-bottom: 16px;

      @include tablet {
        height: 42px;
        color: $black-80;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 33px;
        margin-bottom: 16px;
      }

      @include mobile {
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
        margin-bottom: 0;
      }
    }

    &-Description {
      color: $black-80;
      font-weight: 400;
      @include text-body;
      margin-bottom: 24px;
      @include rich-text-a-style;
      @include rich-text-list-style;

      @include tablet {
        max-width: 85%;
        margin-bottom: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 21px;
        margin-top: 3px;
        color: $black-60;
        margin-bottom: 32px;
      }
    }

    &-Telephone {
      height: 19px;
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      margin-bottom: 16px;
      margin-right: 24px;

      @include mobile {
        display: inline-flex;
        height: auto;
        margin-bottom: 40px;
      }

      a {
        color: $primary;
        text-decoration: none;
        display: flex;
      }

      &-Text {
        @include mobile {
          display: none;
        }
      }

      &-Icon {
        @include mobile {
          width: 56px;
          height: 56px;
          background-color: $primary;
          border-radius: 100%;
          line-height: 69px;
          text-align: center;
        }

        svg {
          margin-right: 8px;
          margin-top: 2px;

          @include mobile {
            width: 24px;
            height: 24px;
            margin-right: 0;
            margin-top: 0;

            &.sprite-icon {
              color: $white; // TODO: what is this?
            }
          }
        }
      }
    }

    &-Email {
      height: 19px;
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;
      margin-bottom: 16px;

      a {
        color: $primary;
        text-decoration: none;
        display: flex;

        &:hover {
          color: $primary-hover;
        }
      }

      @include mobile {
        display: inline-flex;
        height: auto;
      }

      @include tablet {
        margin-bottom: 36px;
      }

      svg {
        margin-right: 8px;
        margin-top: 1px;

        @include mobile {
          width: 24px;
          height: 24px;
          margin-right: 0;
          margin-top: 0;

          &.sprite-icon {
            color: $white;
          }
        }
      }

      &-Text {
        @include mobile {
          display: none;
        }
      }

      &-Icon {
        @include mobile {
          width: 56px;
          height: 56px;
          background-color: $primary;
          border-radius: 100%;
          line-height: 69px;
          text-align: center;
        }
      }
    }

    &-Button {
      @include text-section-title-small;
      display: inline-block;
      padding: 16px 24px;
      color: $white;
      font-weight: bold;
      text-decoration: none;
      border-radius: 4px;
      background-color: $primary;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;

      @include mobile {
        margin-bottom: 40px;
      }

      @include tablet {
        margin-bottom: 24px;
      }

      &:hover {
        background-color: $primary-hover;
      }
    }
  }
}

//used for content editor
.scEnabledChrome a.PersonHolderBox-TextContent-Button {
  color: $white;
}

.PersonHolderBox-TextContent-Button .scTextWrapper.scEnabledChrome {
  display: none;
}
