// Carousel css
.CardsCarousel {
  margin: 0 auto;
  position: relative;

  &-Overlay {
    position: absolute;
    right: -1px;
    top: 12px;
    height: calc(100% - 24px);
    width: 10%;
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

    @media (min-width: #{$small-desktop}) {
      display: none;
    }
  }

  &-ArrowLeft,
  &-ArrowRight {
    z-index: 2;
    position: absolute;
    top: 40%;
    cursor: pointer;

    &.ArrowDisabled {
      opacity: 0.28;
      display: none;

      @media (min-width: #{$small-desktop}) {
        display: block;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      color: $black-60;

      @include small-desktop {
        width: 20px;
        height: 20px;
      }

      @include desktop {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-ArrowLeft {
    left: -6px;

    @include tablet {
      left: -6px;
    }

    @include small-desktop {
      left: -12px;
    }

    @include desktop {
      left: -64px;
    }
  }

  &-ArrowRight {
    right: 8px;

    @include tablet {
      right: 8px;
    }

    @include small-desktop {
      right: -12px;
    }

    @include desktop {
      right: -64px;
    }
  }

  .alice-carousel__play-btn-wrapper {
    display: none;
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none;
  }

  .alice-carousel__dots {
    display: none;
    background: none;

    @media (min-width: #{$small-desktop}) {
      display: block;
    }

    &-item {
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 2px solid #676767;
      opacity: 0.97;

      &:hover {
        background: none;
        border-color: $primary;
      }

      &.__active {
        background: none;
        box-sizing: border-box;
        height: 18px;
        width: 18px;
        border: 2px solid $primary;
        opacity: 0.97;
        position: relative;

        &:after {
          content: '';
          width: 6px;
          height: 6px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $primary;
          border-radius: 50%;
        }
      }
    }
  }

  // Its much better to define the final size here on the stage level, not on the items
  // The items width get calculated by the carousel plugin
  .alice-carousel__stage {
    @include mobile {
      width: 90% !important;
    }

    @include tablet {
      width: 45% !important;
    }
  }

  .alice-carousel__stage {
    // Allow stage-items to be same height
    display: flex;
  }

  // Because of further nesting the content, we need some more fixes on every item
  .alice-carousel__stage-item {
    display: flex; // allows us to have same height
    height: auto; // allows us to have same height
    flex: 0 0 auto; // fixes width of the cards

    // If there is not enough content, we need to make sure the card is at least 100% width
    > * {
      width: 100%;
    }
  }
}

.WhiteColorStyle {
  .CardsCarousel {
    &-ArrowLeft,
    &-ArrowRight {
      svg {
        color: $white;
      }
    }

    .alice-carousel__dots-item {
      border: 2px solid $white;
    }

    .alice-carousel__dots-item.__active {
      border: 2px solid $primary;
    }
  }
}
