.SalesAndServicePointsFilterHeader {
  cursor: pointer;
  padding-left: 32px;
  padding-bottom: 32px;
  outline: none;

  &-FilterIcon {
    svg {
      width: 16px;
      height: 16px;
      margin-top: 1px;
    }
  }

  &-Label {
    color: $primary;
    font-weight: bold;
    @include text-section-title-small;
    padding-left: 8px;
    vertical-align: 2px;
  }

  &-Chevron {
    vertical-align: -1px;
    padding-left: 12px;

    svg {
      width: 8px;
      height: 8px;
      vertical-align: top;
      margin-top: 4.5px;
      color: $primary;
    }
  }

  &:hover {
    .SalesAndServicePointsFilterHeader-Label {
      color: $primary-hover;
    }

    .SalesAndServicePointsFilterHeader-Chevron svg,
    .SalesAndServicePointsFilterHeader-FilterIcon svg {
      color: $primary-hover;
    }
  }
}
