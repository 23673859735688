.MyAccountHeadline {
  @include component-container;
  padding-top: 16px;
  @media (min-width: #{$small-desktop}) {
    padding-top: 114px;
  }
  &-Overline {
    @include text-section-title-small;
    font-weight: bold;
    text-transform: uppercase;
    color: $black-60;
    margin-bottom: 16px;

    @media (min-width: #{$small-desktop}) {
      font-size: 18px;
      letter-spacing: 3.86px;
      line-height: 25px;
    }
  }
  &-Title {
    color: $black-80;
    font-weight: bold;
  }
}
