.ProductCard {
  height: 388px;
  width: 380px;
  background-color: $white;

  &-header {
    display: flex;
    margin-left: 24px;
    padding-top: 24px;

    &-noLabel {
      height: 23px;
      width: 40px;
      visibility: hidden;
    }
    &-label {
      height: 23px;
      width: 40px;
      background-color: $primary;
      display: block;
      span {
        display: inline-block;
        height: 14px;
        width: 40px;
        color: $white;
        font-weight: 400;
        font-size: 10px;
        letter-spacing: 2.12px;
        line-height: 14px;
        text-align: center;
      }
    }

    &-image {
      height: 198px;
      width: 198px;
      margin-left: 18px;
    }
  }

  &-details {
    margin-top: 11px;
    margin-left: 24px;

    &-title {
      text-transform: uppercase;
      height: 25px;
      color: $black-80;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: 25px;
    }

    &-description {
      height: 63px;
      width: 332px;
      color: $black-60;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &-bottom {
    margin-top: 17px;
    margin-left: 24px;
    display: block;

    &-price {
      float: left;
      height: 19px;
      color: $black-80;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 19px;
    }

    &-checkbox {
      margin-right: 15.5px;
      float: right;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      label {
        height: 16px;
        width: 68.7px;
        color: $black-80;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.5px;
        line-height: 16px;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
      }
    }
  }
}
