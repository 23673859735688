.History {
  padding-top: 32px;
  padding-bottom: 56px;
  cursor: default;
  color: $black-80;
  &.WhiteColorStyle {
    color: $white;
  }
  &-Container {
    width: 66.66%;
    margin: 0 auto;
    padding-bottom: 32px;

    @include tablet {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include mobile {
      width: 100%;
      margin-top: 0;
    }
  }
}
