.fieldset {
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  legend {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.015em;
    color: $black-80;
  }
}
