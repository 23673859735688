.PageLinker {
  padding-top: 12px;
  padding-bottom: 12px;

  @media (min-width: #{$small-desktop}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: #{$small-desktop}) {
    min-height: 280px !important;
  }

  &-Right {
    text-align: right;
  }

  &-Left {
    .PageLinker-TextContent {
      text-align: right;
    }

    .PageLinker-TextContent-Link {
      justify-content: flex-end;
    }
  }

  &-NOPadding {
    &-Right {
      padding-right: 0 !important;
    }
  }

  &-Image {
    overflow: hidden;

    a {
      display: flex;
      align-items: flex-start;

      img {
        height: auto;
        width: 100%;
        transition: transform 1.5s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &-Link {
      display: block;

      img {
        height: auto;
        width: 100%;
        transition: transform 1.5s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  &-TextContent {
    background-image: url(./../../../assets/css-svg/main-background-image.svg);
    padding: 32px 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $white;

    @media (min-width: #{$small-desktop}) {
      padding: 32px 40px;
    }

    &-Category {
      text-transform: uppercase;
      color: $black-60;
      font-weight: bold;
      @include text-section-title-small;
      margin-bottom: 8px;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    &-Title {
      color: $black-80;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 33px;
      margin-bottom: 16px;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    &-Description {
      overflow-wrap: break-word;
      word-break: break-word;
      color: $black-80;
      font-weight: 400;
      @include text-body;
      margin-bottom: 34px;
      @include rich-text-a-style;
      @include rich-text-list-style;
    }

    &-Link {
      display: flex;

      &.GeneralLink a {
        overflow-wrap: break-word;
        word-break: break-word;
        color: $primary;
        font-weight: bold;
        @include text-section-title-small;
        text-decoration: none;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        &:hover {
          color: $primary-hover;

          svg {
            color: $primary-hover;
          }
        }
      }

      svg {
        width: 14px;
        height: 14px;
        margin-left: 16px;
        margin-top: 2px;
      }
    }
  }
}
