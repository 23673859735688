.ProductTitle {
  color: $black-80;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 2px;
  margin: 0;
  @media (min-width: #{$small-desktop}) {
    font-size: 36px;
    line-height: 42px;
  }
}
