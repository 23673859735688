.TwoColumnContentBlock {
  width: 100%;
  color: $black-80;
  padding: 8px 0 48px 0;
  @include tablet {
    padding: 32px 0 72px;
  }
  @include small-desktop {
    padding: 72px 0 152px 0;
  }
  @include desktop {
    padding: 72px 0;
  }
  &.WhiteColorStyle {
    color: $white;
  }
  &-Wrapper {
    @include tablet {
      padding: 0 40px;
    }
  }

  &-Column {
    padding-right: 0 !important;

    @include max-small-desktop {
      padding: 0 !important;
    }
  }

  &-Title {
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 33px;
    padding-bottom: 16px;
    cursor: default;

    @include tablet {
      padding-top: 40px;
    }

    @include mobile {
      padding-top: 40px;
      font-size: 18px;
      letter-spacing: 1.5px;
    }
  }

  &-Description {
    font-weight: 400;
    @include text-body;
    word-break: break-word;
    @include rich-text-a-style;
    @include rich-text-list-style;
    cursor: default;
    p {
      margin: 0;
    }
  }
}
