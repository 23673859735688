.EventMap {
  display: none;
  width: 100%;

  @include min-small-desktop {
    display: block;
    height: 400px !important;
  }

  .EventMarker-Title {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 19px;
    margin-block: 5px;
  }

  .EventMarker-Location {
    color: #333333;
    font-size: 10px;
    letter-spacing: 2.14px;
    line-height: 14px;
  }

  .EventMarker-Address {
    color: #676767;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .EventMarker-Address-MultiLine {
    white-space: pre-wrap;
  }
}
