.RichTextTeaser {
  margin: 0 auto;
  @include text-body;
  color: $black-80;
  padding-bottom: 8px;

  &.WhiteColorStyle {
    color: $white;
  }

  @media (min-width: #{$small-desktop}) {
    &-Text {
      margin: 0 auto;
      width: 66.66%;
    }
  }
  @include tablet {
    &-Text {
      padding: 0 40px;
    }
  }

  @include rich-text-list-style;
  @include rich-text-a-style;

  p {
    padding-top: 0;
    padding-bottom: 16px;
    margin: 0;
  }

  h2 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .RichTextWithCheckmarks-Item {
    p:first-child {
      margin: 0;
    }
    position: relative;
    .RichTextWithCheckmarks-Text {
      display: inline-block;
      margin-left: 52px;
      margin-top: 8px;
    }
    svg {
      position: absolute;
      left: 16px;
      top: 12px;
    }
  }
}
