.Menu-Content {
  .SubMenuItems {
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }

    @media (min-width: #{$small-desktop}) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item {
      display: inline-block;
      padding: 26px 0;
      margin-left: 44px;
      letter-spacing: 3px;
      color: $black-80;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;
      font-weight: bold;
      outline: none;

      @include small-desktop {
        margin-left: 24px;
        letter-spacing: 1px;
        font-size: 13px;
        height: 70px;
      }
      @include desktop {
        height: 70px;
      }
      &.active {
        color: $primary;
        border-bottom: 3px solid $primary;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}
