.SalesAndServicePointsMap {
  width: 100%;
  height: 100%;

  @include tablet {
    height: 180px !important;
  }

  @include mobile {
    height: 180px !important;
  }

  &-LocationInput {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 180px;
    height: 56px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 12px;
    outline: none;
    text-overflow: ellipses;
    position: absolute;
    top: 96px;
    left: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;

    &::placeholder {
      color: $black-80;
      opacity: 1;
    }

    @include mobile {
      top: 16px;
      height: 48px;
    }
  }
}
