.MobileMenu {
  position: relative;
  z-index: 99;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: $white;
  overflow: auto;
  .MenuInfo {
    padding: 0 24px 50px 24px;
    &-item {
      color: $black-60;
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1px;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
  .SubMenuItems {
    padding: 0 24px;
    margin-bottom: 45px;
    &-item {
      padding: 18px 20px 18px 0;
      letter-spacing: 3px;
      color: $black-80;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      box-shadow: inset 0 -1px 0 0 $black-10;
      &.active {
        color: $primary;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}

.IconsMenu-menu {
  margin-left: 24px;
  margin-bottom: 2px;
  display: inline-block;
  cursor: pointer;
  outline: none;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  &-Close {
    margin-left: 24px;
    padding-top: 3px;
    width: 24px;
    height: 24px;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
}

.small-gray-placeholder {
  width: 24px;
  height: 24px;
  background-color: #999;
  cursor: pointer;
}
