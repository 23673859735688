.UniversalStoryLinkerLR {
  padding-bottom: 24px;
  min-height: 332px;
  position: relative;

  @include mobile {
    margin-bottom: 0px;
    height: auto;
    padding: 0 !important;
  }

  @include tablet {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.UniversalStoryLinkerLR__Wrapper-Aside,
.UniversalStoryLinkerLR__ImageWrapper {
  padding: 0 !important;
}
.UniversalStoryLinkerLR__ImageWrapper a {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.UniversalStoryLinkerLR__Aside {
  background-color: $black-3;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  p {
    @include text-body;
    color: $black-60;
    margin: 0;
    padding: 4px 56px 0 50px;
    cursor: default;

    @include max-small-desktop {
      font-size: 14px;
      padding: 4px 24px 0 24px;
    }
  }
}

.UniversalStoryLinkerLR__Image {
  width: 100%;
  min-height: 332px;
  object-fit: cover;
  transform-origin: 0 0;
  transition: transform 1s, visibility 1s ease-in;
  .StoryBox:hover & {
    transform: scale(1.05);
  }
  @include mobile {
    height: auto;
    min-height: auto;
  }
}

.UniversalStoryLinkerLR__Title {
  color: $black-80;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 33px;
  margin: 0;
  padding: 24px 56px 0 50px;

  @include max-small-desktop {
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 25px;
    padding: 16px 24px 0 24px;
  }

  a {
    color: $black-80;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 33px;
    padding: 0;

    @include max-small-desktop {
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
      padding: 0;
    }
  }
}

.UniversalStoryLinkerLR__Subcategory {
  color: $black-60;
  font-weight: bold;
  @include text-title-xs;
  text-transform: uppercase;
  padding-left: 50px;
  padding-right: 40px;

  cursor: default;
  @include tablet {
    padding-left: 24px;
    padding-right: 40px;
  }

  @include mobile {
    padding-right: 24px;
    padding-top: 32px;
    padding-left: 24px;
  }
}

.UniversalStoryLinkerLR__Button {
  padding-top: 24px;
  padding-left: 50px;

  @include tablet {
    padding-left: 24px;
  }

  @include mobile {
    padding-bottom: 32px;
    padding-left: 24px;
  }
}

.UniversalStoryLinkerLR__Button-Link {
  display: inline-block;
  border-radius: 4px;

  a {
    background-color: $primary;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    color: $white;
    cursor: pointer;
    display: inline-block;
    @include text-title-xs;
    font-weight: bold;
    padding: 12px 16px;
    text-transform: uppercase;
    text-decoration: none;

    &:before {
      bottom: 0;
      content: '';
      left: 0;
      right: 0;
      position: absolute;
      top: 0;
    }
  }

  &:hover a {
    background-color: $primary-hover;
    color: $white;
  }
}

.GeneralLink.UniversalStoryLinkerLR__Button-Link {
  a {
    color: $white;
  }
}

.container .UniversalStoryLinkerLR::nth-last-of-type(1) {
  padding-bottom: 500px;
}
