.ProductDetailsHero {
  padding: 56px 0;
  @media (min-width: #{$small-desktop}) {
    padding: 80px 0;
  }
  &-Row {
    margin: 0 auto !important;
    &-Left {
      &-Gallery {
        @media (min-width: #{$small-desktop}) {
          width: 80%;
        }
      }
      @include mobile {
        padding-bottom: 40px;
      }
      @include tablet {
        padding-bottom: 40px;
      }
    }
  }
}
