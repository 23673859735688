.tag {
  display: inline-block;
  padding: 4px 8px 4px 8px;
  height: 24px;

  font-family: var(--font-family-base);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.002em;
  text-align: center;
}
.tag--primary {
  background-color: $primary;
  color: #ffffff;
}
.tag--secondary {
  background-color: $black-10;
  color: $black-80;
}
.tag--ok {
  background-color: $alert-ok-10;
  color: $alert-ok;
}
.tag--warning {
  background-color: #FFF7E7;
  color: #FFAF10;
}