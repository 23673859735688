.ExpandedFormWrapper {
  min-height: 24px;
  &-Container {
    width: 66.66%;
    // margin: 0 auto;
    min-height: 24px;

    @include max-small-desktop {
      width: 100%;
    }

    @include tablet {
      padding: 0 40px;
    }
  }
}
