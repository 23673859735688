.HistoryItem {
  box-shadow: 0 1px 0 0 $black-10;
  padding: 16px 0;
  display: flex;
  &:first-child {
    box-shadow: 0 -1px 0 0 $black-10, 0 1px 0 0 $black-10;
  }
  @include mobile {
    display: block;
  }
  &-Title {
    display: inline-block;
    width: 180px;
    padding-left: 24px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 24px;
    word-break: break-word;

    @include rich-text-a-style;
    @include rich-text-list-style;

    p {
      margin: 0;
    }

    @include mobile {
      display: block;
      padding-bottom: 8px;
      width: auto;
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  &-Description {
    display: inline-block;
    width: 65%;
    padding-left: 24px;
    font-weight: 400;
    @include text-body;
    @include rich-text-a-style;
    @include rich-text-list-style;
    .RichTextWithCheckmarks-Item {
      p:first-child {
        margin: 0;
      }
      position: relative;
      .RichTextWithCheckmarks-Text {
        display: inline-block;
        margin-left: 52px;
        margin-top: 8px;
      }
      svg {
        position: absolute;
        left: 16px;
        top: 12px;
      }
    }
    p {
      margin: 0;
    }

    @include mobile {
      display: block;
      width: auto;
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}
