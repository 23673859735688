.Benefits {
  &-Wrapper {
    margin-top: 64px;
  }

  background-color: $white;
  width: 100%;
  min-height: 180px;

  @include desktop {
    //!important is needed to overide react grid inline values
    margin: 0 auto 80px auto !important;
  }

  @include small-desktop {
    //!important is needed to overide react grid inline values
    margin: 0 auto 80px auto !important;
  }

  @include mobile {
    padding-bottom: 32px;
  }

  @include tablet {
    padding-bottom: 32px;
  }

  @media (min-width: 961px) and (max-width: 1160px) {
    max-width: 1000px;
  }

  &-Headline {
    padding-bottom: 32px;
    padding-top: 56px;

    @include desktop {
      padding-bottom: 0;
      margin: 0 auto 56px auto;
      padding-top: 110px;
    }

    @include small-desktop {
      padding-bottom: 0;
      margin: 0 auto 56px auto;
      padding-top: 110px;
    }
  }

  &-Right {
    text-align: right;
  }

  &-Left {
    .Benefits-TextContent {
      left: 0;
      text-align: right;

      &-Link {
        justify-content: flex-end;
      }

      &-Rectangle {
        margin: 0 0 0 auto;
      }
    }
  }

  &-NOPadding {
    &-Right {
      padding-right: 0 !important;
    }
  }

  &-Image {
    aspect-ratio: 1.775; // Reserve the image space, as the images are loaded lazily
    display: inline-block;
    overflow: hidden;
    text-align: center;

    @media (min-width: 1160px) and (max-width: 1460px) {
      padding: 0 0 0 16px !important;

      &.Benefits-Right {
        padding: 0 16px 0 0 !important;
      }
    }

    @media (min-width: 960px) and (max-width: 1020px) {
      padding: 0 0 0 16px !important;

      &.Benefits-Right {
        padding: 0 16px 0 0 !important;
      }
    }

    &-Item {
      transition: transform 1.5s ease;
      height: auto;
      max-width: 100%;
      object-fit: cover;

      &:hover {
        transform: scale(1.05);
      }
    }

    img {
      vertical-align: top;
    }
  }

  &-TextContent {
    display: inline-block;
    padding-top: 32px;
    bottom: 0;
    right: 0;
    cursor: default;

    @media (min-width: 961px) and (max-width: 1160px) {
      width: auto;
    }

    @include desktop {
      position: absolute;
    }

    @include small-desktop {
      position: absolute;
    }

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }

    &-Rectangle {
      height: 16px;
      width: 40px;
      box-shadow: inset 0 4px 0 0 $primary;
    }

    &-Category {
      @include text-title-xs;
      margin-top: 0;
      margin-bottom: 8px;
      text-transform: uppercase;
      color: $black-60;
      font-weight: bold;

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 19px;
        margin-top: 8px;
      }
    }

    &-Title {
      color: $black-80;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 33px;
      margin-bottom: 16px;

      @include mobile {
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }

      @include tablet {
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }

      @include small-desktop {
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }
    }

    &-Description {
      color: $black-80;
      font-weight: 400;
      @include text-body;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: 100%;
      }

      @media (min-width: 961px) and (max-width: 1160px) {
        width: auto;
      }
    }

    &-Link {
      color: $primary;
      font-weight: bold;
      @include text-section-title-small;
      text-decoration: none;
      margin-bottom: 32px;
      display: flex;

      &:hover {
        color: $primary-hover;

        svg.sprite-icon {
          color: $primary-hover;
        }
      }

      svg {
        width: 14px;
        height: 14px;
        margin-left: 16px;
        margin-top: 2px;
      }
    }
  }
}
