.DownloadsLister {
  .FacetManager {
    background-color: #f9f9f9;
    padding-top: 40px;
    border-top: 1px solid #e5e5e5;
  }

  .SearchResults__Header {
    padding-top: 32px;
  }

  @media (min-width: #{$tablet}) {
    .SearchResults__Header {
      padding-top: 16px;
    }
  }
}
