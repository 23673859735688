.LineItemsMobile__Item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid $black-10;

  @include text-sm;
  color: $black;
}

.LineItemsMobile__Item__Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}

.LineItemsMobile__Item__Properties {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.LineItemsMobile__Item__Property {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px 16px;

  dd {
    color: $black-60;
    text-align: right;
  }

  dt,
  dd {
    height: 24px;
  }
}
