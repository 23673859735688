.MyAddressList {
  background-color: $black-3;
  min-height: 256px;

  @include min-small-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-bottom: 160px;
  }
}

.MyAddressList__add-address-button {
  cursor: pointer;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.18em;
  text-transform: uppercase;

  @include max-small-desktop {
    padding: 32px 20px;
    width: 100%;
    border: none;
    background-color: $white;
    border-bottom: 1px solid $black-10;
    justify-content: flex-start;
  }

  @include min-small-desktop {
    border: 2px dashed $black-10;
    background-color: $white;
    height: 272px;
  }

  svg {
    margin-right: 8px;
    margin-top: -2px;
    flex-shrink: 0;
  }
}

.MyAddressList__address-card {
  @include max-small-desktop {
    border-bottom: 1px solid $black-10;
  }
}

.MyAddressList__add-address-button--mobile {
  display: none;
  padding-top: 48px;
  padding-bottom: 48px;

  @include max-small-desktop {
    display: flex;
  }
}
