.StoryProductCard {
  width: 100%;
  max-width: 380px;
  background-color: $white;
  img {
    width: 100%;
    max-height: 214px;
  }
  .CategoryPipeDate {
    margin-bottom: 16px;
    .pipe-bar {
      margin: 0 8px;
      display: inline-block;
    }
    .category {
      color: $black-60;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2.57px;
    }
    .creationDate {
      color: $black-80;
      font-size: 12px;
      letter-spacing: normal;
    }
  }
  &-details {
    padding: 38px 40px 35px 40px;
    &-title {
      margin-bottom: 24px;
      color: $black-80;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.5px;
    }
    &-link {
      color: $primary;
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 3px;
    }
  }
}
