.CompareProductCard {
  display: inline-block;
  width: 100%;
  box-shadow: inset 0 1px 0 0 $black-10;
  @media (min-width: #{$small-desktop}) {
    width: 25%;
    box-shadow: none;
  }

  &-Container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    @media (min-width:#{$small-desktop}) {
      padding: 32px 10px 50px 10px;
    }
  }
  &-DeleteButton {
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    @media (min-width:#{$small-desktop}) {
      margin-bottom: 24px;
    }
  }
  &-Heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-Image {
    min-height: 144px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-Title {
    margin-bottom: 4px;
    a {
      color: $black-80;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
      text-transform: uppercase;
    }

    @media (min-width:#{$small-desktop}) {
      margin-top: 40px;
      margin-bottom: 10px;
      a {
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 33px;
      }
    }
  }

  &-Options,
  &-Article,
  &-Description {
    padding-top: 12px;
    color: $black-60;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }
  &-Description {
    padding: 0;
    display: block;
    height: 63px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 16px;
    @media (min-width:#{$small-desktop}) {
      height: 105px;
      padding-right: 0;
    }
  }
  &-Link {
    margin-top: 16px;
    &.GeneralLink {
      font-size: 12px;
    }
    a {
      color: $primary;
      font-weight: bold;
      @include text-title-xs;
      text-transform: uppercase;

      &:hover {
        color: $primary-hover;
      }
    }
  }
}
