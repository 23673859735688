// Helper mixin to set the color of the icon when hovering over the icon or the link wrapping the icon
@mixin hover-color($color) {
  a:hover > &,
  &:hover {
    color: $color;
  }
}

// Set the default color for "currentColor" in the new svg icons
// This way we can override the color for specific situations
.sprite-icon {
  color: $primary; // #D40028

  &--red {
    color: $primary; // #D40028
  }

  &--orange {
    color: $orange; // #FFB74A
  }

  &--black {
    color: $black; // #000
  }

  &--black-80 {
    color: $black-80; // #333
  }

  &--gray,
  &--grey,
  &--black-60 {
    color: $black-60; // #676767
  }

  &--black-30 {
    color: $black-30; // #b4b4b4
  }

  &--white {
    color: $white; // #fff
  }

  &--green {
    color: $alert-ok; // #80bd28;
  }

  &.hover-icon {
    @include hover-color($primary-hover);

    &.svg-icon--facebook {
      @include hover-color(#3b5998);
    }

    &.svg-icon--android {
      @include hover-color(#a4c639);
    }

    &.svg-icon--instagram {
      @include hover-color(#e1306c);
    }

    &.svg-icon--email,
    &.svg-icon--ios {
      @include hover-color(#000000);
    }

    &.svg-icon--linkedin {
      @include hover-color(#2867b2);
    }

    &.svg-icon--twitter {
      @include hover-color(#000000);
    }

    &.svg-icon--wechat {
      @include hover-color(#00d463);
    }

    &.svg-icon--whatsapp {
      @include hover-color(#25d366);
    }

    &.svg-icon--windowsphone {
      @include hover-color(#0078d7);
    }

    &.svg-icon--youku {
      @include hover-color(#fd517e);
    }

    &.svg-icon--youtube {
      @include hover-color(#ff0000);
    }
  }
}
