.DeleteItemButton {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  text-transform: uppercase;
  display: flex;
  color: $primary;
  gap: 8px;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    margin-top: 2px;
  }
}
