.HeadlineWithTitleAndSubtitle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &-Title {
    margin: 0 16px 0 0;
    color: $black-80;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 33px;

    @media (min-width: #{$small-desktop}) {
      font-size: 36px;
      line-height: 42px;
    }
  }
  &-Subtitle {
    color: $black-80;
    font-size: 24px;
    letter-spacing: 0.3px;
    line-height: 33px;
    @media (min-width: #{$small-desktop}) {
      font-size: 36px;
      letter-spacing: 2px;
      line-height: 49px;
    }
  }

  &-WhiteText {
    .HeadlineWithTitleAndSubtitle-Title,
    .HeadlineWithTitleAndSubtitle-Subtitle {
      color: $white;
    }
  }
}
