.UniversalStoryResultItem {
  background-color: $white;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.UniversalStoryResultItem__Wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

.UniversalStoryResultItem__Wrapper-Info {
  padding-top: 40px;
  padding-bottom: 16px;
}

.UniversalStoryResultItem__Wrapper-Info-Category {
  color: $black-60;
  font-family: var(--font-family-base);
  font-weight: bold;
  @include text-title-xs;
  text-transform: uppercase;
  padding-right: 4px;
}

.UniversalStoryResultItem__Wrapper-Info-Date {
  color: $black-60;
  font-family: var(--font-family-base);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
  padding-left: 4px;
  text-transform: capitalize;
}

.UniversalStoryResultItem__Wrapper-Title {
  color: $black-80;
  font-family: var(--font-family-base);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 25px;
}
