.SalesAndServicePointsFilterCategory {
  &-Hide {
    display: none;
  }

  &-Filters {
    padding-top: 16px;
    padding-left: 32px;
    padding-bottom: 5px;

    div {
      display: inline-block;
      position: relative;
      padding: 0 0 6px 24px;
      width: 50%;
      cursor: default;
      @include mobile {
        width: 100%;
      }
      @media (min-width: #{$small-desktop}) and (max-width: 1240px) {
        width: 100%;
      }
    }

    @include checkbox-input-style;

    .checkmark {
      top: 6px;
      cursor: pointer;
      outline: none;
    }

    &-Title {
      color: $black-80;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 16px;
    }
  }

  &-Title {
    padding-left: 32px;
    color: $black-80;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 19px;
  }
}
