.ListingPageTitleWithDescription {
  @include component-container;
  &-Title {
    color: $black-80;
    padding-top: 16px;
    @media (min-width: #{$small-desktop}) {
      padding-top: 64px;
    }
  }
  &-Description {
    color: $black-80;
    font-weight: 400;
    @include text-body;
    padding: 16px 0 24px;

    @media (min-width: #{$small-desktop}) {
      max-width: 66.66%;
      padding: 8px 0 80px;
    }
  }
}
