.zIndexWithRelativePosition {
  z-index: 99;
  position: relative;

  &.isStickyForMobile {
    top: 70px;
    position: sticky;
  }

  @media (min-width: #{$small-desktop}) {
    top: 70px;
    position: sticky;
  }

  .StickyMenu {
    background-color: $white;
    z-index: 99;

    @media (max-width: #{$small-desktop}) {
      width: 100vw;
    }

    &-Lines {
      border-bottom: 1px solid $black-10;
    }

    &-Wrapper {
      //align-items: center;
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      min-height: 56px;
      max-width: 1440px;
      padding: 0 24px;

      @include small-desktop {
        padding: 0 32px;
      }

      @include desktop {
        padding: 0 130px;
      }

      &-Title {
        @include mobile {
          max-width: 50%;
        }

        align-content: center;
        color: $black-80;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 2.57px;
        line-height: 56px;
        text-transform: uppercase;
        white-space: nowrap;

        &:after {
          align-self: center;
          background: #e5e5e5;
          content: '';
          display: inline-block;
          height: 40px;
          margin: 0 0 0 24px;
          width: 1px;
        }

        &-Text {
          display: block;
          overflow: hidden;
          max-width: 120%;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &-Items {
        align-items: center;
        flex: 1;
        max-height: 56px;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        position: unset;
        white-space: nowrap;

        &.isTransparent {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }

        @media (min-width: #{$small-desktop}) {
          position: relative;
        }

        a {
          color: $black-60;
          cursor: pointer;
          display: inline-block;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 2.57px;
          line-height: 56px;
          margin-left: 24px;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            color: $primary-hover;
          }
        }

        &-Dropdown {
          position: relative;
          display: inline-block;
          margin-left: 24px;

          @include max-small-desktop {
            position: unset;
          }

          &-Container {
            align-self: center;
            margin: 0 24px 0 0;

            & > div {
              display: flex;
            }
          }

          &.Opened {
            .StickyMenu-Wrapper-Items-Dropdown {
              &-Options {
                visibility: visible;
                z-index: 10;
                overflow: auto;
                max-height: calc(100vh - 56px);
              }

              &-Header {
                @media (min-width: #{$small-desktop}) {
                  color: $primary-hover;

                  svg {
                    &.sprite-icon {
                      color: $primary-hover;
                    }
                  }
                }

                @include max-small-desktop {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }

          &-Header {
            align-items: center;
            background-color: $white;
            color: $black-60;
            cursor: pointer;
            display: flex;
            font-weight: bold;
            @include text-title-xs;
            outline: none;
            text-transform: uppercase;

            svg {
              margin-left: 12px;
              height: 8px;
              width: 8px;

              @media (min-width: #{$small-desktop}) {
                margin-left: 8px;
              }

              &.sprite-icon {
                color: $black-60;
              }
            }
          }

          &-Options {
            z-index: -10;
            background-color: $white;
            visibility: hidden;
            padding: 8px 0;
            position: absolute;
            top: 56px;
            width: 100%;
            right: 0;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
            border-radius: 4px;

            @media (max-width: #{$small-desktop}) {
              overflow-y: auto;
            }

            @media (min-width: #{$small-desktop}) {
              width: 280px;
              border-radius: 4px;
              top: 43px;
            }

            a {
              text-decoration: none;
              text-transform: uppercase;
              margin: 0;
              display: block;
              padding: 0 24px;
              color: $black-80;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 2.57px;
              line-height: 40px;

              &:hover {
                background-color: $black-10;
                color: $black-80;
              }

              @media (min-width: #{$small-desktop}) {
                padding: 0 16px;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 1.75px;
                line-height: 40px;

                &:hover {
                  background-color: $black-10;
                  color: $black-80;
                }
              }
            }
          }
        }
      }

      &-Top {
        align-items: center;
        display: none;
        flex-grow: 0;
        flex-shrink: 0;
        cursor: pointer;
        text-transform: uppercase;
        //display: none;

        &:before {
          background: #e5e5e5;
          content: '';
          display: inline-block;
          height: 40px;
          margin: 0 32px 0 0;
          width: 1px;
        }

        .StickyMenu-Separator {
          padding-right: 32px;
        }

        @media (min-width: #{$small-desktop}) {
          display: flex;
          outline: none;
        }

        &-Label {
          display: inline-block;
          color: $black-60;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 2.57px;
          line-height: 56px;
          margin-right: 8px;
        }

        &:hover {
          .StickyMenu-Wrapper-Top-Label {
            color: $primary-hover;
          }

          svg {
            &.sprite-icon {
              color: $primary-hover;
            }
          }
        }

        svg {
          vertical-align: middle;
          width: 16px;
          height: 16px;

          &.sprite-icon {
            color: $black-60;
          }
        }
      }
    }

    &-Separator {
      border-right: 1px solid #e5e5e5;
      display: inline-block;
      height: 40px;
      vertical-align: middle;
      width: 1px;
    }
  }
}
