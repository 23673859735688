.SimilarAccessories {
  padding: 80px 0;

  @media (min-width:#{$small-desktop}) {
    padding: 112px 0;
  }

  &-Headline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-Title {
      margin: 0 16px 0 0;
      color: $black-80;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 24px;
      letter-spacing: 2px;
      line-height: 33px;

      @media (min-width: #{$small-desktop}) {
        font-size: 36px;
        line-height: 42px;
      }
    }

    &-Subtitle {
      color: $black-80;
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;

      @media (min-width: #{$small-desktop}) {
        font-size: 36px;
        letter-spacing: 2px;
        line-height: 49px;
      }
    }
  }

  &-Data {
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;
  }

  &-Item {
    display: inline-block;
    margin-bottom: 16px;
    padding: 16px;
    width: 33%;
    display: flex;
    align-items: center;

    a {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    @include mobile {
      width: 100%;
      box-shadow: inset 0 -1px 0 0 $black-10;
      padding: 16px 0;
      margin: 0;
    }

    @include tablet {
      width: 50%;
    }

    @include small-desktop {
      width: 33%;
    }

    &-Image {
      display: inline-block;
      width: 80px;
      height: 80px;
      margin-right: 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width:#{$small-desktop}) {
        width: 100px;
        height: 100px;
        margin-right: 24px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &-Placeholder {
        background-color: $black-3;
        width: 80%;
        height: 80%;
      }
    }

    &-Description {
      display: inline-block;

      &-Title {
        color: $black-80;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1.5px;
        line-height: 19px;
      }

      &-ArticleNumber {
        color: $black-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-top: 8px;
      }

      &-Details {
        color: $black-60;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: 8px;
      }
    }
  }

  &-More {
    margin-top: 32px;
    color: $primary;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $primary-hover;

      svg {
        &.sprite-icon {
          color: $primary-hover;
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      margin: 0 16px 2px 0;
    }

    &-Hide {
      display: none;
    }
  }
}
