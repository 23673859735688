.MobileMenu {
  .ImageTextRight {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
    min-height: 56px;
    &-Image {
      width: 56px;
      height: auto;
    }
    &-Title {
      padding-left: 16px;
      color: $black-80;
      font-size: 14px;
      letter-spacing: 1.5px;
      &-NoImage {
        line-height: 40px;
        color: $black-80;
        font-size: 14px;
        letter-spacing: 1.5px;
      }
    }
    .gray-placeholder {
      width: 56px;
      height: 56px;
      background-color: $black-4;
      flex-shrink: 0;
    }
  }
}
