.CompareProducts {
  max-width: 1920px;
  margin: 0 auto;
  &-Heading {
    color: $white;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    background-color: $black-80;
    padding: 18px 32px;
  }
  &-ProductsContainer {
    @media (min-width:#{$small-desktop}) {
      display: flex;
    }
  }
  &-Categories {
    @media (min-width:#{$small-desktop}) {
      width: 20%;
    }
  }
  &-Products {
    @media (min-width:#{$small-desktop}) {
      width: 80%;
      padding-left: 10px;
      padding-right: 24px;
    }
  }
}
