.CompareTechnicalSpecsMobile {
  &-Products {
    &-Item {
      padding: 18px 24px 19px 24px;
      border-bottom: 1px solid $black-10;
      &:last-child {
        border-bottom: 0;
      }

      &-Title {
        color: $black-80;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0;
        display: inline-block;
        width: 50%;
      }

      &-Value {
        color: $black-60;
        font-size: 14px;
        letter-spacing: 0;
        text-align: right;
        display: inline-block;
        width: 50%;
      }
    }
  }
}
