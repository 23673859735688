.RadioInput {
  width: 100%;
  @include max-small-desktop {
    margin: 0 auto;
  }
  @include tablet {
    width: 320px;
  }
  @include mobile {
    width: 272px;
  }

  &-Label {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding-left: 24px;
    margin-left: 32px;
    margin-bottom: 10px;
    color: $black-80;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1.5px;

    @include max-small-desktop {
      margin-left: 0;
    }

    &-Wrapper {
      outline: none;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &-Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid $black-10;
    background-color: $white;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
  input:checked ~ .RadioInput-Checkmark:after {
    display: block;
  }
  input:checked ~ .RadioInput-Checkmark {
    border: 2px solid $primary;
  }

  &-Checkmark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
  }
}
