.AlertBox {
  background-color: #e8f0fa;
  padding: 10px 16px;

  color: $black-60;
  @include text-sm;
  font-weight: 500;
}

.AlertBox p {
  margin: 0;
}
