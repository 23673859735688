.GeneralDownloadsResultItem {
  padding: 16px;
  transition: all 0.3s ease;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &:hover {
    .GeneralDownloadsResultItem__Name,
    .GeneralDownloadsResultItem__Type {
      color: $primary;
    }

    .GeneralDownloadsResultItem__Locked {
      max-width: 150px;
    }
  }

  @include min-small-desktop {
    padding: 18px 24px;
  }
}

.GeneralDownloadsResultItem__Locked-icon {
  margin-right: 16px;
  align-self: center;
}

.GeneralDownloadsResultItem__Name {
  color: $black-60;
  font-size: 14px;
  line-height: 1.3571;
  font-weight: 700;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.GeneralDownloadsResultItem__Type {
  color: $black-60;
  font-size: 14px;
  line-height: 1.3571;
  font-weight: 400;
  letter-spacing: 0.7px;
  text-transform: capitalize;

  @include min-small-desktop {
    margin-left: 8px;
    margin-right: auto;
  }
}

.GeneralDownloadsResultItem__File-info {
  display: flex;
}

.GeneralDownloadsResultItem__Language {
  color: $black-60;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3571;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.GeneralDownloadsResultItem__Format {
  color: $black-60;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3571;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-left: 24px;
}

.GeneralDownloadsResultItem__Main {
  margin-right: auto;
  transform: translateY(2px);

  @include min-small-desktop {
    display: flex;
    margin: 0;
    flex-grow: 1;
  }
}

.GeneralDownloadsResultItem__Downloads {
  margin-left: 24px;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.GeneralDownloadsResultItem__Locked {
  color: $primary;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33333;
  letter-spacing: 2.16px;
  text-transform: uppercase;
  overflow: hidden;
  max-width: 0;
  transition: max-width 0.3s ease;
}

.GeneralDownloadsResultItem__Locked-text {
  margin-left: 8px;
}

.GeneralDownloadsResultItem__Button {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

.GeneralDownloadsResultItem__Content {
  flex-grow: 1;
}
