.ProductInfo {
  margin-top: 24px;
  margin-bottom: 40px;

  @include min-tablet {
    display: flex;
    margin-bottom: 87px;
  }

  @include min-small-desktop {
    align-items: center;
  }
}

.ProductInfo__ImageWrapper {
  width: 163px;
  height: 163px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @include min-tablet {
    margin-right: 22px;
    margin-bottom: 0;
  }

  @include min-small-desktop {
    width: 280px;
    height: 280px;
    margin-right: 123px;
  }
}

.ProductInfo__Image {
  width: 163px;

  @include min-small-desktop {
    width: 280px;
  }
}

.ProductInfo__Title {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 6px;
  line-height: 1.33333333;
  display: block;
  color: #676767;

  @include min-tablet {
    font-size: 14px;
    line-height: 1.428571;
  }

  @include min-small-desktop {
    font-size: 18px;
    line-height: 1.3333333333333;
  }
}

.ProductInfo__ProductName {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 1.33333333;
  display: block;

  @include min-tablet {
    font-size: 36px;
    line-height: 1.166666666666667;
  }

  @include min-small-desktop {
    font-size: 48px;
    line-height: 1.375;
  }
}

.ProductInfo__Description {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 1.42857;
  display: block;
  margin: 0;
}
