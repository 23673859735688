.DownloadItem {
  padding: 24px;
  min-height: 80px;
  margin: 0 !important;
  box-shadow: 0 -1px 0 0 #e5e5e5;
  text-decoration: none;
  color: $black-60;
  .image {
    height: 24px;
    width: 24px;
  }
  cursor: pointer;
  @media (min-width: #{$tablet}) {
    &:hover {
      .DownloadItem-Title-Inline {
        border-bottom: 1px solid $primary;
      }
    }
  }
  &:active .DownloadItem-Title,
  &:active .DownloadItem-Size {
    color: $primary;
  }

  &-Title {
    text-decoration: none;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    overflow-wrap: break-word;
    word-break: break-word;

    &-Inline {
      border-bottom: 1px solid transparent;
      display: inline-block;
    }

    @include mobile {
      font-size: 12px;
      letter-spacing: 2.57px;
      line-height: 16px;
      margin-left: 16px !important;
    }
  }
  &-Size {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    text-align: right;
    white-space: nowrap;
  }
}
.WhiteColorStyle .DownloadItem {
  color: $white;
}
