$ProgressBarHeight: 2px;
$ProgressBarColor: #e5e5e5;
$ProgressBarHighlightColor: #d40028;
$ProgressBarGapColor: white;
$ProgressBarGapWidth: 4px;

.ProgressBar {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 180px;
  overflow: hidden;
  border-radius: 8px;
}

.ProgressBar__element {
  flex: 1 1 auto;
  height: $ProgressBarHeight;
  background-color: $ProgressBarColor;
}

.ProgressBar__highlight {
  flex: 1 1 auto;
  position: absolute;
  height: $ProgressBarHeight;
  background-color: $ProgressBarHighlightColor;
  transition: left 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &::before {
    content: '';
    position: absolute;
    left: -$ProgressBarGapWidth;
    top: 0;
    background-color: $ProgressBarGapColor;
    width: $ProgressBarGapWidth;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: -$ProgressBarGapWidth;
    top: 0;
    background-color: $ProgressBarGapColor;
    width: $ProgressBarGapWidth;
    bottom: 0;
  }
}
