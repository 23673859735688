.ProductCarouselThumbnails {
  position: relative;
  @media (min-width: #{$small-desktop}) {
    height: 80px;
  }
  &-Wrapper {
    position: relative;
    outline: none;
    @include mobile {
      height: 300px;
    }
    @include tablet {
      height: 400px;
    }
    svg {
      position: absolute;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      z-index: 1;
    }
    &-Image {
      transition: opacity 0.5s ease-in-out;
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: contain;
      @media (min-width: #{$small-desktop}) {
        opacity: 0.3;
        max-height: 60px;
      }
      &.noOpacity {
        opacity: 1;
      }
    }
  }
  &-Container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ProductCarouselThumbnails-Wrapper {
      position: relative;
      max-height: 480px;

      @media (min-width: #{$small-desktop}) {
        max-width: 25%;
      }
      &-Image {
        transition: opacity 1s ease-in-out;
        cursor: pointer;
        padding: 0 10px;
        opacity: 0.3;
        @media (min-width: #{$small-desktop}) {
          max-height: 80px;
        }

        &.noOpacity {
          opacity: 1;
        }
      }
    }
  }

  .ProductsCarousel .alice-carousel__stage-item {
    @media (max-width:#{$small-desktop}) {
      padding: 0;
    }
  }
}
