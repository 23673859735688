.CartItem {
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}

.CartItem__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: $black-80;
}

.CartItem__image {
  overflow: hidden;
  position: relative;
  width: 56px;
  height: 56px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
  flex-shrink: 0;

  img {
    max-height: 100%;
    height: auto;
  }
}

.CartItem__variant {
  display: flex;
  flex: 1 1 auto;
}

.CartItem__product-name {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  margin-right: 16px;
}

.CartItem__product-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  color: $black-80;
}

.CartItem__product-sku {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71429;
  color: $black-80;
}

.CartItem__price {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: $black-80;
}

.CartItem__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.CartItem__quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  margin-left: 3px;
  height: 48px;
}

.CartItem__quantity--small {
  height: 36px;
}

.CartItem__quantity-text {
  text-align: center;
  width: 1.5rem;
}

.CartItem__quantity-value {
  text-align: center;
  width: 1.5rem;
}

.CartItem__quantity-value {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
