@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';

.AddToCart__Button {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: $primary;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: $primary-hover;
  }

  svg {
    color: $white;
  }
}

.AddToCart__Button--disabled {
  background: $primary-30;
  cursor: default;

  &:hover {
    background: $primary-30;
  }
}

.AddToCart__Button--ghost {
  background-color: transparent;
  color: $primary;
  box-shadow: none;

  svg {
    color: $primary;
  }

  &:hover {
    background-color: transparent;
  }

  &:disabled {
    background-color: $black-3;
    color: $primary-60;
  }
}

.AddToCart__Button__Text {
  color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 3.36px;
  text-transform: uppercase;
}
