.ColumnCategoryItem {
  display: flex;
  margin-top: 8px;
  align-items: center;

  &-Image {
    width: 56px;
    height: 56px;
    background-color: $black-4;
    overflow: hidden;
    flex-shrink: 0;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transform-origin: 0 0;
      transition: transform 1s, visibility 1s ease-in;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
  &-Link {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 19px;
    padding: 0 16px;

    a {
      color: $black-80;
    }

    a:hover {
      color: $primary;
    }
  }
}

.ThreeColumnCategory {
  &.WhiteColorStyle {
    .ColumnCategory {
      .ColumnCategoryItem-Link {
        a {
          color: $white;
        }
        a:hover {
          color: $primary;
        }
      }
    }
  }
}
