.SubCategoriesItem {
  display: inline-block;
  margin-bottom: 16px;
  padding: 16px;
  width: 33%;
  display: flex;
  align-items: center;
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  @include mobile {
    width: 100%;
    box-shadow: inset 0 -1px 0 0 $black-10;
    padding: 16px 0;
    margin: 0;
  }

  @include tablet {
    width: 50%;
  }

  @include small-desktop {
    width: 33%;
  }

  &-Image {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-right: 16px;
    flex-shrink: 0;
    @media (min-width:#{$small-desktop}) {
      width: 100px;
      height: 100px;
      margin-right: 24px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-Description {
    display: inline-block;

    &-Title {
      color: $black-80;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 19px;
    }

    &-ArticleNumber {
      color: $black-60;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      margin-top: 8px;
    }

    &-Details {
      color: $black-60;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 8px;
    }
  }
}
