.FormWrapper {
  form {
    width: 66.66%;
    margin: 0 auto;

    @include max-small-desktop {
      width: 100%;
    }

    @include tablet {
      padding: 0 40px;
    }
  }
}
