.CompareUSP {
  @media (min-width: #{$small-desktop}) {
    padding-top: 32px;
    padding-left: 20px;
    display: flex;
  }

  &-Title {
    display: none;
    @media (min-width: #{$small-desktop}) {
      display: inline-block;
      width: 20%;
    }
  }
}
