.MediaGalleryTeaser {
  max-width: 980px;
  margin: 0 auto 40px auto;
  .caption {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: $black-60;
    margin: 13px 0;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-size: 4em;
    opacity: 0.8;
  }
  .image-gallery-fullscreen-button:before,
  .image-gallery-left-nav:before,
  .image-gallery-play-button:before,
  .image-gallery-right-nav:before {
    text-shadow: 0 1px 1px #1a1a1a;
  }
  .image-gallery-fullscreen-button:hover:before,
  .image-gallery-left-nav:hover:before,
  .image-gallery-play-button:hover:before,
  .image-gallery-right-nav:hover:before {
    color: $primary;
  }
}
