.SolutionResultItem {
  background-color: $white;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.SolutionResultItem__Wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

.SolutionResultItem__Wrapper-FoundIn {
  color: $black-80;
  font-family: var(--font-family-base);
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 3px;
  line-height: 14px;
  padding-top: 28px;
  padding-bottom: 4px;
  text-transform: uppercase;
}

.SolutionResultItem__Wrapper-Title {
  color: $black-80;
  font-family: var(--font-family-base);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 25px;
}

.SolutionResultItem__Wrapper-Description {
  color: $black-80;
  font-family: var(--font-family-base);
  font-weight: 400;
  @include text-body;
  max-height: 72px;
  overflow: hidden;

  p {
    margin: 0;
    padding: 0;
  }
}
