.SimpleTableTeaser {
  max-width: 980px;
  margin: 0 auto 40px auto;
  padding: 0 100px;
  &-Header {
    min-width: 480px;
    min-height: 56px;
    background-color: $black-3;
    box-shadow: inset 0 -1px 0 0 $black-10;
    &-Item {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: $black-80;
      padding: 16px 24px;
    }
  }
  &-Row {
    min-width: 480px;
    min-height: 56px;
    box-shadow: inset 0 -1px 0 0 $black-10;
    &-Item {
      font-size: 16px;
      color: $black-80;
      padding: 16px 24px;
    }
  }
}
