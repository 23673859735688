.UniversalHero {
  margin: 0 auto;
  width: 83.33%;
  padding-top: 100px;

  @include tablet {
    width: 100%;
    padding-top: 32px;
  }

  @include mobile {
    padding-top: 24px;
    width: 100%;
    position: static;
  }

  &-Container {
    margin-bottom: 32px;
  }

  &-Title {
    color: $black-80;
    width: 80%;
    margin-bottom: 40px;
    margin-right: auto;
    margin-left: auto;

    cursor: default;

    @include tablet {
      width: 100%;
      margin-bottom: 32px;
      margin-right: auto;
      margin-left: auto;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 32px;
      margin-right: auto;
      margin-left: auto;
      padding: 0 24px;
    }

    &-TopSpace {
      margin-top: 24px;

      @include tablet {
        margin-top: 32px;
      }

      @include mobile {
        margin-top: 32px;
      }
    }
  }

  .GeneralLink {
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
    letter-spacing: 3px;
    width: 80%;
    margin: 0 auto;

    &:hover {
      a,
      svg {
        color: $primary-hover;
      }
    }

    a {
      color: $primary;
      font-size: 14px;
      text-decoration: none;

      svg {
        margin-bottom: -3px;
        margin-right: 8px;
      }
    }

    @include tablet {
      width: 100%;
      padding: 0 64px;
    }

    @include mobile {
      width: 100%;
      padding: 0 24px;
    }
  }

  &-Image {
    width: 100%;
    height: auto;
  }

  &-Description {
    width: 80%;
    margin: 48px auto 0 auto;
    color: $black-60;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    cursor: default;
    @include rich-text-a-style;
    @include rich-text-list-style;

    @include tablet {
      width: 100%;
      padding: 0 64px;
      margin-top: 32px;
    }

    @include mobile {
      width: 100%;
      font-size: 22px;
      line-height: 30px;
      margin-top: 32px;
      padding: 0 24px;
    }
  }

  &-Caption {
    display: inline-block;
    width: 80%;
    margin: 0 auto;
    font-weight: 400;
    color: $black-60;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.35px;
    line-height: 22px;
    padding: 0 10px;

    @include tablet {
      padding: 0;
    }

    @include mobile {
      width: 100%;
      padding: 0;
    }
  }

  &-SocialLinkWrapper {
    width: 32px;
    float: right;
    margin-top: 24px;
    margin-bottom: 24px;

    @include tablet {
      width: auto;
      float: none;
      text-align: end;
      margin-bottom: 0;
      padding-right: 24px;
    }

    @include mobile {
      float: none;
      width: auto;
      margin: 32px auto 0 auto;
      text-align: center;
    }
  }
}
