.HeadlineRichtextTeaser {
  max-width: 980px;
  margin: 0 auto 40px auto;
  padding: 0 100px;
  color: $black-80;
  .title {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 26px;
    margin-top: 0;
  }
  @include rich-text-list-style;
}
.WhiteColorStyle .HeadlineRichtextTeaser {
  color: $white;
}
