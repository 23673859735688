@import '../../assets/colors.scss';
@import '../../assets/media-width.scss';
@import '../../assets/typography-mixins.scss';

.ConfirmationDialog {
  position: fixed;
  z-index: 120;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmationDialog-TransparentOverlay {
  background-color: rgba(0, 0, 0, 0.64);
}

.ConfirmationDialog svg {
  cursor: pointer;
  margin-left: 24px;
  flex-shrink: 0;

  @include min-tablet {
    margin-top: 6px;
  }
}

.ConfirmationDialog-DetailsContainer {
  background-color: $white;
  width: 80%;

  @include min-small-desktop {
    width: 40%;
  }
  padding: 32px 40px 40px 40px;
}

.ConfirmationDialog-Title {
  color: $black-80;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 19px;

  @include min-tablet {
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 33px;
  }
}

.ConfirmationDialog-Text {
  color: $black-80;
}

.ConfirmationDialog-Heading {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.ConfirmationDialog-Buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px 24px;

  // Here we prefer to use the gap property
  // But this means we have to remove the margin on button siblings, otherwise we have double margin
  .button ~ .button {
    margin: 0;
  }

  @include min-tablet {
    flex-direction: row;
  }
}
