@import './PersonalInformations/PersonalInformations.scss';
@import './AccountInformations/AccountInformations.scss';
@import './CompanyInformations/CompanyInformations.scss';

.MyProfileComponent {
  @media (min-width:#{$tablet}) {
    background-color: $black-3;
  }

  &-Wrapper {
    @media (min-width:#{$tablet}) {
      @include component-container;
      background-color: $black-3;
      padding-top: 80px;
    }
  }
}
