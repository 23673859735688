.HeroTitleWithOverline {
  background-color: $white;
  &-Container {
    width: 100%;
    padding: 56px 0 48px 0;
    @include tablet {
      padding-bottom: 32px;
    }
    @media (min-width: #{$small-desktop}) {
      width: 66.66%;
      padding-top: 152px;
      padding-bottom: 64px;
    }
  }
  &-Overline {
    display: block;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 3.86px;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
    cursor: default;

    @include max-small-desktop {
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 19px;
    }
  }

  &-Title {
    display: block;
    color: $black;
    cursor: default;
    margin: 0;
  }
}
