.IconLink {
  display: flex;

  .GeneralLink {
    a {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $primary;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 3px;
      text-transform: uppercase;
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }

      &.scEnabledChrome {
        display: none;
      }
    }

    .IconLink-img {
      margin-right: 15px;
    }
  }
}
