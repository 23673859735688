.LeftVideoWithRichText {
  color: $black-80;
  margin: 0 auto;
  padding: 16px 0;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &:before {
    content: '';
    display: table;
    clear: both;
  }
  &-Text {
    width: 100%;
    margin: 0 auto;
    @include text-body;
    padding: 0 24px;
    @include tablet {
      padding: 0 40px;
    }
    @include rich-text-a-style;
    @include rich-text-list-style;

    ol,
    ul {
      display: table;
    }
    p {
      padding-top: 0;
      padding-bottom: 16px;
      margin: 0;
    }

    h2 {
      padding-top: 0;
      padding-bottom: 24px;
      @media (min-width: #{$small-desktop}) {
        line-height: 40px;
      }
    }
    .RichTextWithCheckmarks-Item {
      p:first-child {
        margin: 0;
      }
      position: relative;
      display: table;
      .RichTextWithCheckmarks-Text {
        display: inline-block;
        margin-left: 52px;
        margin-top: 8px;
      }
      svg {
        position: absolute;
        left: 16px;
        top: 12px;
      }
    }
  }
  &-VideoContainer {
    position: relative;
    margin-bottom: 32px;
    width: 100%;
    min-height: 208px;
    outline: none;

    @include mobile {
      min-height: 168px;
    }
    img {
      margin-top: 4px;
      height: auto;
      width: 100%;
    }
  }
  &-VideoContainer:after {
    content: '';
    display: table;
    clear: both;
  }
  &-VideoContainer:before {
    content: '';
    display: table;
    clear: both;
  }
  @include tablet {
    &-VideoContainer {
      margin: 0 24px 24px 0;
      float: left;
      width: 50%;
    }
  }

  @media (min-width: #{$small-desktop}) {
    &-Text {
      width: 66.66%;
      padding: 0;
    }
    &-VideoContainer {
      margin: 0 24px 24px 8.33%;
      float: left;
      width: 41.66%;
    }
  }
}

/*
====================================================================================================
VIDEO ON THE RIGHT
====================================================================================================
*/

.RightVideoWithRichText {
  color: $black-80;
  margin: 0 auto;
  padding: 16px 0;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &:before {
    content: '';
    display: table;
    clear: both;
  }

  &-Text {
    width: 100%;
    margin: 0 auto;
    @include text-body;
    padding: 0 24px;
    @include tablet {
      padding: 0 40px;
    }
    @include rich-text-a-style;
    @include rich-text-list-style;
    p {
      padding-top: 0;
      padding-bottom: 16px;
      margin: 0;
    }

    h2 {
      padding-top: 0;
      padding-bottom: 24px;
      @media (min-width: #{$small-desktop}) {
        line-height: 40px;
      }
    }
    .RichTextWithCheckmarks-Item {
      p:first-child {
        margin: 0;
      }
      position: relative;
      display: table;
      .RichTextWithCheckmarks-Text {
        display: inline-block;
        margin-left: 52px;
        margin-top: 8px;
      }
      svg {
        position: absolute;
        left: 16px;
        top: 12px;
      }
    }
  }
  &-VideoContainer {
    margin-bottom: 32px;
    width: 100%;
    position: relative;
    min-height: 208px;
    outline: none;

    @include mobile {
      min-height: 168px;
    }
    img {
      margin-top: 4px;
      height: auto;
      width: 100%;
    }
  }

  @include tablet {
    &-VideoContainer {
      margin: 0 0 24px 24px;
      float: right;
      width: 50%;
    }
  }
  @media (min-width: #{$small-desktop}) {
    &-Text {
      width: 66.66%;
      padding: 0;
    }
    &-VideoContainer {
      margin: 0 8.33% 24px 24px;
      float: right;
      width: 41.66%;
    }
  }
}

/*
====================================================================================================
VIDEO DETAILS
====================================================================================================
*/

.VideoPlayButton {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  svg {
    fill-opacity: 0.8;
  }
  &:hover {
    svg {
      color: $primary-hover;
    }
  }
  @media (max-width: #{$small-desktop}) {
    svg {
      width: 40px;
      height: 40px;
    }
    margin-left: -20px;
    margin-top: -20px;
  }
}

.VideoDetails {
  &-Wrapper {
    position: absolute;
    bottom: 24px;
    left: 32px;
  }
  &-Line {
    width: 40px;
    height: 16px;
    box-shadow: inset 0 4px 0 0 $primary;
  }
  &-Overline {
    color: $white;
    font-weight: bold;
    @include text-section-title-small;
    text-transform: uppercase;
    cursor: default;
    padding: 8px 0 4px 0;

    @include tablet {
      padding: 0;
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 25px;
    }
  }
  &-Title {
    color: $white;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.3px;
    line-height: 33px;
    cursor: default;

    @include tablet {
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
    }
  }
}
.WhiteColorStyle.RightVideoWithRichText,
.WhiteColorStyle.LeftVideoWithRichText {
  color: $white;
}
