.CompareBox {
  @media (min-width: #{$tablet}) {
    @include tooltip-product;
    background-color: $black-80;
    z-index: 5;
    padding: 24px;
    opacity: 1;
    bottom: 125%;
    width: 280px;

    &::after {
      border-color: $black-80 transparent transparent transparent;
      border-width: 9px;
      margin-left: -9px;

      @media (min-width: #{$small-desktop}) and (max-width: 1330px) {
        left: 83%;
      }
    }

    &.CompareBox-Right {
      right: -30px;
      left: auto !important;

      &::after {
        left: auto !important;
        right: 30px !important;
      }
    }
  }

  @media (min-width: #{$small-desktop}) and (max-width: 1330px) {
    left: 0;
    transform: translateX(-70%);
  }

  @include mobile {
    position: fixed;
    width: 100%;
    visibility: hidden;
    background-color: $white;
    padding: 24px;
    z-index: 105;
    top: 0;
    right: 0;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
  }

  &-Active {
    visibility: visible;
  }

  &-Heading {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 19px;
    text-transform: uppercase;
    padding-bottom: 8px;
    cursor: default;

    svg {
      margin-left: 48px;
      width: 12px;
      height: 12px;
      cursor: pointer;

      &.sprite-icon {
        color: $white;

        @include mobile {
          color: $black-80;
        }
      }

      &:hover {
        @media (min-width: #{$small-desktop}) {
          &.sprite-icon {
            color: $black-30;
          }
        }
      }
    }

    @include mobile {
      color: $black-80;
      letter-spacing: 1.5px;
      padding-bottom: 24px;
      letter-spacing: 1.5px;

      svg {
        width: 18px;
        height: 18px;
        margin-left: 24px;
      }
    }
  }

  &-Text {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    white-space: normal;
  }

  &-AddButton {
    margin-top: 16px;
    display: inline-block;

    a {
      display: inline-block;
      padding: 12px 16px;
      background-color: $primary;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      font-weight: bold;
      @include text-title-xs;
      text-transform: uppercase;
      cursor: pointer;
      color: $white !important;

      &:hover {
        background-color: $primary-hover;
        color: $white !important;
      }

      @include mobile {
        text-align: center;
      }
    }

    @include mobile {
      margin-top: 24px;
    }
  }
}
