.Quote {
  margin: 0 auto;
  padding: 56px 0;
  @include mobile {
    padding: 36px 24px;
  }
  @include tablet {
    padding: 54px 0;
  }

  &-Title {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 3.86px;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0 auto;
    cursor: default;

    @include mobile {
      font-size: 12px;
      letter-spacing: 2.57px;
      line-height: 16px;
    }
    @include tablet {
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 19px;
    }
  }
  &-Description {
    color: $black-80;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 33px;
    margin: 26px auto 0 auto;
    cursor: default;

    @include mobile {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 19px;
    }
    @include tablet {
      font-size: 18px;
      letter-spacing: 1.5px;
      line-height: 25px;
    }
  }
  &-Description:before {
    content: '\00AB';
    margin-right: 5px;
  }

  &-Description:after {
    content: '\00BB';
    margin-left: 8px;
  }

  &-Row {
    justify-content: center !important;
    max-width: 83.33%;
    margin: 0 auto 56px auto !important;

    @include tablet {
      padding: 0 40px;
    }

    @include max-small-desktop {
      max-width: 100%;
      margin-bottom: 32px !important;
    }
  }
  &-Container {
    max-width: 80% !important;

    @include max-small-desktop {
      max-width: 100% !important;
    }
  }
}

.BottomLine {
  border-bottom: 2px solid $black-10;
}

.TopLine {
  border-top: 2px solid $black-10;
}
