.Checkbox {
  @include checkbox-input-style-extended;

  padding: 0;
  padding-left: var(--download-item-padding-inline);
  flex-grow: 1;
  font-family: var(--font-family-base);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  color: $black-80;

  .checkmark {
    left: var(--download-item-padding-inline);
    top: 50%;
    transform: translateY(-50%);
  }
}

.Checkbox__check {
  width: 32px;
  flex-shrink: 0;
}
