.AddToCartSlideOver__main {
  padding: 0 20px;
  overflow: auto;
  flex: 1 1 auto;

  @include min-small-desktop {
    padding: 0 24px;
  }
}

.AddToCartSlideOver__items {
  margin-bottom: 32px;
  list-style: none;
  padding: 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
