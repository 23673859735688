.BenefitsWithCheckmarks {
  position: relative;
  box-shadow: 0 1px 0 0 $black-10;
  &:first-child {
    box-shadow: 0 -1px 0 0 $black-10, 0 1px 0 0 $black-10;
  }
  &-Container {
    padding-bottom: 32px;
    @media (min-width: #{$small-desktop}) {
      margin-bottom: 80px;
      padding-bottom: 0;
    }
  }

  svg {
    position: absolute;
    left: 16px;
    top: 20px;
  }

  &-Text {
    display: inline-block;
    margin-left: 52px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 400;
    @include text-body;
  }
}
