.CompanyInformations {
  background-color: $white;
  position: relative;
  padding: 56px 24px;
  border-bottom: 1px solid $black-10;
  @media (min-width:#{$tablet}) {
    padding: 32px 40px;
    border-bottom: none;
  }
  &-Headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    &-Title {
      color: $black-80;
      @media (max-width:#{$tablet}) {
        font-size: 18px;
        letter-spacing: 1.5px;
        line-height: 25px;
      }
    }

    &-EditSection {
      a {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 5px;
      }
      &-Text {
        display: none;
        @include text-section-title-small;
        font-weight: bold;
        color: $primary;
        text-transform: uppercase;

        &:hover {
          color: $primary-hover;
        }

        @media (min-width:#{$small-desktop}) {
          display: block;
        }
      }
    }
  }

  &-Row {
    line-height: 25px;
    @media (min-width:#{$tablet}) {
      display: flex;
      margin-top: 1px;
      line-height: 49px;
      padding: 0 8px;
      border-top: 1px solid $black-10;
    }
    &-Labels {
      @media (min-width:#{$tablet}) {
        width: 40%;
      }

      font-size: 14px;
      font-weight: bold;
      letter-spacing: 3px;
      color: $black-60;
      text-transform: uppercase;
    }
    &-TextColumn {
      margin-bottom: 16px;
      @media (min-width:#{$tablet}) {
        margin-bottom: 0;
        width: 40%;
      }
      font-size: 14px;
      letter-spacing: 0px;
      color: $black-60;
      &-Address {
        line-height: 26px;
        font-size: 14px;
        letter-spacing: 0;
        margin-top: 12px;
      }
    }
  }
}
