.ContentAccordionElements {
  &-Item {
    min-height: 80px;
    border-top: 1px solid $black-10;
    padding: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;

    @media (min-width: #{$small-desktop - 1px}) {
      &:hover {
        svg {
          color: $primary;
        }

        .ContentAccordionElements-Item-Title {
          border-bottom: solid 1px $primary;
        }
      }
    }

    &-WithoutTitle {
      justify-content: flex-end;
    }

    &-Title {
      border-bottom: solid 1px transparent;
      word-break: break-word;
      word-wrap: break-word;
      pointer-events: none;
      max-width: 678px;
      display: inline-block;
      color: $black-60;
      font-weight: bold;
      @include text-section-title-small;
      text-transform: uppercase;

      @include mobile {
        font-size: 12px;
        letter-spacing: 2.57px;
        line-height: 16px;
      }

      &-Active {
        color: $primary;
      }
    }

    &-Chevron {
      pointer-events: none;
      text-align: center;
      padding-left: 24px;
    }
  }

  &-Description {
    word-break: break-word;
    word-wrap: break-word;
    color: $black-80;
    font-weight: 400;
    @include text-body;
    padding-left: 24px;
    padding-right: 24px;
    opacity: 0;
    height: 0;
    overflow: hidden;
    cursor: default;
    @include rich-text-a-style;
    @include rich-text-list-style;

    @include mobile {
      font-size: 14px;
      line-height: 21px;
    }

    &-enter {
      opacity: 0;
      height: 0;
      padding-bottom: 0;
      transition: all 0.5s linear;
    }

    &-enter-active,
    &-enter-done {
      opacity: 1;
      height: auto;
      padding-bottom: 32px;
      transition: all 0.5s linear;
    }

    &-exit {
      opacity: 1;
      height: auto;
      padding-bottom: 32px;
      transition: all 0.5s linear;
    }

    &-exit-active,
    &-exit-done {
      opacity: 0;
      height: 0;
      padding-bottom: 0;
      transition: all 0.5s linear;
    }
  }
}
