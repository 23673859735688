.ListLayout .ProductResultItem--downloads {
  padding: 24px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 5px 10px 0px #0000004d;

    .ProductResultItem__Downloads-Label {
      opacity: 1;
    }
  }

  img {
    width: 60px;
    height: 60px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .ProductResultItem__Wrapper {
    display: flex;
    flex-direction: column;
  }

  .ProductResultItem__Wrapper-Description {
    padding: 0;
  }

  .ProductResultItem__Wrapper-Title {
    font-size: 18px;
    line-height: 1.3888888;
    padding-top: 0;
  }

  .ProductResultItem__Downloads-Label {
    color: $primary;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }

  .ProductResultItem__Downloads-Label-Text {
    margin-right: 8px;
    margin-top: 3px;
    line-height: 1.333333;
  }

  .ProductResultItem__PhasedOut {
    margin-bottom: 4px;
  }

  .ProductResultItem__Link {
    display: flex;
  }

  @include max-small-desktop {
    .ProductResultItem__Link {
      position: relative;
      flex-direction: column;

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .ProductResultItem__Wrapper {
        max-width: 100%;
        padding-left: 76px;
      }

      .ProductResultItem__Wrapper-Title {
        padding-left: 0;
        padding-right: 0;
      }

      .ProductResultItem__Downloads-Label {
        padding-left: 76px;
        margin-top: 12px;
      }
    }
  }

  @media (min-width: #{$small-desktop}) {
    .ProductResultItem__Link {
      align-items: center;
    }

    .ProductResultItem__Downloads-Label {
      opacity: 0;
      margin-left: auto;
    }
  }
}
