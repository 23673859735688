//white
$white: #ffffff;
//black
$black: #000000;
$black-80: #333333;
$black-60: #676767;
$black-30: #b4b4b4;
$black-10: #e5e5e5;
$black-4: #f4f4f4;
$black-3: #f9f9f9;

//TODO ask if this is the color for company accordion
$gray: #fafafa;

//Primary color
$primary: #d40028;
$primary-80: #dd3353;
$primary-60: #e5667e;
$primary-30: #f2b2be;
$primary-10: #fae5e9;
$primary-4: #fdf5f7;
$primary-3: #fdf7f8;
$primary-hover: #b40028;

//border
$border: #e5e5e5;

//weldy main color
$weldy-main: #0e1655;
$weldy-main-30: #b6b9cc;

//leister main color
$leister-main: #009257;

//alert error
$alert-error: #ee0000;
$alert-error-10: #fde5e5;

//alert info
$alert-info: #0d60c0;
$alert-info-10: #e6eff8;

//alert warning
$alert-warning: #f8e818;
$alert-warning-10: #fefce7;

//alert ok
$alert-ok: #80bd28;
$alert-ok-10: #f2f8e9;

// orange
$orange: #ffb74a;

$pre-launch: #e6f5f0;
