@import '../../../assets/colors.scss';
@import '../../../assets/media-width.scss';
@import '../../../assets/typography-mixins.scss';

.GatedDownloadContainer {
  margin-bottom: 96px;
  margin-top: 42px;
}

.GatedDownloadCard {
  background-color: $black-3;
  margin-bottom: 80px;
  margin-right: 10px;
  padding: 36px 40px;
  width: 100%;

  @include tablet {
    padding: 72px 24px;
  }

  @include mobile {
    padding: 72px 24px;
  }

  &-Title {
    color: $black-60;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 25px;
    text-transform: uppercase;
  }

  &-Article {
    @include text-body;
  }
}
