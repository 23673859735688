.shopify-price-details {
  display: grid;
  gap: 0 8px;
  grid-template-areas:
    'oldPrice oldPrice'
    'currentPrice discountTag';
  justify-content: center;
  align-items: center;

  .shopify-price-details__discountTag {
    grid-area: discountTag;
    display: flex;
  }
  .shopify-price-details__oldPrice {
    grid-area: oldPrice;
  }
  .shopify-price-details__currentPrice {
    grid-area: currentPrice;

    &.has-old-price {
      .shopify-price {
        color: $primary;
      }
    }
  }

  @include min-small-desktop {
    grid-template-areas:
      'oldPrice oldPrice'
      'discountTag currentPrice';
    justify-items: flex-end;
  }

  // Bundle special placement
  &.shopify-price-details--variant-bundle {
    gap: 0 16px;
    @include max-small-desktop {
      justify-items: flex-start;
      grid-template-areas:
        'discountTag'
        'oldPrice'
        'currentPrice';

      .shopify-price-details__oldPrice {
        margin-top: 4px;
      }
    }
  }
}
