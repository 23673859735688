.USPAccessories {
  margin-block: 16px;
  &-Item {
    &-WithBackground {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &-Name {
      width: 50%;
      display: inline-block;
      color: $black-80;
      font-weight: bold;
      @include text-title-xs;
      text-transform: uppercase;
      padding: 16px 24px;

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 19px;
      }
    }

    &-Value {
      vertical-align: top;
      width: 50%;
      display: inline-block;
      color: $black-60;
      font-size: 12px;
      line-height: 16px;
      padding: 16px 24px 16px 0;

      @media (min-width: #{$small-desktop}) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
