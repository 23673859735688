@import '../../../../../assets/colors.scss';

.EventInformationSection {
  padding: 0 0 80px;

  h1 {
    margin-bottom: 32px;

    @media (min-width: #{$small-desktop}) {
      margin-bottom: 62px;
    }
  }

  .LinkElement-Link {
    padding: 0;
    width: auto;
  }

  &-DetailsRow {
    color: $black-80;
    list-style: none;
    line-height: 1.5;
    margin: 0 0 10px 0;
    padding: 0;

    @media (min-width: #{$tablet}) {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      align-items: center;
      display: flex;
      font-size: 14px;
      line-height: 1.5;
      margin: 0 24px 12px 0;
    }

    svg {
      color: $primary;
      height: 16px;
      margin: 0 12px 0 0;
      transform: translateY(-2px);
      width: 16px;
    }
  }

  &-DetailsSeparator {
    margin: 0 5px;
  }

  &-TextContainer {
    color: $black-80;
    @include text-body;
    margin: 0 0 24px 0;
  }

  &-LinkRow {
    margin: 12px 0 0 0;
  }

  &-Download {
    border: none;
    background: transparent;
    display: flex;
    cursor: pointer;
    color: $primary;
    font-family: var(--font-family-base);
    padding: 0;
    font-size: 0.875rem;
    align-items: center;

    &:hover {
      color: $primary-hover;
    }
  }
}

.EventAddToCalendar {
  margin: 40px 0;
}

.EventAddress {
  margin-block: 40px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.EventAddressInfo {
  display: grid;
  grid-template-columns: 2fr 6fr;
  margin-block: 40px;

  @include mobile {
    grid-template-columns: auto;
  }
}

.EventAddressInfo-Label {
  text-transform: uppercase;
  color: #676767;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 26px;

  @include mobile {
    margin-bottom: 5px;
  }
}

.EventAddressInfo-Content {
  color: #333333;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.EventAddressInfo-Content-Venue {
  font-weight: bold;
  margin-bottom: 16px;
}

.EventAddressInfo-Directions {
  margin-top: 14px;

  &:first-child {
    margin-top: 0;
  }

  a {
    padding-block: 4px;
    display: inline-block;
  }

  .icon-map-pin {
    color: $primary;
    margin-inline: 0px 8px;
  }
}
